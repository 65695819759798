import React, { useState } from "react";
import coupon1 from "../../Assets/Icons/coupon.png";
import NoCoupon from "../../Assets/Image_lc/coupon.png";
import Order from "../../../API/API_SERVISES/Order";
import BillSundry from "../../../API/API_SERVISES/BillSundry";
import CouponMasterApi from "../../../API/API_SERVISES/CouponMaster";

const AddCouponModal = ({
  coupons,
  handleClick,
  ApplyCoupon,
  setLoading,
  InputValues,
  setCouponApplied,
  calculateTotalPrice,
  GetCouponData,
  AppliedCouponData,
  CouponApplied,
  setAppliedCouponData,
}) => {
  const [CouponCode, setCouponCode] = useState("");

  const ApplyCouponFromCode = async () => {
    setLoading(true);
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    var SundryData = await BillSundry.GetBillSundry();
    var discountId = SundryData.find((item) => item.name == "Discount")?.id;
    var discountAmtId = SundryData.find(
      (item) => item.name == "Discount Amt"
    )?.id;

    var AllCoupondata = await CouponMasterApi.getCouponMaster();
    debugger;
    var CouponData = AllCoupondata.find(
      (item) => item.couponTitleOrCode == CouponCode.trim()
    );

    var data = {
      rowIndex: 0,
      id: CouponData.discountType == "1" ? discountId : discountAmtId,
      adjustmentInItem: true,
      Percent: CouponData.discountType == "1" ? CouponData.discountValue : 0,
      Amount: CouponData.discountType != "1" ? CouponData.discountValue : 0,
    };

    var newSundryData = CouponApplied.some((coupon) => coupon.id === data.id)
      ? CouponApplied.map((coupon) =>
          coupon.id === data.id
            ? {
                ...coupon,
                Amount: data.Amount ?? coupon.Amount,
                Percent: data.Percent ?? coupon.Percent,
              }
            : coupon
        )
      : [...CouponApplied, data];

    // Separate items based on conditions
    let amountZeroItems = [];
    let PercentZeroItems = [];
    let otherItems = [];

    newSundryData.forEach((item) => {
      if (item.Amount === 0) {
        amountZeroItems.push(item);
      } else if (item.Percent === 0) {
        PercentZeroItems.push(item);
      } else {
        otherItems.push(item);
      }
    });

    // Reconstruct the array: Amount === 0 first, then normal items, then Percent === 0
    newSundryData = [
      ...PercentZeroItems,
      ...otherItems,
      ...amountZeroItems,
    ];
    var stringifydata = JSON.stringify(newSundryData);
    var encodedData = encodeURIComponent(stringifydata);
    var newCouponData = AppliedCouponData.some(
      (coupon) => coupon.id === CouponData.id
    )
      ? [...AppliedCouponData]
      : [...AppliedCouponData, CouponData];
    
    // Separate items based on discountType condition
    let discountTypeOne = [];
    let otherDiscounts = [];
    
    newCouponData.forEach((item) => {
      if (item.discountType === "1") {
        discountTypeOne.push(item);
      } else {
        otherDiscounts.push(item);
      }
    });
    
    // Reconstruct the array: discountType != "1" first, then discountType == "1"
    newCouponData = [...otherDiscounts, ...discountTypeOne];
    var stringifydataCoupon = JSON.stringify(newCouponData);
    var encodedDataCoupon = encodeURIComponent(stringifydataCoupon);
    console.log("COUPONDATA", data);
    console.log("COUPONDATA2", CouponData);

    const CartData = [
      {
        id: 0,
        useType: 0,
        accId: loginData.d2Id || 0,
        ex1: encodedData || "",
        ex2: encodedDataCoupon || "",
        ex3: "",
        ex4: "",
        ex5: "",
        iEx1: 0,
        iEx2: 0,
        iEx3: 0,
        nEx1: 0,
        nEx2: 0,
        nEx3: 0,
        nEx4: 0,
        nEx5: 0,
      },
    ];

    var coupon = await Order.SaveEcomCartExtrafields(CartData);
    debugger;
    if (coupon.isSuccess) {
      setLoading(false);
      handleClick();
      setCouponApplied(data);
      setTimeout(() => {
        // if (loginData && loginData.d2Id) {
          GetCouponData(loginData?.d2Id || 0, InputValues, false);
        // } else {
        //   calculateTotalPrice();
        // }
      }, 100);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="modal-back">
      <div className="form-container-1">
      <div className="coupon-searchbox">
          <div className="inputBox_container">
            <svg
              className="search_icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              alt="search icon"
            >
              <path d="M46.599 46.599a4.498 4.498 0 0 1-6.363 0l-7.941-7.941C29.028 40.749 25.167 42 21 42 9.402 42 0 32.598 0 21S9.402 0 21 0s21 9.402 21 21c0 4.167-1.251 8.028-3.342 11.295l7.941 7.941a4.498 4.498 0 0 1 0 6.363zM21 6C12.717 6 6 12.714 6 21s6.717 15 15 15c8.286 0 15-6.714 15-15S29.286 6 21 6z"></path>
            </svg>
            <input
              className="inputBox"
              id="inputBox"
              type="text"
              value={CouponCode}
              onChange={(e) => setCouponCode(e.target.value)} // Ensures the input updates in real-time
              placeholder="Enter Your Coupon Code"
            />
          </div>
          <button className="search-coupon-btn-1" onClick={ApplyCouponFromCode}>
            Apply
          </button>
        </div>
        <i
          style={{
            cursor: "pointer",
            fontSize: "16px",
            top: "5px",
            right: "8px",
          }}
          onClick={handleClick}
          className="fa-solid fa-xmark modal-cross"
        ></i>
        <div className="coupon-box-container">
  {coupons.some((coupon) => coupon.isVisible) ? (
    coupons
      .filter((coupon) => coupon.isVisible)
      .map((coupon, index) => (
        <div className="coupon-box-1" key={index}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "20%" }}>
              <img className="coupon-image-1" src={coupon1} alt="" />
            </div>
            {coupon.discountType == "1" ? (
              <div className="coupon-txt-box-1">
                <p className="coupon-off-button">
                  Flat {coupon.discountValue} %
                </p>
                <p style={{ margin: "0" }}>
                  Flat {coupon.discountValue} % off
                </p>
              </div>
            ) : (
              <div className="coupon-txt-box-1">
                <p className="coupon-off-button">
                  Flat {coupon.discountValue}
                </p>
                <p style={{ margin: "0" }}>
                  Flat {coupon.discountValue} off
                </p>
              </div>
            )}
            <button
              className="coupon-apply-btn-1"
              onClick={() => ApplyCoupon(coupon)}
            >
              APPLY
            </button>
          </div>
          <div className="" style={{ padding: "5px 0px 10px 0px", width: "100%" }}>
            <div className="space-between">
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
                className="couponName"
              >
                {coupon.couponName}
              </label>
            </div>
            <div>
              <label style={{ color: "grey", padding: "2px 0px" }}>
                {coupon.couponMethod}
              </label>
              {coupon.couponTitleOrCode !== "" && (
                <>
                  <br />
                  <label style={{ color: "grey", padding: "0" }}>
                    <>
                      {coupon.couponMethodId == 1 ? (
                        <label htmlFor="">
                          Use code {coupon.couponTitleOrCode} & get flat{" "}
                          {coupon.discountValue}% off on this order.
                        </label>
                      ) : (
                        <label htmlFor="">
                          Use Title {coupon.couponTitleOrCode} & get flat{" "}
                          {coupon.discountType == "1" ? (
                            <>{coupon.discountValue}%</>
                          ) : (
                            <>{coupon.discountValue}</>
                          )}
                          off on this order.
                        </label>
                      )}
                    </>
                  </label>
                </>
              )}
            </div>
          </div>
        </div>
      ))
  ) : (
    // Show an image when no coupons are visible
    <div className="no-coupons">
      <img style={{width:"30%"}} src={NoCoupon} alt="No Coupons Available" />
      <h3>No coupons available</h3>
    </div>
  )}
</div>


  {/* <div className="coupon-box-container">
        <div className="coupon-box-1"> <img className="coupon-image-1" src={coupon} alt="" /> 
        <div className="coupon-txt-box-1">
          <p className="coupon-off-button">Flat 1000 </p>
          <p style={{margin:"0"}}>Flat Rs. 1000 off</p>
        </div>
          <button className="coupon-apply-btn-1">APPLY</button>
        
         </div>
        <div className="coupon-box-1"> <img className="coupon-image-1" src={coupon} alt="" /> 
        <div className="coupon-txt-box-1">
          <p className="coupon-off-button">Flat 500 </p>
          <p style={{margin:"0"}}>Flat Rs. 500 off</p>
        </div>
          <button className="coupon-apply-btn-1">APPLY</button>
        
         </div>
        <div className="coupon-box-1"> <img className="coupon-image-1" src={coupon} alt="" /> 
        <div className="coupon-txt-box-1">
          <p className="coupon-off-button">Flat 300 </p>
          <p style={{margin:"0"}}>Flat Rs. 300 off</p>
        </div>
          <button className="coupon-apply-btn-1">APPLY</button>
        
         </div>
        <div className="coupon-box-1"> <img className="coupon-image-1" src={coupon} alt="" /> 
        <div className="coupon-txt-box-1">
          <p className="coupon-off-button">Flat 200 </p>
          <p style={{margin:"0"}}>Flat Rs. 200 off</p>
        </div>
          <button className="coupon-apply-btn-1">APPLY</button>
        
         </div>
        <div className="coupon-box-1"> <img className="coupon-image-1" src={coupon} alt="" /> 
        <div className="coupon-txt-box-1">
          <p className="coupon-off-button">Flat 2000 </p>
          <p style={{margin:"0"}}>Flat Rs. 2000 off</p>
        </div>
          <button className="coupon-apply-btn-1">APPLY</button>
        
         </div>
    </div>      */}
      </div>
    </div>
  );
};

export default AddCouponModal;
