import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

function parseBoolean(v) {
  if (typeof v === "boolean") {
    return v;
  } else if (typeof v === "string") {
    v = v.toLowerCase().trim();
    return v === "true";
  } else {
    console.log("error", v);
    return false;
  }
}

const APIConfiguration = {
  insertApiConfig: async (data, optionalData) => {
    debugger;
    var param_data = [];
    for (var i = 0; i < optionalData.length; i++) {
      param_data.push({
        paramHeader: optionalData[i].paramHeader,
        paramName: optionalData[i].paramName,
        paramType: optionalData[i].paramType,
        paramValue: optionalData[i].paramValue
      });
    }

    console.log(data)
    try {
      const apiUrl = `${WebApiUrl}/api/APIConfiguration`;
      const response = await axios.post(
        apiUrl,
        {
          configType: data.configType,
          formateName: data.formateName,
          accessCode: data.accessCode,
          workingKey: data.workingKey,
          isDefault: parseBoolean(data.isDefault),
          isActive: parseBoolean(data.isActive),
          parameterNameAndValue: param_data
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response);
      return response;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  getAPIConfigurationById: async (ApiId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/APIConfiguration?ApiId=${ApiId}`;
      const response = await axios.get(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      //setEditCallBack(response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  // ************************* Get All ***************************
  getAPIConfiguration: async (controlType) => {
    // debugger
    try {
      const apiUrl = `${WebApiUrl}/api/APIConfiguration/GetAllApiConfiguration?ControlType=${controlType}`;
      const response = await axios.post(apiUrl, {
        filter: [],
        order: [
          {
            propertyName: "string",
            ascending: false
          }
        ],
        pageNumber: 0,
        pageSize: 50000
      }, {
        withCredentials: true,
      });
      console.log('Response:', response.data);
      return response.data;

    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  // ************************* Get All with filters ***************************
  getAPIConfigurationFilter: async (controlType, filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/APIConfiguration/GetAllApiConfiguration?ControlType=${controlType}`;
      const response = await axios.post(apiUrl, {
        filter: filteredData,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        pageNumber: 0,
        pageSize: 0
      }, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  // ************************* Get All ***************************
  getAPIConfigurationFilter: async () => {
    // debugger
    try {
      const apiUrl = `${WebApiUrl}/api/APIConfiguration/GetAllApiConfiguration`;
      const response = await axios.post(apiUrl, {
        filter: [],
        order: [
          {
            propertyName: "id",
            ascending: false
          }
        ],
        pageNumber: 0,
        pageSize: 500000
      }, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
    }
  },


  getApiParameterNameAndValue: async (mastType, ApiId) => {
    //  debugger;
    try {
      const apiUrl = `${WebApiUrl}/api/APIConfiguration/GetApiParameterNameAndValue?MasterType=${mastType}&ApiId=${ApiId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // *********************  Update API Configuration ***************
  updateAPIConfiguration: async (data, data1) => {
    console.log(data);
    try {
      const apiUrl = `${WebApiUrl}/api/APIConfiguration?ApiId=${data.id}`;
      const response = await axios.put(
        apiUrl,
        {
          id: data.id,
          configType: data.configType,
          formateName: data.formateName,
          accessCode: data.accessCode,
          workingKey: data.workingKey,
          isDefault: parseBoolean(data.isDefault),
          isActive: parseBoolean(data.isActive),
          parameterNameAndValue: data1,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess;

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  deleteAPIConfiguration: async (ApiId) => {
    console.log(ApiId)
    try {
      console.log("delete");
      const apiUrl = `${WebApiUrl}/api/APIConfiguration?ApiId=${ApiId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


};
export default APIConfiguration;