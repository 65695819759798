import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../MyContext";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import LoyaltyMaster from "../../../API/API_SERVISES/LoyaltyMaster";
import CouponMasterApi from "../../../API/API_SERVISES/CouponMaster";

const BillDetailCard = (props) => {
  const {
    companyData,
    AppliedCouponData,
    totalDiscountAmt,
    totalPrice,
    InputValues,
    totalDiscountAmtWithoutDiscount,
    MembershipItems,
    AddMembershipToCart,
    isMembershipCardAdded,
    setMembershipItems,
    AutoApplyCoupon,
    // AutoApplyCouponType,
    // setAutoApplyCouponType,
    setIsMembershipCardAdded,
    setAutoApplyCoupon,
    setAllCouponsData,
    AllCouponsData,
    isMembershipDetails,
    setIsMembershipDetails,
    setLoading
  } = useContext(MyContext);

  // const [membershipItem, setMembershipItem] = useState({});

  useEffect(() => {
    if (isMembershipDetails && isMembershipDetails == 0) {
      var MembershipDetailsSring = localStorage.getItem("MembershipDetails"); // This is a string
      var MembershipDetails = JSON.parse(MembershipDetailsSring);
      if (MembershipDetails) {
        setIsMembershipDetails(MembershipDetails);
      }
    }
  }, []);

  useEffect(() => {
    console.log("AutoApplyCoupon", AutoApplyCoupon);
  }, [AutoApplyCoupon]);

  useEffect(() => {
    const GetmembershipItem = async () => {
      const filteredata = [
        {
          fieldName: "productCategory",
          operatorName: "equal",
          compareValue: 3,
        },
      ];
      var Item = await ItemMaster.GetItemMasterFilter(filteredata);
      if (Item && Item.length > 0) {
        setMembershipItems(Item);
      }
    };
    debugger
    if (MembershipItems.length == 0) {
      GetmembershipItem();
    }
  }, []);
  useEffect(() => {
    const GetData = async () => {
      // debugger;
      // if (AutoApplyCoupon.length == 0) {
        setLoading(true)
      if (MembershipItems && MembershipItems.length > 0) {
        MembershipItems.forEach(async (IsmembershipinCartDetail, index) => {
          var membershipDetails =
            await LoyaltyMaster.GetLoyaltyMemberShipAccountCardDetail(
              IsmembershipinCartDetail.iUseMembershipCard
            );
          if (membershipDetails) {
            var AllCoupondata;
            if (AllCouponsData || AllCouponsData.length == 0) {
              AllCoupondata = await CouponMasterApi.getCouponMaster();
              setAllCouponsData(AllCoupondata);
            } else {
              AllCoupondata = AllCouponsData;
            }
            var CouponData1 = AllCoupondata.filter((item) =>
              membershipDetails.autoApplyCouponsList.includes(item.id)
          );

          if(CouponData1[index]?.minPurchaseReqValue > totalDiscountAmtWithoutDiscount){
            setLoading(false)
            return
          } 
            debugger;
            setAutoApplyCoupon((prevState) => {
              const isAlreadyAdded = prevState.some(
                (coupon) => coupon.CardId === IsmembershipinCartDetail?.d2Id
              );
              if (isAlreadyAdded) return prevState;
              return [
                ...prevState,
                {
                  disVal: CouponData1[index]?.discountValue,
                  disType: CouponData1[index]?.discountType,
                  CardId: IsmembershipinCartDetail?.d2Id,
                },
              ];
            });
            setLoading(false)
          }
          else{
            setLoading(false)
          }
        });
        // }
      }else{
        setLoading(false)
      }
    };

    GetData();
  }, [MembershipItems , totalDiscountAmtWithoutDiscount]);

  function formatDateWithFullMonth(yyyyMmDd) {
    if (!yyyyMmDd) return ""; // Handle null/undefined cases

    // Convert the input string to a Date object
    let date = new Date(yyyyMmDd);

    // Extract day, month, and year
    let day = date.getDate();
    let month = date.toLocaleString("en-US", { month: "long" }); // Get full month name (e.g., "January")
    let year = date.getFullYear();

    // Function to add ordinal suffix (st, nd, rd, th)
    function getDaySuffix(day) {
      if (day >= 11 && day <= 13) return "th"; // Special case for 11th, 12th, 13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    // Return formatted date
    return `${day}${getDaySuffix(day)} ${month} ${year}`;
  }

  return (
    <div
      className="cartpage-pricebox"
      style={{
        paddingBottom: "0px",
        paddingTop: "10px",
        width: props.width || "90%",
      }}
    >
      <div className="pricebox-type-1">
        <div className="pricebox-heading">Item Total</div>
        <div className="pricebox-heading-2">
          {companyData.currencySymbol} {totalDiscountAmtWithoutDiscount}
        </div>
      </div>
      <div style={{ color: "rgb(17, 186, 216)" }} className="pricebox-type-1">
        <div className="pricebox-heading">Total discount</div>
        <div className="pricebox-heading-2">
          <p
            style={{
              margin: "0",
              paddingLeft: "10px",
              width: "100%",
            }}
          >
            Flat &nbsp;
            {totalDiscountAmt || 0}
            {companyData.currencySymbol}
          </p>
        </div>
      </div>
      <div className="pricebox-type-1">
        <div className="pricebox-heading">Net Amount</div>
        <div className="pricebox-heading-2">
          {companyData.currencySymbol} {totalPrice}
        </div>
      </div>
      <div
        style={{
          borderBottom: isMembershipCardAdded
            ? "0"
            : "1px dashed rgb(179, 179, 179)",
        }}
        className="pricebox-type-1"
      >
        <div className="pricebox-heading Total-bold-pay">
          Total Payable (incl. Taxes)
        </div>
        <div className="pricebox-heading-2 Total-bold-pay">
          {companyData.currencySymbol} {totalPrice}
        </div>
      </div>
      {isMembershipDetails != 0 ? (
        <>
          <div style={{ border: "0" }} className="pricebox-type-1">
            <div className="pricebox-heading Total-bold-pay">
              Your Membership Expiry Date Is :- <br />{" "}
              {formatDateWithFullMonth(isMembershipDetails.cardExpiryDate)}
            </div>
            <div className="pricebox-heading-2 Total-bold-pay">
              <button
                style={{ width: "85px", lineHeight: "20px" }}
                onClick={() =>
                  AddMembershipToCart("", isMembershipDetails.itemId_d2)
                }
              >
                Extend Validity
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {AutoApplyCoupon.length != 0 && (
            <>
              {isMembershipCardAdded == false && (
                <>
                  {MembershipItems.length == AutoApplyCoupon.length && (
                    <>
                      {MembershipItems.map((item, index) => (
                        <div
                          style={{
                            borderBottom:
                              index === MembershipItems.length - 1
                                ? "none"
                                : "1px dashed rgb(179, 179, 179)",
                          }}
                          className="pricebox-type-1"
                        >
                          <div className="pricebox-heading Total-bold-pay">
                            {AutoApplyCoupon[index].disType == "1" ? (
                              <>
                                Add {item.printName} Membership and Get{" "}
                                {AutoApplyCoupon[index].disVal || 0} % off
                              </>
                            ) : (
                              <>
                                Add {item.printName} Membership and Get Flat{" "}
                                {AutoApplyCoupon[index].disVal || 0}{" "}
                                {companyData.currencySymbol} off
                              </>
                            )}
                          </div>
                          <div className="pricebox-heading-2 Total-bold-pay">
                            <button onClick={() => AddMembershipToCart(item)}>
                              Add
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BillDetailCard;
