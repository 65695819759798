import React, { useContext, useEffect, useState } from "react";
import Coming from "../../Assets/Image_lc/card-images/coming soon.jpeg";
import MyContext from "../../MyContext";
import { useLocation } from "react-router-dom";
import ShopBanner from "../../../API/API_SERVISES/Shopbanner";

const ComingSoon = () => {

const {imageBaseUrl,shopBanner, currentUser} = useContext(MyContext);
console.log(imageBaseUrl);
const [apiBannerData, setApiBannerData] = useState([]);

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const location = useLocation();
const query = useQuery();
const productId = query.get('productId');

  const ImageId =
  apiBannerData &&
  apiBannerData
    // .filter((item) => item.showInCategoryGroup == productId)
    .map((image) => image.bannerImageId);
console.log(ImageId);


const fetchAllBannerdata = async () => {
  if (currentUser === true) {
    try {
      const filterData = [
        {
          fieldName: "imageBannerName",
          operatorName: "Equal",
          compareValue: "ComingSoon",
        },
      ];
      const response = await ShopBanner.GetShopBannersFilter(filterData);
      if (response) {
        console.log("Response", response);
        setApiBannerData(response);
      }
    } catch (error) {
      alert(error);
      throw new error("Unable To fetch Data");
    }
  }
};
useEffect(() => {
  fetchAllBannerdata();
}, [currentUser, shopBanner]);


  return (
      <div >
        <div className="coming-soon-container">
        {ImageId.length > 0 && (
          <img style={{
            height: ImageId.length === 0 ? "0" : "max-content",
          }}
            className="coming-soon-img"
            src={ImageId.length > 0 ? `${imageBaseUrl}${ImageId[0]}` : ""}
          />
          )}

        </div>
      </div>

  );
};

export default ComingSoon;
