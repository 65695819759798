import React, { useContext } from "react";
import Navbar_lc from "./Navbar/Navbar_lc";
import NavBarType2 from "../Component/Navbar/NavBarType2";
import { useLocation } from 'react-router-dom';
import MyContext from "../MyContext";
import NavBarType1 from "./Navbar/NavBarType1";

const NavBar = (props) => {
  const { wishList } = props
  // console.log("", wishList);
  const darktheme = () => {
    document.getElementById("body").classList.toggle("dark-theme");
  };
  // console.log(wishList);
  const location = useLocation();
  const currentPath = location.pathname;
  const showButton = () => {
    if (currentPath === '/Storelocator' || currentPath === '/Storehome/Kenya') {
      return (
        <div className=""></div>
      );
    } else if (currentPath === '/Checkout' ) {
      return (

        <div className="nav-space2"></div>

      );
    } else {

      return (
        <div>
          <div className="nav-space"></div>
        </div>
      );
    }
  };

  return (
    <div>
      <label htmlFor="theme" className="theme">
        <span className="theme__toggle-wrap">
          <input
            onClick={darktheme}
            id="theme"
            className="theme__toggle"
            type="checkbox"
            role="switch"
            name="theme"
            value="dark" />
          <span className="theme__fill"></span>
          <span className="theme__icon"></span>
        </span>
      </label>
      {showButton()}

      {/* <NavBarType1 /> */}
      {/* <NavBarType2 /> */}
      <Navbar_lc wishList={wishList} />

    </div>
  );
};

export default NavBar;
