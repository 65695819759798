import React, { useState, useRef, useEffect } from "react";
import img from "../../Assets/Image_lc/login l2c.webp";
import LoginApi from "../../../API/API_SERVISES/User";
import MyContext from "../../MyContext";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
// import UserCredential from "../../../API/API_SERVISES/User";
import ShopBanner from "../../../API/API_SERVISES/Shopbanner";

const StoreloginModalBox = (props) => {
  const { close } = props;

  const {imageBaseUrl,shopBanner, currentUser} = useContext(MyContext);

  const [showPassword, setShowPassword] = useState(false);
  const [UserDetails, setUserDetails] = useState([]);
  const [password, setPassword] = useState("");
  const [apiBannerData, setApiBannerData] = useState([]);


  useEffect(() => {
    console.log(UserDetails);
  }, [UserDetails]);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const location = useLocation();
const query = useQuery();
const productId = query.get('productId');

  const ImageId =
  apiBannerData &&
  apiBannerData
    // .filter((item) => item.showInCategoryGroup == productId)
    .map((image) => image.bannerImageId);
console.log(ImageId);

const fetchAllBannerdata = async () => {
  if (currentUser === true) {
    try {
      const filterData = [
        {
          fieldName: "imageBannerName",
          operatorName: "Equal",
          compareValue: "LoginImage",
        },
      ];
      const response = await ShopBanner.GetShopBannersFilter(filterData);
      if (response) {
        console.log("Response", response);
        setApiBannerData(response);
      }
    } catch (error) {
      alert(error);
      throw new error("Unable To fetch Data");
    }
  }
};
useEffect(() => {
  fetchAllBannerdata();
}, [currentUser, shopBanner]);


  const togglePasswordVisibility = (val) => {
    setShowPassword(!showPassword);
  };

  const [inputValues, setInputValues] = useState({
    compCode: "",
    userName: "",
    password: "",
    comClientRefId: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    handleInputChange(e);
  };

  const login = async () => {
    debugger;
    const updatedSentence = inputValues.userName.trim();

    const updatedValues = {
      ...inputValues,
      userName: updatedSentence,
    };
    try {
      //   setLoading(true);
      const response = await LoginApi.loginUser(updatedValues);
      if (response.isSuccess) {
        localStorage.setItem("StoreLogin", JSON.stringify(response.result));
        setUserDetails(response.result);
        alert("Store login Successful");
        close();
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      //   setLoading(false);
    }
  };

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };
  const handleKeyPress1 = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      login();
    }
  };

  return (
    <div
      style={{ zIndex: 100 }}
      className="form-container-login-theme3 form-loginWidth-Lc"
    >

      {ImageId.length > 0 && (
      <img style={{
        height: ImageId.length === 0 ? "0" : "max-content",
      }}
      className="Login-Img_Lc" src={ImageId.length > 0 ? `${imageBaseUrl}${ImageId[0]}` : ""}
       alt="" />
      )}


      <i
        onClick={close}
        style={{ cursor: "pointer" }}
        className="fa-solid fa-xmark modal-cross"
      ></i>
      <div className="SignIn-Padding-Lc">
        <p style={{ marginBottom: "20px", border: "none" }} className="title">
          Store Login
        </p>
        <div className="form-modal">
          <input
            type="text"
            className="input-modal"
            placeholder="Comp id"
            ref={input1Ref}
            onKeyDown={(event) => handleKeyPress(event, input2Ref)}
            onChange={handleInputChange}
            name="compCode"
            required
            value={inputValues.compCode}
          />
          <input
            type="text"
            className="input-modal"
            placeholder="Username"
            ref={input2Ref}
            onKeyDown={(event) => handleKeyPress(event, input3Ref)}
            required
            onChange={handleInputChange}
            name="userName"
            value={inputValues.userName}
          />
          <div style={{ position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"}
              className="input-modal"
              value={password}
              name="password"
              onChange={handlePasswordChange}
              ref={input3Ref}
              onKeyPress={handleKeyPress1}
              required
              placeholder="Password"
            />
            <div className="eyebutton">
              {showPassword ? (
                <i className="fa-solid fa-eye-slash"></i>
              ) : (
                <i className="fa-solid fa-eye"></i>
              )}
            </div>
          </div>
          <p className="page-link">
            {/* <span className="page-link-label">Forgot Password?</span> */}
            <div
              className="input-image position-icon-pw"
              onClick={() => togglePasswordVisibility("password")}
            ></div>
          </p>
          <div
            style={{ textDecoration: "none", textAlign: "center" }}
            className="form-btn"
            onClick={login}
            ref={input4Ref}
            onKeyDown={handleKeyPress1}
          >
            Log in
          </div>
        </div>
        {/* <div className="sign-up-label">
          New Member?
          <span className="sign-up-link">Create An Account</span>
        </div> */}
        <div className="buttons-container"></div>
      </div>
    </div>
  );
};

export default StoreloginModalBox;
