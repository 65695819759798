import React, { useState } from "react";

const ResetPasswordPanel = (props) => {
  const { handleInputChangeSignIn, ChangePassword, inputValuesForSignIn } =
    props;

  const { close } = props;

  const [showPasswordNew, setShowPasswordNew] = useState(false);

  const togglePasswordVisibilityNew = (val) => {
    setShowPasswordNew(!showPasswordNew);
  };

  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const togglePasswordVisibilityConfirm = (val) => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  return (
    <div className="form-container Changepwd-height">
      <i
        style={{ cursor: "pointer" }}
        onClick={close}
        className="fa-solid fa-xmark modal-cross"
      ></i>
      <div className="SignIn-Padding-Lc">
        <p style={{ marginBottom: "20px", border: "none" }} className="title">
          Change Password
        </p>
        <div className="form-modal">
          <input
            type={showPasswordNew ? "text" : "password"}
            className="input-modal"
            placeholder="New Password"
            name="password"
            id="password1"
            onChange={handleInputChangeSignIn}
            value={inputValuesForSignIn.password}
          />
          <input
            type={showPasswordConfirm ? "text" : "password"}
            className="input-modal"
            placeholder="Confirm Password"
            name="reEnterpassword"
            id="password1"
            onChange={handleInputChangeSignIn}
            value={inputValuesForSignIn.reEnterpassword}
          />
          <div
            style={{
              textDecoration: "none",
              textAlign: "center",
              marginTop: "5px",
            }}
            className="form-btn"
            onClick={ChangePassword}
          >
            Change Password
          </div>
          <div
            className="input-image position-icon-pw change-pwd-top"
            onClick={() => togglePasswordVisibilityNew("password")}
          >
            {showPasswordNew ? (
              <i className="fa-solid fa-eye-slash"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </div>
          <div
            className="input-image position-icon-pw Confirm-pwd-top"
            onClick={() => togglePasswordVisibilityConfirm("password")}
          >
            {showPasswordConfirm ? (
              <i className="fa-solid fa-eye-slash"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordPanel;
