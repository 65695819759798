import React, { useState, useEffect, useContext, useRef } from "react";
import Transaction from "../../../API/API_SERVISES/Transaction";
import moment from "moment";
import MyContext from "../../MyContext";
import Loader1 from "../Loaders/Loader1";

const ModelSavedPrescription = (props) => {
  const {
    itemData,
    refId,
    closeModal,
    toggleModalPower,
    fetchMyOrderData,
    isSelectShow,
    setRunCount,
    lensData,
  } = props;

  const { imageBaseUrl, setLoading } = useContext(MyContext);

  const [showTable, setShowTable] = useState(false);
  // const [lensData, setLensData] = useState();
  const [userData, setUserData] = useState();
  const [selectedData, setSelectedData] = useState();

  console.log("itemData", itemData);

  const handleSelectClick = (id) => {
    const filterData = lensData.find((item) => item.id == id);
    const data = {
      indx: 0,
      rItemId_d2: itemData.itemId || filterData?.rItemId_d2 || 0,
      rItemName: itemData.itemName || filterData?.rItemName || "",
      rSphDv: filterData?.rSphDv,
      rCylDv: filterData?.rCylDv,
      rAxisDv: filterData?.rAxisDv,
      rVnDv: filterData?.rVnDv,
      rSphNv: filterData?.rSphNv,
      rCylNv: filterData?.rCylNv,
      rAxisNv: filterData?.rAxisNv,
      rVnNv: filterData?.rVnNv,
      rAdd: filterData?.rAdd,
      rpB1: filterData?.rpB1,
      rpB2: filterData?.rpB2,
      lItemId_d2: itemData.itemId || filterData?.lItemId_d2 || 0,
      lItemName: itemData.itemName || filterData?.lItemName,
      lSphDv: filterData?.lSphDv,
      lCylDv: filterData?.lCylDv,
      lAxisDv: filterData?.lAxisDv,
      lVnDv: filterData?.lVnDv,
      lSphNv: filterData?.lSphNv,
      lCylNv: filterData?.lCylNv,
      lAxisNv: filterData?.lAxisNv,
      lVnNv: filterData?.lVnNv,
      lAdd: filterData?.lAdd,
      lpB1: filterData?.lpB1,
      lpB2: filterData?.lpB2,
      printLenseStatus: filterData?.printLenseStatus,
      printLensEye: filterData?.printLensEye,
      lenseType: itemData?.lensType || filterData?.lenseType || "",
      prescribedBy: filterData?.prescribedBy,
      partyId: filterData?.partyId,
      partyName: filterData?.partyName,
      frameName: filterData?.frameName,
      remark: filterData?.remark,
      nextVisit: filterData?.nextVisit,
      nvDate: filterData?.nvDate,
      lensExpRem: filterData?.lensExpRem,
      lExDate: filterData?.lExDate,
      pdr: filterData?.pdr,
      pdl: filterData?.pdl,
      pdrl: filterData?.pdrl,
      deliverDays: filterData?.deliverDays,
      deliverTime: filterData?.deliverTime,
      contact: filterData?.contact,
      transpose: filterData?.transpose,
      ar: filterData?.ar,
      im: filterData?.im,
      linkedItm: filterData?.linkedItm || 0,
    };
    setSelectedData(data);
    setShowTable(true);
  };

  // Handle click to go back (hide the table)
  const handleBackClick = () => {
    setShowTable(false);
  };

  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const loginDataString = localStorage.getItem("accountLogin");
        if (loginDataString) {
          const loginData = JSON.parse(loginDataString);
          setUserData(loginData);
          if (hasFetched.current) {
            setLoading(false);
            return;
          } else {
            // hasFetched.current = true;
            // const data = await Transaction.getSaleLensDataByTransId(0, loginData.d2Id);
            // const filterData = data.filter(item => !item.imgId); // Simplified the condition
            // setLensData(filterData);
            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSaveBtnClick = async () => {
    setLoading(true);
    const transData = await Transaction.GetTransactionById(
      itemData.transId,
      71
    );
    const saveLens = await Transaction.SetTransLensData(
      selectedData,
      transData
    );
    const createTrans = await Transaction.createTransaction(transData);
    if (saveLens) {
      // setIsModalOpenPower(prevState => ({
      //     ...prevState,
      //     [itemData.vouchNo]: !prevState[itemData.vouchNo] // Toggle only the specific modal state
      // }));
      setRunCount(0);
      fetchMyOrderData();
      toggleModalPower(itemData.mastId2, false);
      alert("Updated Successfully");
      setLoading(false);
      closeModal();
    }
    setLoading(false);
  };

  return (
    <div>
      {isSelectShow && (
        <div className="flex-ord-sold mdl-manual-pre">
          <div
            className="review-box-image order-image-box"
            style={{ width: "150px", height: "100px" }}
          >
            <img
              className="favourite-img fav-image-ord"
              src={`${imageBaseUrl}${itemData.imgId}`}
              alt=""
            />
          </div>
          <div
            className="review-box-detail item-details-width-ord"
            style={{ height: "auto" }}
          >
            <div className="review-box-detail-line-1">
              <div className="name-top-item">{itemData.itemName}</div>
            </div>
          </div>
        </div>
      )}

      <div className="learn-read-prescription">
        {!showTable && (
          <>
            <div className="saved-prescription-your">MY SAVED PRESCRIPTION</div>
            <div className="saved-prescription-your">
              (Select from your saved prescription)
            </div>
            <div className="lenses-show-multiple">
              {lensData &&
                lensData.map((data, index) => {
                  debugger;
                  var lensType = data.lenseType
                    ? data.lenseType.includes(",")
                      ? data.lenseType.split(",")
                      : [data.lenseType]
                    : [];
                  if (data.lenseType != "" && data.lenseType != "NA") {
                    if (
                      (lensType ||
                        lensType.includes("0") ||
                        lensType.includes("4")) &&
                      (data.rAdd == 0 || data.rAdd == "") &&
                      (data.lAdd == 0 || data.lAdd == "")
                    ) {
                      return (
                        <div
                          className="flex-prescripton"
                          key={index}
                          style={{ fontFamily: "calibri" }}
                        >
                          <div
                            className="left-prescription-width"
                            style={{ width: "100%" }}
                          >
                            <>
                              <div className="flex-power-type">
                                <p className="prescription-name-user">
                                  NAME :{" "}
                                </p>
                                <p className="prescription-name-user">
                                  {userData && userData.name}
                                </p>
                              </div>
                              <div className="flex-power-type">
                                <p className="prescription-name-user">
                                  POWER TYPE :{" "}
                                </p>
                                {data.lenseType == "0" && (
                                  <p className="prescription-name-user">
                                    Single Vision
                                  </p>
                                )}
                                {data.lenseType == "1" && (
                                  <p className="prescription-name-user">
                                    Bifocal Vision
                                  </p>
                                )}
                                {data.lenseType == "3" && (
                                  <p className="prescription-name-user">
                                    Progressive Vision
                                  </p>
                                )}
                                {data.lenseType == "4" && (
                                  <p className="prescription-name-user">
                                    Tinted Single Vision
                                  </p>
                                )}
                                {data.lenseType == "5" && (
                                  <p className="prescription-name-user">
                                    Tineted Bifocal Vision
                                  </p>
                                )}
                                {data.lenseType == "6" && (
                                  <p className="prescription-name-user">
                                    Tinted Progressive Vision
                                  </p>
                                )}
                                {data.lenseType === "" && (
                                  <p className="prescription-name-user">Lens</p>
                                )}
                              </div>
                              <div className="flex-power-type">
                                <p>
                                  Created on{" "}
                                  {moment(data.createdOn).format("DD MMM YYYY")}
                                </p>
                              </div>
                            </>
                            <table
                              style={{
                                width: "100%",
                                borderCollapse: "collapse",
                              }}
                            >
                              <thead className="header-prescription-eye">
                                <tr>
                                  <th className="header-left-right-eye">EYE</th>
                                  <th className="header-left-right-eye">
                                    RIGHT EYE
                                  </th>
                                  <th className="header-left-right-eye">
                                    LEFT EYE
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {(lensType.includes("0") ||
                                  lensType.includes("4")) && ( //Single
                                  <>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Spherical
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rSphDv && data.rSphDv != ""
                                          ? data.rSphDv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lSphDv && data.lSphDv != ""
                                          ? data.lSphDv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Cylindrical
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rCylDv && data.rCylDv != ""
                                          ? data.rCylDv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lCylDv && data.lCylDv != ""
                                          ? data.lCylDv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Axis
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rAxisDv && data.rAxisDv != ""
                                          ? data.rAxisDv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lAxisDv && data.lAxisDv != ""
                                          ? data.lAxisDv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        ADD (Near Vision Power)
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rAdd && data.rAdd != ""
                                          ? data.rAdd
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lAdd && data.lAdd != ""
                                          ? data.lAdd
                                          : "-"}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {(lensType.includes("1") ||
                                  lensType.includes("3") ||
                                  lensType.includes("5") ||
                                  lensType.includes("6")) && ( //Bifocal
                                  <>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Spherical
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rSphNv && data.rSphNv != ""
                                          ? data.rSphNv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lSphNv && data.lSphNv != ""
                                          ? data.lSphNv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Cylindrical
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rCylNv && data.rCylNv != ""
                                          ? data.rCylNv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lCylNv && data.lCylNv != ""
                                          ? data.lCylNv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Axis
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rAxisNv && data.rAxisNv != ""
                                          ? data.rAxisNv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lAxisNv && data.lAxisNv != ""
                                          ? data.lAxisNv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        ADD (Near Vision Power)
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rAdd && data.rAdd != ""
                                          ? data.rAdd
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lAdd && data.lAdd != ""
                                          ? data.lAdd
                                          : "-"}
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            {isSelectShow && (
                              <div className="">
                                <button
                                  className="btn-pay-now select-btn-pres"
                                  onClick={() => handleSelectClick(data.id)}
                                >
                                  SELECT
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    } else if (
                      (lensType ||
                        lensType.includes("1") ||
                        lensType.includes("3") ||
                        lensType.includes("5") ||
                        lensType.includes("6")) &&
                      data.rAdd != 0 &&
                      data.rAdd != "" &&
                      data.lAdd != 0 &&
                      data.lAdd != ""
                    ) {
                      return (
                        <div
                          className="flex-prescripton"
                          key={index}
                          style={{ fontFamily: "calibri" }}
                        >
                          <div
                            className="left-prescription-width"
                            style={{ width: "100%" }}
                          >
                            <>
                              <div className="flex-power-type">
                                <p className="prescription-name-user">
                                  NAME :{" "}
                                </p>
                                <p className="prescription-name-user">
                                  {userData && userData.name}
                                </p>
                              </div>
                              <div className="flex-power-type">
                                <p className="prescription-name-user">
                                  POWER TYPE :{" "}
                                </p>
                                {data.lenseType == "0" && (
                                  <p className="prescription-name-user">
                                    Single Vision
                                  </p>
                                )}
                                {data.lenseType == "1" && (
                                  <p className="prescription-name-user">
                                    Bifocal Vision
                                  </p>
                                )}
                                {data.lenseType == "3" && (
                                  <p className="prescription-name-user">
                                    Progressive Vision
                                  </p>
                                )}
                                {data.lenseType == "4" && (
                                  <p className="prescription-name-user">
                                    Tinted Single Vision
                                  </p>
                                )}
                                {data.lenseType == "5" && (
                                  <p className="prescription-name-user">
                                    Tineted Bifocal Vision
                                  </p>
                                )}
                                {data.lenseType == "6" && (
                                  <p className="prescription-name-user">
                                    Tinted Progressive Vision
                                  </p>
                                )}
                                {data.lenseType === "" && (
                                  <p className="prescription-name-user">Lens</p>
                                )}
                              </div>
                              <div className="flex-power-type">
                                <p>
                                  Created on{" "}
                                  {moment(data.createdOn).format("DD MMM YYYY")}
                                </p>
                              </div>
                            </>
                            <table
                              style={{
                                width: "100%",
                                borderCollapse: "collapse",
                              }}
                            >
                              <thead className="header-prescription-eye">
                                <tr>
                                  <th className="header-left-right-eye">EYE</th>
                                  <th className="header-left-right-eye">
                                    RIGHT EYE
                                  </th>
                                  <th className="header-left-right-eye">
                                    LEFT EYE
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {(lensType.includes("0") ||
                                  lensType.includes("4")) && ( //Single
                                  <>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Spherical
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rSphDv && data.rSphDv != ""
                                          ? data.rSphDv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lSphDv && data.lSphDv != ""
                                          ? data.lSphDv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Cylindrical
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rCylDv && data.rCylDv != ""
                                          ? data.rCylDv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lCylDv && data.lCylDv != ""
                                          ? data.lCylDv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Axis
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rAxisDv && data.rAxisDv != ""
                                          ? data.rAxisDv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lAxisDv && data.lAxisDv != ""
                                          ? data.lAxisDv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        ADD (Near Vision Power)
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rAdd && data.rAdd != ""
                                          ? data.rAdd
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lAdd && data.lAdd != ""
                                          ? data.lAdd
                                          : "-"}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {(data.lenseType == "1" ||
                                  data.lenseType == "3" ||
                                  data.lenseType == "5" ||
                                  data.lenseType == "6") && ( //Bifocal
                                  <>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Spherical
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rSphNv && data.rSphNv != ""
                                          ? data.rSphNv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lSphNv && data.lSphNv != ""
                                          ? data.lSphNv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Cylindrical
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rCylNv && data.rCylNv != ""
                                          ? data.rCylNv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lCylNv && data.lCylNv != ""
                                          ? data.lCylNv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        Axis
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rAxisNv && data.rAxisNv != ""
                                          ? data.rAxisNv
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lAxisNv && data.lAxisNv != ""
                                          ? data.lAxisNv
                                          : "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="table-left-right-data">
                                        ADD (Near Vision Power)
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.rAdd && data.rAdd != ""
                                          ? data.rAdd
                                          : "-"}
                                      </td>
                                      <td className="table-prescription-data">
                                        {data.lAdd && data.lAdd != ""
                                          ? data.lAdd
                                          : "-"}
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            {isSelectShow && (
                              <div className="">
                                <button
                                  className="btn-pay-now select-btn-pres"
                                  onClick={() => handleSelectClick(data.id)}
                                >
                                  SELECT
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }
                  }
                })}
            </div>
          </>
        )}
        {/* ******************************* Second Table *************************************** */}
        {showTable && (
          <div>
            <div className="right-checked-circle">
              <i class="fa-regular fa-circle-check"></i>
            </div>
            <div className="review-submit">
              Please review your prescription before submitting
            </div>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead className="header-prescription-eye">
                <tr>
                  <th className="header-left-right-eye">EYE</th>
                  <th className="header-left-right-eye">RIGHT EYE</th>
                  <th className="header-left-right-eye">LEFT EYE</th>
                </tr>
              </thead>
              <tbody>
                {(selectedData.lenseType == "0" ||
                  selectedData.lenseType == "4") && ( //Single
                  <>
                    <tr>
                      <td className="table-left-right-data">Spherical</td>
                      <td className="table-prescription-data">
                        {selectedData.rSphDv && selectedData.rSphDv != ""
                          ? selectedData.rSphDv
                          : "-"}
                      </td>
                      <td className="table-prescription-data">
                        {selectedData.lSphDv && selectedData.lSphDv != ""
                          ? selectedData.lSphDv
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-left-right-data">Cylindrical</td>
                      <td className="table-prescription-data">
                        {selectedData.rCylDv && selectedData.rCylDv != ""
                          ? selectedData.rCylDv
                          : "-"}
                      </td>
                      <td className="table-prescription-data">
                        {selectedData.lCylDv && selectedData.lCylDv != ""
                          ? selectedData.lCylDv
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-left-right-data">Axis</td>
                      <td className="table-prescription-data">
                        {selectedData.rAxisDv && selectedData.rAxisDv != ""
                          ? selectedData.rAxisDv
                          : "-"}
                      </td>
                      <td className="table-prescription-data">
                        {selectedData.lAxisDv && selectedData.lAxisDv != ""
                          ? selectedData.lAxisDv
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-left-right-data">
                        ADD (Near Vision Power)
                      </td>
                      <td className="table-prescription-data">
                        {selectedData.rAdd && selectedData.rAdd != ""
                          ? selectedData.rAdd
                          : "-"}
                      </td>
                      <td className="table-prescription-data">
                        {selectedData.lAdd && selectedData.lAdd != ""
                          ? selectedData.lAdd
                          : "-"}
                      </td>
                    </tr>
                  </>
                )}
                {(selectedData ||
                  selectedData.includes("1") ||
                  selectedData.includes("3") ||
                  selectedData.includes("5") ||
                  selectedData.includes("6")) && ( //Bifocal
                  //     selectedData.lenseType == "1" ||
                  //   selectedData.lenseType == "3" ||
                  //   selectedData.lenseType == "5" ||
                  //   selectedData.lenseType == "6"
                  <>
                    <tr>
                      <td className="table-left-right-data">Spherical</td>
                      <td className="table-prescription-data">
                        {selectedData.rSphNv && selectedData.rSphNv != ""
                          ? selectedData.rSphNv
                          : "-"}
                      </td>
                      <td className="table-prescription-data">
                        {selectedData.lSphNv && selectedData.lSphNv != ""
                          ? selectedData.lSphNv
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-left-right-data">Cylindrical</td>
                      <td className="table-prescription-data">
                        {selectedData.rCylNv && selectedData.rCylNv != ""
                          ? selectedData.rCylNv
                          : "-"}
                      </td>
                      <td className="table-prescription-data">
                        {selectedData.lCylNv && selectedData.lCylNv != ""
                          ? selectedData.lCylNv
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-left-right-data">Axis</td>
                      <td className="table-prescription-data">
                        {selectedData.rAxisNv && selectedData.rAxisNv != ""
                          ? selectedData.rAxisNv
                          : "-"}
                      </td>
                      <td className="table-prescription-data">
                        {selectedData.lAxisNv && selectedData.lAxisNv != ""
                          ? selectedData.lAxisNv
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-left-right-data">
                        ADD (Near Vision Power)
                      </td>
                      <td className="table-prescription-data">
                        {selectedData.rAdd && selectedData.rAdd != ""
                          ? selectedData.rAdd
                          : "-"}
                      </td>
                      <td className="table-prescription-data">
                        {selectedData.lAdd && selectedData.lAdd != ""
                          ? selectedData.lAdd
                          : "-"}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            <div className="continue-btn">
              <button
                className="btn-pay-now continue-btn-select"
                onClick={handleSaveBtnClick}
              >
                Save
              </button>
            </div>
            <div className="back-color-mdl" onClick={handleBackClick}>
              BACK
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelSavedPrescription;
