import React, { useEffect, useState,useContext } from "react";
import dlt from "../../Assets/Icons/trash.png"
import coupon from "../../Assets/Icons/discount.png"
import Modal from "../../Component/Modal/Modal";
import AlertBox from "../../Component/AlertBox/AlertBox";
import AddCouponModal from "../../Component/Modal/AddCouponModal";
import MyContext from "../../MyContext";
import LoginPanel_Lc from "../../Component/Modal/LoginPanel_Lc";



const CartPage = () => {
  const {modal, setLoginPanel, myaccountmodal1 } = useContext(MyContext);
  const [alert , setAlert] = useState(false);
  const [addcoupon , setAddCoupon] = useState(false);



  const alertbox =()=>{
    setAlert(!alert)
  }

  
  const OpenAddCoupon =()=>{
    debugger
    var loginDataString = localStorage.getItem("accountLogin"); // This is a string
    var loginData = JSON.parse(loginDataString);
    if(loginData&&loginData.d2ID) {
      setAddCoupon(!addcoupon)
    }
    else{
      myaccountmodal1()
    }
  }
  
  useEffect(() => {
    // Scroll to the top of the page when the App component is mounted
    window.scrollTo(0, 0);
  }, []);


  // const signin =()=>{
  //   setLoginPanel(!modal)
  // }

  return (
    <div className="cartpage">
      <div className="cartpage-left">
        <div className="cartpage-heading">Review Order</div>
        <div className="cartpage-reviewbox">
          <div className="review-box">
            <div className="review-box-image">
              <img
                src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                alt=""
              />
            </div>
            <div className="review-box-detail">
              <div className="review-box-detail-line-1">
                <div className="review-box-name">
                  Peyush Bansal Shark Tank Monza Red Full Rim Hustler
                  Eyeglasses....
                </div>
                <div className="review-box-section">
                  {/* <i className="fa-solid fa-trash"></i> */}
                  <img onClick={alertbox} className="fa-trash" src={dlt} alt="" />
                </div>
              </div>
              <div className="review-box-detail-line-2">
                <div className="lens-txt">
                  Lens :{" "}
                  <div className="review-product-name">
                    Black Full Frame Eyeglasses
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "60px",
                  }}
                >
                  {" "}
                  <p className="review-price">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                  </p>
                  {/* <p className="review-price-2">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 2500
                  </p> */}
                </div>
              </div>
              <div className="review-box-detail-line-3">
                <div className="frame-txt">
                  frame :{" "}
                  <div className="review-product-name">
                    {" "}
                    ZERO Power - Gkb Thin Blue
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "60px",
                  }}
                >
                  {" "}
                  <p className="review-price">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 500
                  </p>
                  {/* <p className="review-price-2">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 2500
                  </p> */}
                </div>
              </div>
              <p className="review-box-detail-line-4">
                <div className="quantity">
                  <a href="cart" className="quantity__minus">
                    <span>-</span>
                  </a>
                  <input
                    name="quantity"
                    type="text"
                    className="quantity__input"
                    value="1"
                  />
                  <a href="cart" className="quantity__plus">
                    <span>+</span>
                  </a>
                </div>
              </p>
            </div>
          </div>

          <div className="review-box">
            <div className="review-box-image">
              <img
                src="https://happychashma.com/shop/images/product/Green_Year_Square_S10_Metal_Full_Rim_Square_Sunglass_Black_1672_1.jpg"
                alt=""
              />
            </div>
            <div className="review-box-detail">
              <div className="review-box-detail-line-1">
                <div className="review-box-name">
                  Peyush Bansal Shark Tank Monza Red Full Rim Hustler
                  Eyeglasses....
                </div>
                <div className="review-box-section">
                <img className="fa-trash" src={dlt} alt="" />
                </div>
              </div>
              <div className="review-box-detail-line-2">
                <div className="lens-txt">
                  Lens :{" "}
                  <div className="review-product-name">
                    Black Full Frame Eyeglasses
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "60px",
                  }}
                >
                  {" "}
                  <p className="review-price">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                  </p>
                  {/* <p className="review-price-2">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 2500
                  </p> */}
                </div>
              </div>
              <div className="review-box-detail-line-3">
                <div className="frame-txt">
                  frame :{" "}
                  <div className="review-product-name">
                    {" "}
                    ZERO Power - Gkb Thin Blue
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "60px",
                  }}
                >
                  {" "}
                  <p className="review-price">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 500
                  </p>
                  {/* <p className="review-price-2">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 2500
                  </p> */}
                </div>
              </div>
              <p className="review-box-detail-line-4">
                <div className="quantity">
                  <a href="cart" className="quantity__minus">
                    <span>-</span>
                  </a>
                  <input
                    name="quantity"
                    type="text"
                    className="quantity__input"
                    value="1"
                  />
                  <a href="cart" className="quantity__plus">
                    <span>+</span>
                  </a>
                </div>
              </p>
            </div>
          </div>

          <div className="review-box">
            <div className="review-box-image">
              <img
                src="https://happychashma.com/shop/images/product/Green_Year_9017_Metal_Half_Rim_52_Rectangle_Black_502_1.jpg"
                alt=""
              />
            </div>
            <div className="review-box-detail">
              <div className="review-box-detail-line-1">
                <div className="review-box-name">
                  Peyush Bansal Shark Tank Monza Red Full Rim Hustler
                  Eyeglasses....
                </div>
                <div className="review-box-section">
                <img className="fa-trash" src={dlt} alt="" />
                </div>
              </div>
              <div className="review-box-detail-line-2">
                <div className="lens-txt">
                  Lens :{" "}
                  <div className="review-product-name">
                    Black Full Frame Eyeglasses
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "60px",
                  }}
                >
                  {" "}
                  <p className="review-price">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                  </p>
                  {/* <p className="review-price-2">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 2500
                  </p> */}
                </div>
              </div>
              <div className="review-box-detail-line-3">
                <div className="frame-txt">
                  frame :{" "}
                  <div className="review-product-name">
                    {" "}
                    ZERO Power - Gkb Thin Blue
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "60px",
                  }}
                >
                  {" "}
                  <p className="review-price">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 500
                  </p>
          
                </div>
              </div>
              <p className="review-box-detail-line-4">
                <div className="quantity">
                  <a href="cart" className="quantity__minus">
                    <span>-</span>
                  </a>
                  <input
                    name="quantity"
                    type="text"
                    className="quantity__input"
                    value="1"
                  />
                  <a href="cart" className="quantity__plus">
                    <span>+</span>
                  </a>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="cartpage-right">
        <div style={{marginLeft:"5px"}} className="cartpage-heading">Price Summary</div>
        <div style={{cursor:"pointer"}} onClick={OpenAddCoupon} className="cartpage-pricebox">
          <div  className="pricebox-1">
            <div style={{display:"flex"}}>
            <img src={coupon} alt="" />
            <div  className="pricebox-coupon">Add Coupon Code</div>
            </div>
            <i  className="fa-solid fa-caret-right"></i>
          </div>
        </div>
          <div className="cartpage-pricebox">
            <div className="pricebox-type">
              <div className="pricebox-heading">
              MRP 
              </div>
              <div className="pricebox-heading-2">
                1600 <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
            </div>
            <div style={{color:"rgb(17, 186, 216)"}} className="pricebox-type">
              <div className="pricebox-heading">
              Item Discount
              </div>
              <div className="pricebox-heading-2">
              -300 <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
            </div>
            <div className="pricebox-type">
              <div className="pricebox-heading">
              Net Price
              </div>
              <div className="pricebox-heading-2">
              1300 <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>

              
            </div>

            <div style={{border:'0'}} className="pricebox-type">
              <div className="pricebox-heading">
              You Pay (incl. taxes)
              </div>
              <div className="pricebox-heading-2">
              1300 <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
            </div>
 
        <button className="pricebox-type-btn" onClick={myaccountmodal1} >CheckOut</button>

          </div>
          {/* <div className="cartpage-pricebox"></div>
          <div className="cartpage-pricebox"></div>
          <div className="cartpage-pricebox"></div> */}
      </div>
      <div className="mobile-cart-price-box">
     <p>total amount to be pay 
     </p>
     <p>1600 <i className="fa-solid fa-indian-rupee-sign"></i> </p>
      </div>
      {/* {modal && (
        <Modal handleClick={signin} />
      )} */}
      {alert && (<AlertBox alertname="Remove Item From Cart" close={alertbox}/>)}
      {addcoupon && (<AddCouponModal handleClick={OpenAddCoupon} /> )}
      {/* {openLogin && (<LoginPanel_Lc handleClick={OpenAddCoupon} /> )} */}

    </div>
  );
};

export default CartPage;
