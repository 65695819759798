import React, { useContext, useRef, useState, useEffect } from "react";
import "./PartnerwithUs.scss";
import MyContext from "../../MyContext";
import DynamicStaticPage from "../DynamicPage/DynamicStaticPage";
import logo from "../../Assets/Image_lc/logo.jpg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import User from "../../../API/API_SERVISES/User";
// import Swal from 'sweetalert2'
import StateInfoApi from "../../../API/API_SERVISES/StateInfo";

const PartnerWithUs = () => {
    const { currentUser, companyData, imageBaseUrl } = useContext(MyContext);
    console.log(currentUser);
    console.log(companyData);

  const [activetab, setactivetab] = useState(0);
  const [show, setshow] = useState(false);

    const stateInfo = useRef([]);
    console.log(stateInfo);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const data = await StateInfoApi.GetAllState();
    //             stateInfo.current[0]=data
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    const fetchData = async (Cid) => {
        const filterData = [
            {
                fieldName: "CountryId",
                operatorName: "equal",
                compareValue: Cid,
            },
        ];

        try {
            const state = await StateInfoApi.GetAllStateWithFilter(filterData);
            //   setStateInfo(state);
            stateInfo.current[0] = state;
            console.log(state);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (companyData.countryId) {
            fetchData(companyData.countryId);
        }
    }, [companyData.countryId]);

    const formData = useRef({
        Name: "",
        Email: "",
        PhoneNumber: "",
        City: "",
        optometrist: "Yes",
        PropertyType: "",
    });
    const LandlordformData = useRef({
        FirstName: "",
        LastName: "",
        Email: "",
        MobileNo: "",
        City: "",
        State: "",
        LandlordType: "",
        Frontage: "",
        PropertyAddress: "",
        CarpetAddress: "",
        City2: "",
        Catchment: "",
        SuperArea: "",
    });
    const navigate = useNavigate();
    const data = [
        {
            label: "First Name* :",
            placeholder: "Enter your First Name",
            type: "text",
            name: "FirstName",
        },
        {
            label: "Last Name* :",
            placeholder: "Enter your Last Name",
            type: "text",
            name: "LastName",
        },
        {
            label: "Email Id* :",
            placeholder: "Enter your Email Id",
            type: "text",
            name: "Email",
        },
        {
            label: "Mobile No.* :",
            placeholder: "Enter your Mobile No",
            type: "number",
            name: "MobileNo",
        },
        {
            label: "City * :",
            placeholder: "Enter your City",
            type: "text",
            name: "City",
        },
        {
            label: "State* :",
            placeholder: stateInfo.current[0],
            type: "select",
            name: "State",
        },
        {
            label: "Landlord Type* :",
            placeholder: [
                {
                    name: "Select landlord type",
                },
                {
                    name: "Developer",
                },
                {
                    name: "Consultant",
                },
                {
                    name: "Individual",
                },
            ],
            type: "select",
            name: "LandlordType",
        },
        {
            type: "text",
            label: "Frontage* :",
            placeholder: "Frontage in Sq.ft",
            name: "Frontage",
        },
        {
            label: "Property Address* :",
            type: "textarea",
            placeholder: "Layout Address",
            name: "PropertyAddress",
        },
        {
            label: "Carpet Area*:",
            type: "text",
            placeholder: "Size in Sq.ft",
            name: "CarpetAddress",
        },
        {
            label: "City* :",
            type: "text",
            placeholder: "Location",
            name: "City2",
        },

        {
            label: "Catchment:",
            type: "select",
            placeholder: [
                {
                    name: "Neighborhand Market",
                },
                {
                    name: "High Street",
                },
                {
                    name: "Mall",
                },
            ],
            name: "Catchment",
        },
        {
            label: "Super Area* :",
            placeholder: "In Sq.ft",
            type: "text",
            name: "SuperArea",
        },
    ];

    const handleLanddlordDetails = (e) => {
        debugger;
        const { name, value } = e.target;
        LandlordformData.current[name] = value;
    };

    const inputboxwithlabel = (
        label = "First Name* :",
        name,
        placeholder = "placeholder",
        type = "select",
        option
    ) => {
        console.log(option);

        return (
            <>
                <div className={`landlord-input-box`}>
                    <label>{label}</label>
                    {type == "text" || type == "number" ? (
                        <input
                            type={type}
                            name={name}
                            onChange={handleLanddlordDetails}
                            placeholder={placeholder}
                        />
                    ) : type == "select" && name == "State" ? (
                        <select name={name} onChange={handleLanddlordDetails}>
                            {option[0].map((item, index) => {
                                return (
                                    <option key={index} value={item.stateName}>
                                        {item.stateName}
                                    </option>
                                );
                            })}
                        </select>
                    ) : type == "select" ? (
                        <select name={name} onChange={handleLanddlordDetails}>
                            {option.map((item, index) => {
                                return (
                                    <option key={index} value={item.name}>
                                        {item.name}
                                    </option>
                                );
                            })}
                        </select>
                    ) : null}
                    {type == "textarea" ? (
                        <textarea
                            placeholder={placeholder}
                            name={name}
                            onChange={handleLanddlordDetails}
                        />
                    ) : null}
                </div>
            </>
        );
    };

    const handleInputChange = (e) => {
        debugger;
        const { name, value } = e.target;
        formData.current[name] = value; // Update the value in useRef
    };

    // ------------------------SEND EMAIL-------------------------------

    const refrence = useRef([
        {
            data: () => {
                return (
                    <>
                        {/* --------leftside-------------- */}
                        <div className={`thirdlevel-first-div`}>
                            <DynamicStaticPage pageName="Partner" />
                            {/* <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: Partner }} /> */}
                        </div>
                        {/* --------------rightside--------------- */}
                        <div className={`thirdlevel-second-div`}>
                            {/* -------top--------- */}
                            <p className={`title`}>Ready to start your growth journey?</p>

                            {/* -----------middle------------ */}
                            <form className={`thirdlevel-form`}>
                                <p className={`msg-form`}>Are you an optometrist ?</p>
                                <div className={`both-radio`}>
                                    <div>
                                        <input
                                            name="show"
                                            value={"show"}
                                            onChange={(e) => handleradio(e)}
                                            type="radio"
                                        />
                                        Yes
                                    </div>
                                    <div>
                                        <input
                                            name="show"
                                            value={"hide"}
                                            onChange={(e) => handleradio(e)}
                                            type="radio"
                                        />
                                        No
                                    </div>
                                </div>

                                <div className={`greenbox-all-input`}>
                                    <input
                                        placeholder="Name"
                                        name={"Name"}
                                        onChange={handleInputChange}
                                        type="text"
                                    />
                                    <input
                                        placeholder="Email"
                                        name={"Email"}
                                        onChange={handleInputChange}
                                        type="email"
                                    />
                                    <input
                                        placeholder="PhoneNumber"
                                        name={"PhoneNumber"}
                                        onChange={handleInputChange}
                                        type="number"
                                    />
                                    <input
                                        placeholder="City"
                                        name={"City"}
                                        onChange={handleInputChange}
                                        type="text"
                                    />
                                </div>
                            </form>

                            {/* --------button----------- */}
                            <div className={`submitbtn`}>
                                <button type="button" onClick={(e) => handleSubmit(e)}>
                                    Submit Request
                                </button>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            data: () => {
                return (
                    <>
                        {/* --------leftside-------------- */}
                        <div className={`thirdlevel-first-div`}>
                            <DynamicStaticPage pageName="Partner" />
                        </div>
                        {/* --------------rightside--------------- */}
                        <div className={`thirdlevel-second-div`}>
                            {/* -------top--------- */}
                            <p className={`title`}>Ready to start your growth journey?</p>

                            {/* -----------middle------------ */}
                            <form className={`thirdlevel-form`}>
                                <p className={`msg-form`}>Are you an optometrist ?</p>
                                <div className={`both-radio`}>
                                    <div>
                                        <input
                                            name="show"
                                            value={"show"}
                                            onChange={(e) => handleradio(e)}
                                            type="radio"
                                        />
                                        Yes
                                    </div>
                                    <div>
                                        <input
                                            name="show"
                                            value={"hide"}
                                            onChange={(e) => handleradio(e)}
                                            type="radio"
                                        />
                                        No
                                    </div>
                                </div>

                                <div className={`greenbox-all-input`}>
                                    <input
                                        placeholder="Name"
                                        name={"Name"}
                                        onChange={handleInputChange}
                                        type="text"
                                    />
                                    <input
                                        placeholder="Email"
                                        name={"Email"}
                                        onChange={handleInputChange}
                                        type="email"
                                    />
                                    <input
                                        placeholder="PhoneNumber"
                                        name={"PhoneNumber"}
                                        onChange={handleInputChange}
                                        type="number"
                                    />
                                    <input
                                        placeholder="City"
                                        name={"City"}
                                        onChange={handleInputChange}
                                        type="text"
                                    />
                                </div>
                            </form>
                            {/* -----------bottm---------------- */}
                            <div className={`greenbox-bottom`}>
                                <p>What property type will you open the store at?</p>
                                <div className={`greenbox-button-radio`}>
                                    <div>
                                        <input
                                            onChange={handleInputChange}
                                            name={"PropertyType"}
                                            type="radio"
                                        />
                                        Owned
                                    </div>
                                    <div>
                                        <input
                                            onChange={handleInputChange}
                                            name={"PropertyType"}
                                            type="radio"
                                        />
                                        Rented/Leased
                                    </div>
                                </div>
                            </div>

                            {/* --------button----------- */}
                            <div className={`submitbtn`}>
                                <button type="button" onClick={(e) => handleSubmit(e)}>
                                    Submit Request
                                </button>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            data: () => {
                return (
                    <>
                        <div className={`landlord-main`}>
                            {/* -----top----- */}
                            <div className={`landlord-main-top`}>
                                <DynamicStaticPage pageName="Landlord" />
                            </div>
                            {/* -------bottom-------- */}
                            <div className={`landlord-main-bottom`}>
                                {/* ------left-------- */}
                                <h2 className="label-landlord">PERSONAL DETAILS</h2>
                                <div className={`landlord-main-left`}>
                                    {data.slice(0, 6).map((item) => {
                                      
                                        return inputboxwithlabel(
                                            item.label,
                                            item.name,
                                            item.placeholder,
                                            item.type,
                                            stateInfo.current
                                        );
                                    })}
                                </div>

                                <div className={`div-for-gap`} />
                                {/* ----------right---------- */}
                                <h2 className="label-landlord">LAND DETAILS</h2>
                                <div className={`landlord-main-right`}>
                                    {data.slice(6).map((item) => {
                                        return inputboxwithlabel(
                                            item.label,
                                            item.name,
                                            item.placeholder,
                                            item.type,
                                            item.placeholder
                                        );
                                    })}
                                </div>
                            </div>

                            <div onClick={handleSubmitLandlord} className={`submit-button`}>
                                <button>SUBMIT</button>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ]);

    // ------------handleradiochange--------------------------
    const handleradio = (e) => {
        debugger;
        console.log(e);
        console.log(e.target.value);
        if (!e.target.checked) {
            e.target.checked = false;
        } else {
            if (e.target.value == "show") {
                setactivetab(0);
                formData.current.optometrist = "Yes";
            } else {
                setactivetab(1);
                formData.current.optometrist = "No";
            }
        }
    };

    const handleSubmit = async (e) => {
        debugger;

        const { Name, Email, PhoneNumber, City, optometrist, PropertyType } =
            formData.current;
        try {
            const data = {
                text: `
                    Name: ${Name},
                    Email: ${Email},
                    PhoneNumber: ${PhoneNumber},
                    PropertyType: ${PropertyType},
                    City: ${City},
                    Optometrist: ${optometrist}
                    `,
                subject: `Message from lens2kart PartnerWithUs!`,
                // to: "help.lens2cart@gmail.com"
                to: companyData?.email || "user@example.com",
            };

            await User.SendEmail(data)
                .then((res) => {
                    console.log(res);
                    // Swal.fire({
                    //     title: 'Success',
                    //     text: 'Email sent successfully!',
                    //     icon: 'success',
                    //     confirmButtonText: 'Close'
                    // })
                    alert("Email Sent Successfully");
                })
                .catch((err) => {
                    alert(err.message);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmitLandlord = async () => {
        debugger;
        const {
            CarpetAddress,
            Catchment,
            City,
            City2,
            Email,
            FirstName,
            Frontage,
            LandlordType,
            LastName,
            MobileNo,
            PropertyAddress,
            State,
            SuperArea,
        } = LandlordformData.current;
        try {
            const data = {
                text: `
                    FirstName: ${FirstName},
                    LastName: ${LastName},
                    MobileNo: ${MobileNo},
                    Email: ${Email},
                    City: ${City},
                    LandlordType: ${LandlordType},
                    PropertyAddress: ${PropertyAddress},
                    Carpet Address: ${CarpetAddress},
                    Catchment: ${Catchment},
                    LandCity: ${City2},
                    Frontage: ${Frontage},
                    State: ${State},
                    SuperArea: ${SuperArea}
                    `,
                subject: `Lens2kart Landlord Details!`,
                // to: "help.lens2cart@gmail.com",
                to: companyData?.email || "user@example.com",
            };

            await User.SendEmail(data)
                .then((res) => {
                    console.log(res);
                    // Swal.fire({
                    //     title: 'Success',
                    //     text: 'Email sent successfully!',
                    //     icon: 'success',
                    //     confirmButtonText: 'Close'
                    // })

                    alert("Email Sent Successfully");
                })
                .catch((err) => {
                    alert(err.message);
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className={`partners-main`}>
                {/* -------blackbox------------ */}
                <div className={`partners-main-black`}>
                    <h2>
                        Be cautious while dealing with anyone claiming to offer Lens2cart
                        franchisee and to verify details click here.
                    </h2>
                </div>
                {/* --------------secondlevel----------- */}
                <div className={`partners-main-second`}>
                    {/* -------logo-------- */}
                    <Link to="/" className={`partners-logo-div`}>
                        <img src={`${imageBaseUrl}${companyData?.companyImageId}`} />
                    </Link>

                    {/* ---------button------------- */}
                    <div className={`partners-button`}>
                        <button
                            style={{
                                borderBottom:
                                    activetab == 0 || activetab == 1
                                        ? "1px solid #49aa9a"
                                        : "1px solid transparent",
                            }}
                            onClick={() => setactivetab(0)}
                        >
                            Partner
                        </button>
                        <button
                            style={{
                                borderBottom:
                                    activetab == 2
                                        ? "1px solid #49aa9a"
                                        : "1px solid transparent",
                            }}
                            onClick={() => setactivetab(2)}
                        >
                            Landlord
                        </button>
                    </div>

                    {/* ------------email/number-------------- */}
                    <div className={`partners-numbers`}>
                        {/* ------------email---------------- */}
                        <div>
                            <span>
                                <i class="fa-solid fa-envelope"></i>
                            </span>
                            <p> {companyData.email}</p>
                        </div>
                        {/* ----------------number------------------ */}
                        {/* <div style={{ border: 'none' }}>
                            <span>
                                <i class="fa-solid fa-phone"></i>
                            </span>
                            <p>{companyData.mobileNo}</p>
                        </div> */}
                    </div>
                </div>

                {/* ------------thirdlenvel------------- */}
                <div className={`thirdlevel`}>{refrence.current[activetab].data()}</div>

                {/* --------------------submitbtn----------------- */}
            </div>
        </>
    );
};

export default PartnerWithUs;
