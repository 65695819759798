import React, { useContext } from "react";
import MyContext from "../../MyContext";
import Order from "../../../API/API_SERVISES/Order";
import coupon2 from "../../Assets/Icons/discount.png";
import BillSundry from "../../../API/API_SERVISES/BillSundry";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import LoyaltyMaster from "../../../API/API_SERVISES/LoyaltyMaster";

const CouponCard = (props) => {
  const {
    companyData,
    CouponApplied,
    AppliedCouponData,
    InputValues,
    totalDiscountAmt,
    setCouponApplied,
    GetCouponData,
    setLoading,
    calculateTotalPrice,
    MembershipItems,
    setMembershipItems,
  } = useContext(MyContext);

  const { OpenAddCoupon , alertbox1 } = props;

  

  return (
    <>
      {" "}
      {AppliedCouponData &&
        AppliedCouponData.map((Item, index) => (
          <div
            style={{
              cursor: "pointer",
              width: props.width || "99%",
              padding: "0",
            }}
            className="cartpage-pricebox frutiger-button"
          >
            {/* <div class=""> */}
            <div class="inner">
              <div class="top-white"></div>
              <div className="pricebox-1" style={{ padding: "0" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {/* <img src={coupon2} alt="" /> */}
                  <div className="pricebox-coupon">
                    {" "}
                    {Item?.couponMethod}
                  </div>
                  {Item?.discountType == "1" ? (
                    <div className="coupon-txt-box-1" style={{ width: "100%" }}>
                      <p
                        style={{
                          margin: "0",
                          paddingLeft: "10px",
                          width: "100%",
                        }}
                      >
                        Flat {Item?.discountValue} % off
                      </p>
                      <p
                        style={{
                          margin: "0",
                          paddingLeft: "10px",
                          width: "100%",
                        }}
                      >
                        You are saving {totalDiscountAmt || 0}
                        {companyData.currencySymbol}
                      </p>
                    </div>
                  ) : (
                    <div className="coupon-txt-box-1" style={{ width: "100%" }}>
                      <p
                        style={{
                          margin: "0",
                          paddingLeft: "10px",
                          width: "100%",
                        }}
                      >
                        Flat {Item?.discountValue} off
                      </p>
                      <p
                        style={{
                          margin: "0",
                          paddingLeft: "10px",
                          width: "100%",
                        }}
                      >
                        You are saving {totalDiscountAmt || 0}
                        {companyData.currencySymbol}
                      </p>
                    </div>
                  )}
                </div>
                <i
                  onClick={(e) =>
                    alertbox1(
                      e,
                      Item.id,
                      AppliedCouponData.length,
                      AppliedCouponData,
                      Item
                    )
                  }
                  style={{ zIndex: "11" }}
                  class="fa-regular fa-circle-xmark"
                ></i>
              </div>
            </div>
          </div>
        ))}
      <div
        style={{
          cursor: "pointer",
          width: `${parseInt(props.width, 10) - 5}%` || "90%",
        }}
        onClick={OpenAddCoupon}
        className="cartpage-pricebox"
      >
        <div className="pricebox-1">
          <div style={{ display: "flex" }}>
            <img src={coupon2} alt="" />
            <div className="pricebox-coupon">Add Coupon Code</div>
          </div>
          <i className="fa-solid fa-caret-right"></i>
        </div>
      </div>
    </>
  );
};

export default CouponCard;
