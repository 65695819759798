import React, { useContext, useEffect, useState } from "react";
import OrderApi from "../../../API/API_SERVISES/Order";
import Transaction from "../../../API/API_SERVISES/Transaction";
import MyContext from "../../MyContext";
import Loader1 from "../Loaders/Loader1";

const ModelPrescriptionManually = ({
  onSelect,
  onContinue,
  isSubmitted,
  refId,
  itemData,
  closeModal,
  fetchMyOrderData,
  toggleModalPower,
  setRunCount,
  lensType,
}) => {
  const [showTable, setShowTable] = useState(false);
//   const [lensType, setLenstype] = useState(LensType);

  const { imageBaseUrl } = useContext(MyContext);
  const data = [
    { id: 1, eye: "SPHERICAL", rightEye: "-3.50", leftEye: "-4.00" },
    // { id: 2, eye: 'CYLINDRICAL', rightEye: '-3.50', leftEye: '-4.00' },
  ];
  console.log("itemData", itemData);


//   useEffect(()=>{
//     const lensType = itemData.LensTypeEf.split(",");
//     // console.log("lensType",lensType);
//     setLenstype(lensType)
//   },[itemData])

  const [negativeOptionsSph] = useState([
    // -13.00, -12.75, -12.50, -12.25, -12.00, -11.75, -11.50, -11.25, -11.00,
    // -10.75, -10.50, -10.25, -10.00, -9.75, -9.50, -9.25, -9.00, -8.75, -8.50,
    // -8.25, -8.00, -7.75, -7.50, -7.25, -7.00, -6.75, -6.50, -6.25, -6.00,
    // -5.75, -5.50, -5.25, -5.00, -4.75, -4.50, -4.25, -4.00, -3.75, -3.50,
    // -3.25, -3.00, -2.75, -2.50, -2.25, -2.00, -1.75, -1.50, -1.25, -1.00,
    // -0.75, -0.50, -0.25

    "-0.25",
    "-0.50",
    "-0.75",
    "-1.00",
    "-1.25",
    "-1.50",
    "-1.75",
    "-2.00",
    "-2.25",
    "-2.50",
    "-2.75",
    "-3.00",
    "-3.25",
    "-3.50",
    "-3.75",
    "-4.00",
    "-4.25",
    "-4.50",
    "-4.75",
    "-5.00",
    "-5.25",
    "-5.50",
    "-5.75",
    "-6.00",
    "-6.25",
    "-6.50",
    "-6.75",
    "-7.00",
    "-7.25",
    "-7.50",
    "-7.75",
    "-8.00",
  ]);

  const [positiveOptionsSph] = useState([
    // 0.25, 0.50, 0.75, 1.00, 1.25, 1.50, 1.75, 2.00, 2.25, 2.50, 2.75, 3.00,
    // 3.25, 3.50, 3.75, 4.00, 4.25, 4.50, 4.75, 5.00, 5.25, 5.50, 5.75, 6.00,
    // 6.25, 6.50, 6.75, 7.00, 7.25, 7.50, 7.75, 8.00

    "+0.25",
    "+0.50",
    "+0.75",
    "+1.00",
    "+1.25",
    "+1.50",
    "+1.75",
    "+2.00",
    "+2.25",
    "+2.50",
    "+2.75",
    "+3.00",
    "+3.25",
    "+3.50",
    "+3.75",
    "+4.00",
    "+4.25",
    "+4.50",
    "+4.75",
    "+5.00",
    "+5.25",
    "+5.50",
    "+5.75",
    "+6.00",
  ]);

  const [negativeOptionsCyl] = useState([
    // -6.00, -5.75, -5.50, -5.25, -5.00, -4.75, -4.50, -4.25, -4.00, -3.75,
    // -3.50, -3.25, -3.00, -2.75, -2.50, -2.25, -2.00, -1.75, -1.50, -1.25,
    // -1.00, -0.75, -0.50, -0.25
    "-0.25",
    "-0.50",
    "-0.75",
    "-1.00",
    "-1.25",
    "-1.50",
    "-1.75",
    "-2.00",
    "-2.25",
    "-2.50",
    "-2.75",
    "-3.00",
    "-3.25",
    "-3.50",
    "-3.75",
    "-4.00",
  ]);

  const [positiveOptionsCyl] = useState([
    // 0.25, 0.50, 0.75, 1.00, 1.25, 1.50,
    // 1.75, 2.00, 2.25, 2.50, 2.75, 3.00, 3.25, 3.50, 3.75, 4.00,
    // 4.25, 4.50, 4.75, 5.00, 5.25, 5.50, 5.75, 6.00
    "+0.25",
    "+0.50",
    "+0.75",
    "+1.00",
    "+1.25",
    "+1.50",
    "+1.75",
    "+2.00",
    "+2.25",
    "+2.50",
    "+2.75",
    "+3.00",
    "+3.25",
    "+3.50",
    "+3.75",
    "+4.00",
  ]);

  const handleBackClick = () => {
    setShowTable(false);
  };

  const [lensData, setLensData] = useState({
    indx: 0,
    rItemId_d2: itemData?.itemId || 0,
    rItemName: itemData?.itemName || "",
    rSphDv: "",
    rCylDv: "",
    rAxisDv: "",
    rVnDv: "",
    rSphNv: "",
    rCylNv: "",
    rAxisNv: "",
    rVnNv: "",
    rAdd: "",
    rpB1: "",
    rpB2: "",
    lItemId_d2: itemData?.itemId || 0,
    lItemName: itemData?.itemName || "",
    lSphDv: "",
    lCylDv: "",
    lAxisDv: "",
    lVnDv: "",
    lSphNv: "",
    lCylNv: "",
    lAxisNv: "",
    lVnNv: "",
    lAdd: "",
    lpB1: "",
    lpB2: "",
    printLenseStatus: "",
    printLensEye: "",
    lenseType: itemData?.LensTypeEf || "",
    // itemData?.lensType || ""
    prescribedBy: "",
    partyId: 0,
    partyName: "",
    frameName: "",
    remark: "",
    nextVisit: "",
    nvDate: "",
    lensExpRem: "",
    lExDate: "",
    pdr: "",
    pdl: "",
    pdrl: "",
    deliverDays: "",
    deliverTime: "",
    contact: "",
    transpose: "",
    ar: "",
    im: "",
    linkedItm: itemData.linkedItem || 0,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const userLogin = localStorage.getItem("accountLogin");
    const userArray = userLogin ? JSON.parse(userLogin) : null;
    setLensData((prevValues) => ({
      ...prevValues,
      partyId: userArray.d2Id,
      rItemId_d2: itemData.itemId,
      lItemId_d2: itemData.itemId,
      partyName: userArray.userName,
      rItemName: itemData.itemName,
      lItemName: itemData.itemName,
      linkedItm: itemData.linkedItem,
      lenseType: itemData?.LensTypeEf,
    }));
    console.log(lensData);
    setLoading(false);
  }, []);

  // function generateRanges(start, end, interval) {
  //     const step = parseFloat(interval);
  //     const startT = parseFloat(start);
  //     const endT = parseFloat(end);

  //     const result = [];

  //     for (let value = startT; value <= endT; value += step) {

  //         const roundedValue = Math.round(value * 100) / 100;

  //         result.push(roundedValue);
  //     }
  //     return result;
  // }

  // useEffect(() => {
  //     const fetchData = async () => {
  //         const lens = await OrderApi.getLensMasterSettAndFeatures(5419); //d2ItemId
  //         //*********** Sph ****************** */
  //         const sphSplit = lens?.rE_SPH?.split(",")
  //         const DataSphRight = generateRanges((sphSplit[0]), (sphSplit[1]), lens.sph_Step);
  //         const negativeValues = DataSphRight.filter(num => num < 0).sort((a, b) => a - b);
  //         const positiveValues = DataSphRight.filter(num => num > 0).sort((a, b) => a - b);
  //         setNegativeOptionsRight(negativeValues);
  //         setPositiveOptionsRight(positiveValues);

  //         const sphSplitLeft = lens?.lE_SPH?.split(",")
  //         const DataSphLeft = generateRanges((sphSplitLeft[0]), (sphSplitLeft[1]), lens.sph_Step);
  //         const negativeValuesLeft = DataSphLeft.filter(num => num < 0).sort((a, b) => a - b);
  //         const positiveValuesLeft = DataSphLeft.filter(num => num > 0).sort((a, b) => a - b);
  //         setNegativeOptionsLeft(negativeValuesLeft);
  //         setPositiveOptionsLeft(positiveValuesLeft);
  //     };
  //     fetchData();
  // }, []);

  /*********************************************************** */

  const [selectedValueRight, setSelectedValueRight] = useState("Select");
  const [isOpenRight, setIsOpenRight] = useState(false);

  const toggleDropdownRight = () => {
    setIsOpenRight(!isOpenRight);
    setIsOpenLeftCyl(false);
    setIsOpenRightCyl(false);
    setIsOpenLeft(false);
    setIsOpenRightAxis(false);
    setIsOpenLeftAxis(false);
    setIsOpenRightAdd(false);
    setIsOpenLeftAdd(false);
  };

  // const handleSelectRight = (event, val) => {
  //     const { name, value } = event.target;
  //     console.log(`Changing ${name} to ${value}`);
  //     setSelectedValueRight(val);
  //     if (typeof onSelect === 'function') {
  //         onSelect(val);
  //     }
  //     setIsOpenRight(false);

  //     setLensData((prevValues) => ({
  //         ...prevValues,
  //         [name]: value,
  //     }));
  // };

  /************************************************************* */
  const [selectedValueLeft, setSelectedValueLeft] = useState("Select");
  const [isOpenLeft, setIsOpenLeft] = useState(false);

  const toggleDropdownLeft = () => {
    setIsOpenLeft(!isOpenLeft);
    setIsOpenLeftCyl(false);
    setIsOpenRightCyl(false);
    setIsOpenRight(false);
    setIsOpenRightAxis(false);
    setIsOpenLeftAxis(false);
    setIsOpenRightAdd(false);
    setIsOpenLeftAdd(false);
  };
  // ***************** CYLINDRICAL ******************

  const [isShowCylindrical, setIsShowCylindrical] = useState(false);

  const handleCheckBox = () => {
    setIsShowCylindrical(!isShowCylindrical);
  };

  const [selectedValueRightCyl, setSelectedValueRightCyl] = useState("Select");
  const [isOpenRightCyl, setIsOpenRightCyl] = useState(false);

  const toggleDropdownRightCyl = () => {
    setIsOpenRightCyl(!isOpenRightCyl);
    setIsOpenLeftCyl(false);
    setIsOpenLeft(false);
    setIsOpenRight(false);
    setIsOpenRightAxis(false);
    setIsOpenLeftAxis(false);
    setIsOpenRightAdd(false);
    setIsOpenLeftAdd(false);
  };

  /************************************************************* */
  const [selectedValueLeftCyl, setSelectedValueLeftCyl] = useState("Select");
  const [isOpenLeftCyl, setIsOpenLeftCyl] = useState(false);

  const toggleDropdownLeftCyl = () => {
    setIsOpenLeftCyl(!isOpenLeftCyl);
    setIsOpenRightCyl(false);
    setIsOpenLeft(false);
    setIsOpenRight(false);
    setIsOpenRightAxis(false);
    setIsOpenLeftAxis(false);
    setIsOpenRightAdd(false);
    setIsOpenLeftAdd(false);
  };

  // **************** AXIS ************************
  const [isShowAxis, setIsShowAxis] = useState(false);

  const [selectedValueRightAxis, setSelectedValueRightAxis] =
    useState("Select");
  const [isOpenRightAxis, setIsOpenRightAxis] = useState(false);

  const toggleDropdownRightAxis = () => {
    setIsOpenRightAxis(!isOpenRightAxis);
    setIsOpenRightCyl(false);
    setIsOpenLeftCyl(false);
    setIsOpenLeft(false);
    setIsOpenRight(false);
    setIsOpenLeftAxis(false);
    setIsOpenRightAdd(false);
    setIsOpenLeftAdd(false);
  };

  /************************************************************* */
  const [selectedValueLeftAxis, setSelectedValueLeftAxis] = useState("Select");
  const [isOpenLeftAxis, setIsOpenLeftAxis] = useState(false);

  const toggleDropdownLeftAxis = () => {
    setIsOpenLeftAxis(!isOpenLeftAxis);
    setIsOpenLeftCyl(false);
    setIsOpenRightCyl(false);
    setIsOpenLeft(false);
    setIsOpenRight(false);
    setIsOpenRightAxis(false);
    setIsOpenRightAdd(false);
  };

  const [isNvOrDv, setIsNvOrDv] = useState("DV");

  const handleCheckBoxChange = (val) => {
    setIsNvOrDv(val);
  };

  const [eyePower, setEyePower] = useState({
    sphLeft: "0",
    sphRight: "0",
    cylLeft: "0",
    cylRight: "0",
    axisLeft: "0",
    axisRight: "0",
    addLeft: "0",
    addRight: "0",
  });

  const handleEyePower = (event, val, name) => {
    if (typeof onSelect === "function") {
      onSelect(val);
    }
    setEyePower((prevValues) => ({
      ...prevValues,
      [name]: val,
    }));
    setIsOpenRight(false);
    setIsOpenRightAxis(false);
    setIsOpenLeftAxis(false);
    setIsShowAxis(!isShowAxis);
    setIsOpenRightCyl(false);
    setIsOpenLeftCyl(false);
    setIsOpenLeft(false);
    setIsOpenRightAdd(false);
    setIsOpenLeftAdd(false);
  };

  // const axisValues = ['10', '20', '30', '40', '50'];
  const axisValues = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
    "76",
    "77",
    "78",
    "79",
    "80",
    "81",
    "82",
    "83",
    "84",
    "85",
    "86",
    "87",
    "88",
    "89",
    "90",
    "91",
    "92",
    "93",
    "94",
    "95",
    "96",
    "97",
    "98",
    "99",
    "100",
    "101",
    "102",
    "103",
    "104",
    "105",
    "106",
    "107",
    "108",
    "109",
    "110",
    "111",
    "112",
    "113",
    "114",
    "115",
    "116",
    "117",
    "118",
    "119",
    "120",
    "121",
    "122",
    "123",
    "124",
    "125",
    "126",
    "127",
    "128",
    "129",
    "130",
    "131",
    "132",
    "133",
    "134",
    "135",
    "136",
    "137",
    "138",
    "139",
    "140",
    "141",
    "142",
    "143",
    "144",
    "145",
    "146",
    "147",
    "148",
    "149",
    "150",
    "151",
    "152",
    "153",
    "154",
    "155",
    "156",
    "157",
    "158",
    "159",
    "160",
    "161",
    "162",
    "163",
    "164",
    "165",
    "166",
    "167",
    "168",
    "169",
    "170",
    "171",
    "172",
    "173",
    "174",
    "175",
    "176",
    "177",
    "178",
    "179",
    "180",
  ];

  // const addValues = ['+0.75', '+1.00', '+1.25', '+1.50', '+1.75', '+2.00', '+2.25', '+2.50', '+2.75', '+3.00', '+3.25', '+3.50', '+3.75', '+4.00'];
  const addValues = [
    "+1.00",
    "+1.25",
    "+1.50",
    "+1.75",
    "+2.00",
    "+2.25",
    "+2.50",
    "+2.75",
    "+3.00",
  ];

  const handleSubmitClick = async () => {
    debugger;
    setShowTable(true);
    console.log(eyePower);
    setIsOpenRight(false);
    setIsOpenRightAxis(false);
    setIsOpenLeftAxis(false);
    setIsShowAxis(!isShowAxis);
    setIsOpenRightCyl(false);
    setIsOpenLeftCyl(false);
    setIsOpenLeft(false);
    setIsOpenRightAdd(false);
    setIsOpenLeftAdd(false);

    let updatedData = [];
    const lensType = itemData.LensTypeEf.split(",");
    if (
      lensType.includes("0") ||
      lensType.includes("4")
      //   itemData.lensType == "0" ||
      //   itemData.lensType == "4" ||
      //   itemData.lensType == "NA"
    ) {
      //Single
      updatedData = {
        ...lensData,
        rSphDv: eyePower.sphRight,
        rCylDv: eyePower.cylRight,
        rAxisDv: eyePower.axisRight,
        rVnDv: "",
        rAdd: eyePower.addRight,
        rpB1: "",
        rpB2: "",

        lSphDv: eyePower.sphLeft,
        lCylDv: eyePower.cylLeft,
        lAxisDv: eyePower.axisLeft,
        lVnDv: "",
        lAdd: eyePower.addLeft,
        lpB1: "",
        lpB2: "",
      };
      setLensData((prevValues) => ({
        ...prevValues,
        rSphDv: eyePower.sphRight,
        rCylDv: eyePower.cylRight,
        rAxisDv: eyePower.axisRight,
        rVnDv: "",
        rAdd: eyePower.addRight,
        rpB1: "",
        rpB2: "",

        lSphDv: eyePower.sphLeft,
        lCylDv: eyePower.cylLeft,
        lAxisDv: eyePower.axisLeft,
        lVnDv: "",
        lAdd: eyePower.addLeft,
        lpB1: "",
        lpB2: "",
      }));
    } else if (
      lensType.includes("1") ||
      lensType.includes("3") ||
      lensType.includes("5") ||
      lensType.includes("6")

      //   itemData.lensType == "1" ||
      //   itemData.lensType == "3" ||
      //   itemData.lensType == "5" ||
      //   itemData.lensType == "6"
    ) {
      //Bifocal
      updatedData = {
        ...lensData,
        rSphDv: eyePower.sphRight,
        rCylDv: eyePower.cylRight,
        rAxisDv: eyePower.axisRight,
        rVnDv: "",
        rAdd: eyePower.addRight,
        rpB1: "",
        rpB2: "",

        lSphDv: eyePower.sphLeft,
        lCylDv: eyePower.cylLeft,
        lAxisDv: eyePower.axisLeft,
        lVnDv: "",
        lAdd: eyePower.addLeft,
        lpB1: "",
        lpB2: "",
      };
      setLensData((prevValues) => ({
        ...prevValues,
        rSphNv: eyePower.sphRight,
        rCylNv: eyePower.cylRight,
        rAxisNv: eyePower.axisRight,
        rVnNv: "",
        rAdd: eyePower.addRight,
        rpB1: "",
        rpB2: "",

        lSphNv: eyePower.sphLeft,
        lCylNv: eyePower.cylLeft,
        lAxisNv: eyePower.axisLeft,
        lVnNv: "",
        lAdd: eyePower.addLeft,
        lpB1: "",
        lpB2: "",
      }));
    }
    if (showTable) {
      const transData = await Transaction.GetTransactionById(
        itemData.transId,
        71
      );
      const saveLens = await Transaction.SetTransLensData(
        updatedData,
        transData
      );
      const createTrans = await Transaction.createTransaction(transData);
      if (saveLens) {
        // setIsModalOpenPower(prevState => ({
        //     ...prevState,
        //     [itemData.vouchNo]: !prevState[itemData.vouchNo] // Toggle only the specific modal state
        // }));
        setRunCount(0);
        fetchMyOrderData();
        toggleModalPower(itemData.mastId2, false);
        alert("Updated Successfully");
        closeModal();
      }
    }
  };

  // ***************** ADDITION ******************

  const [selectedValueRightAdd, setSelectedValueRightAdd] = useState("Select");
  const [isOpenRightAdd, setIsOpenRightAdd] = useState(false);

  const toggleDropdownRightAdd = () => {
    setIsOpenRightAdd(!isOpenRightAdd);
    setIsOpenLeftCyl(false);
    setIsOpenLeft(false);
    setIsOpenRight(false);
    setIsOpenRightAxis(false);
    setIsOpenLeftAxis(false);
    setIsOpenRightCyl(false);
    setIsOpenLeftAdd(false);
  };

  /************************************************************* */
  const [selectedValueLeftAdd, setSelectedValueLeftAdd] = useState("Select");
  const [isOpenLeftAdd, setIsOpenLeftAdd] = useState(false);

  const toggleDropdownLeftAdd = () => {
    setIsOpenLeftAdd(!isOpenLeftAdd);
    setIsOpenRightCyl(false);
    setIsOpenLeft(false);
    setIsOpenRight(false);
    setIsOpenRightAxis(false);
    setIsOpenLeftAxis(false);
    setIsOpenRightCyl(false);
    setIsOpenRightAdd(false);
  };

  return (
    <div>
      {/*onClick={handleCloseSelectBox}*/}
      <div className="flex-ord-sold mdl-manual-pre">
        <div className="review-box-image order-image-box">
          <img
            className="favourite-img fav-image-ord "
            src={`${imageBaseUrl}${itemData.imgId}`}
            alt=""
          />
        </div>
        <div className="review-box-detail item-details-width-ord">
          <div className="review-box-detail-line-1">
            <div className="name-top-item">{itemData.itemName}</div>
          </div>
        </div>
      </div>

      <div className="learn-read-prescription">
        {/* {!showTable && ( */}
        <div>
          {showTable ? (
            <>
              <div className="right-checked-circle">
                <i class="fa-regular fa-circle-check"></i>
              </div>
              <div className="review-submit">
                Please review your prescription before submitting
              </div>
            </>
          ) : (
            <div className="power-details-pres">
              <div className="add-power-details">
                <label>Add Power Details</label>
                {/*<div style={{ display: "flex" }}>
                                    <input type="checkBox" checked={isNvOrDv == "DV" ? true : false} onChange={() => handleCheckBoxChange("DV")} />
                                    <label>DV</label>

                                    <input type="checkBox" checked={isNvOrDv == "NV" ? true : false} onChange={() => handleCheckBoxChange("NV")} />
                                    <label>NV</label>
                                </div>*/}
              </div>
              <div
                className="rigt-leran-prescription"
                style={{ height: "25px" }}
              >
                LEARN HOW TO READ PRESCRIPTION
              </div>
            </div>
          )}
          <table
            className="table-head-model-top"
            style={{ width: "100%", borderCollapse: "collapse" }}
          >
            <thead className="header-prescription-eye">
              <tr>
                <th className="header-left-right-eye">EYE</th>
                <th className="header-left-right-eye">RIGHT EYE</th>
                <th className="header-left-right-eye">LEFT EYE</th>
              </tr>
            </thead>
            <tbody>
              {/* ************************** Spherical ************************* */}
              {data.map((row) => (
                <tr key={row.id}>
                  <td className="table-left-right-data">{row.eye}</td>
                  <td className="table-prescription-data">
                    <div className="dd-container-pres">
                      <div className="custom-dropdown-pres cl-dd">
                        <div
                          className={`cl-dd dropdown-container-pres ${
                            isOpenRight ? "show" : ""
                          }`}
                          onClick={showTable ? () => {} : toggleDropdownRight}
                        >
                          <div className="dropdown-display-pres clicked cl-dd">
                            <span className="selected-value-pres">
                              {eyePower.sphRight}
                            </span>
                            <i
                              aria-hidden="true"
                              className={`fa-solid fa-angle-${
                                isOpenRight ? "up" : "down"
                              } cl-dd`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {isOpenRight && (
                      <div
                        className="dropdown-list-pres negative positive both"
                        style={{ zIndex: "1" }}
                      >
                        <div className="cl-power-list">
                          <div className="top-pres-Zero" 
                           onClick={(e) =>
                            handleEyePower(e, 0, "sphRight")
                          }
                        >0</div>
                          <div
                            className="list-container"
                            style={{ display: "flex" }}
                          >
                            <div className="negative-list" style={{ flex: 1 }}>
                              <ul className="list pull-left zero full-width">
                                {negativeOptionsSph.map((option, index) => (
                                  <li
                                    key={index}
                                    onClick={(e) =>
                                      handleEyePower(e, option, "sphRight")
                                    }
                                  >
                                    <div>{option}</div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="positive-list" style={{ flex: 1 }}>
                              <ul className="list pull-left zero full-width">
                                {positiveOptionsSph.map((option, index) => (
                                  <li
                                    key={index}
                                    onClick={(e) =>
                                      handleEyePower(e, option, "sphRight")
                                    }
                                  >
                                    <div>{option}</div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="table-prescription-data">
                    <div className="dd-container-pres">
                      <div className="custom-dropdown-pres cl-dd">
                        <div
                          className={`cl-dd dropdown-container-pres ${
                            isOpenLeft ? "show" : ""
                          }`}
                          onClick={showTable ? () => {} : toggleDropdownLeft}
                        >
                          <div className="dropdown-display-pres clicked cl-dd">
                            <span className="selected-value-pres">
                              {eyePower.sphLeft}
                            </span>
                            <i
                              aria-hidden="true"
                              className={`fa-solid fa-angle-${
                                isOpenLeft ? "up" : "down"
                              } cl-dd`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {isOpenLeft && (
                      <div
                        className="dropdown-list-pres negative positive both"
                        style={{ zIndex: "1" }}
                      >
                        <div className="cl-power-list">
                          <div className="top-pres-Zero" 
                          onClick={(e) =>
                            handleEyePower(e, 0, "sphLeft")
                          }
                          >0</div>
                          <div
                            className="list-container"
                            style={{ display: "flex" }}
                          >
                            <div className="negative-list" style={{ flex: 1 }}>
                              <ul className="list pull-left zero full-width">
                                {negativeOptionsSph.map((option, index) => (
                                  <li
                                    key={index}
                                    onClick={(e) =>
                                      handleEyePower(e, option, "sphLeft")
                                    }
                                  >
                                    <div>{option}</div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="positive-list" style={{ flex: 1 }}>
                              <ul className="list pull-left zero full-width">
                                {positiveOptionsSph.map((option, index) => (
                                  <li
                                    key={index}
                                    onClick={(e) =>
                                      handleEyePower(e, option, "sphLeft")
                                    }
                                  >
                                    <div>{option}</div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
              {/* ************************ Cylindrical ********************** */}
              {isShowCylindrical && (
                <>
                  <tr>
                    <td className="table-left-right-data">CYLINDRICAL</td>
                    <td className="table-prescription-data">
                      <div className="dd-container-pres">
                        <div className="custom-dropdown-pres cl-dd">
                          <div
                            className={`cl-dd dropdown-container-pres ${
                              isOpenRightCyl ? "show" : ""
                            }`}
                            onClick={
                              showTable ? () => {} : toggleDropdownRightCyl
                            }
                          >
                            <div className="dropdown-display-pres clicked cl-dd">
                              <span className="selected-value-pres">
                                {eyePower.cylRight}
                              </span>
                              <i
                                aria-hidden="true"
                                className={`fa-solid fa-angle-${
                                  isOpenRightCyl ? "up" : "down"
                                } cl-dd`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {isOpenRightCyl && (
                        <div
                          className="dropdown-list-pres negative positive both"
                          style={{ zIndex: "1" }}
                        >
                          <div className="cl-power-list">
                            <div className="top-pres-Zero"
                            onClick={(e) =>
                              handleEyePower(e, 0, "cylRight")
                            }
                            >0</div>
                            <div
                              className="list-container"
                              style={{ display: "flex" }}
                            >
                              <div
                                className="negative-list"
                                style={{ flex: 1 }}
                              >
                                <ul className="list pull-left zero full-width">
                                  {negativeOptionsCyl.map((option, index) => (
                                    <li
                                      key={index}
                                      onClick={(e) =>
                                        handleEyePower(e, option, "cylRight")
                                      }
                                    >
                                      <div>{option}</div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div
                                className="positive-list"
                                style={{ flex: 1 }}
                              >
                                <ul className="list pull-left zero full-width">
                                  {positiveOptionsCyl.map((option, index) => (
                                    <li
                                      key={index}
                                      onClick={(e) =>
                                        handleEyePower(e, option, "cylRight")
                                      }
                                    >
                                      <div>{option}</div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                    <td className="table-prescription-data">
                      <div className="dd-container-pres">
                        <div className="custom-dropdown-pres cl-dd">
                          <div
                            className={`cl-dd dropdown-container-pres ${
                              isOpenLeftCyl ? "show" : ""
                            }`}
                            onClick={
                              showTable ? () => {} : toggleDropdownLeftCyl
                            }
                          >
                            <div className="dropdown-display-pres clicked cl-dd">
                              <span className="selected-value-pres">
                                {eyePower.cylLeft}
                              </span>
                              <i
                                aria-hidden="true"
                                className={`fa-solid fa-angle-${
                                  isOpenLeftCyl ? "up" : "down"
                                } cl-dd`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {isOpenLeftCyl && (
                        <div
                          className="dropdown-list-pres negative positive both"
                          style={{ zIndex: "1" }}
                        >
                          <div className="cl-power-list">
                            <div className="top-pres-Zero" 
                            onClick={(e) =>
                              handleEyePower(e, 0, "cylLeft")
                            }
                            >0</div>
                            <div
                              className="list-container"
                              style={{ display: "flex" }}
                            >
                              <div
                                className="negative-list"
                                style={{ flex: 1 }}
                              >
                                <ul className="list pull-left zero full-width">
                                  {negativeOptionsCyl.map((option, index) => (
                                    <li
                                      key={index}
                                      onClick={(e) =>
                                        handleEyePower(e, option, "cylLeft")
                                      }
                                    >
                                      <div>{option}</div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div
                                className="positive-list"
                                style={{ flex: 1 }}
                              >
                                <ul className="list pull-left zero full-width">
                                  {positiveOptionsCyl.map((option, index) => (
                                    <li
                                      key={index}
                                      onClick={(e) =>
                                        handleEyePower(e, option, "cylLeft")
                                      }
                                    >
                                      <div>{option}</div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  {/* *********************** Axis ********************** */}
                  <tr>
                    <td className="table-left-right-data">AXIS</td>
                    <td className="table-prescription-data">
                      <div className="dd-container-pres">
                        <div className="custom-dropdown-pres cl-dd">
                          <div
                            className={`cl-dd dropdown-container-pres ${
                              isOpenRightAxis ? "show" : ""
                            }`}
                            onClick={
                              showTable ? () => {} : toggleDropdownRightAxis
                            }
                          >
                            <div className="dropdown-display-pres clicked cl-dd">
                              <span className="selected-value-pres">
                                {eyePower.axisRight}
                              </span>
                              <i
                                aria-hidden="true"
                                className={`fa-solid fa-angle-${
                                  isOpenRightAxis ? "up" : "down"
                                } cl-dd`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {isOpenRightAxis && (
                        <div
                          className="dropdown-list-pres negative positive both"
                          style={{ zIndex: "1" }}
                        >
                          <div className="cl-power-list">
                            {/* <div className="top-pres-Zero"
                            onClick={(e) =>
                              handleEyePower(e, 0, "axisRight")
                            }
                            >0</div> */}
                            <div
                              className="list-container"
                              style={{ display: "flex" }}
                            >
                              <div
                                className="negative-list"
                                style={{ flex: 1 }}
                              >
                                <ul className="list pull-left zero full-width">
                                  {axisValues.map((value) => (
                                    <li
                                      key={value}
                                      onClick={(e) =>
                                        handleEyePower(e, value, "axisRight")
                                      }
                                    >
                                      {value}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                    <td className="table-prescription-data">
                      <div className="dd-container-pres">
                        <div className="custom-dropdown-pres cl-dd">
                          <div
                            className={`cl-dd dropdown-container-pres ${
                              isOpenLeftAxis ? "show" : ""
                            }`}
                            onClick={
                              showTable ? () => {} : toggleDropdownLeftAxis
                            }
                          >
                            <div className="dropdown-display-pres clicked cl-dd">
                              <span className="selected-value-pres">
                                {eyePower.axisLeft}
                              </span>
                              <i
                                aria-hidden="true"
                                className={`fa-solid fa-angle-${
                                  isOpenLeftAxis ? "up" : "down"
                                } cl-dd`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {isOpenLeftAxis && (
                        <div
                          className="dropdown-list-pres negative positive both"
                          style={{ zIndex: "1" }}
                        >
                          <div className="cl-power-list">
                            {/* <div className="top-pres-Zero"
                            onClick={(e) =>
                              handleEyePower(e, 0, "axisLeft")
                            }
                            >0</div> */}
                            <div
                              className="list-container"
                              style={{ display: "flex" }}
                            >
                              <div
                                className="negative-list"
                                style={{ flex: 1 }}
                              >
                                <ul className="list pull-left zero full-width">
                                  {axisValues.map((value) => (
                                    <li
                                      key={value}
                                      onClick={(e) =>
                                        handleEyePower(e, value, "axisLeft")
                                      }
                                    >
                                      {value}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                </>
              )}
              {((lensType && lensType.includes("1")) ||
                lensType.includes("3") ||
                lensType.includes("5") ||
                lensType.includes("6")) && (
                <tr>
                  <td className="table-left-right-data">
                    ADD (Near Vision Power)
                  </td>
                  <td className="table-prescription-data">
                    <div className="dd-container-pres">
                      <div className="custom-dropdown-pres cl-dd">
                        <div
                          className={`cl-dd dropdown-container-pres ${
                            isOpenRightAdd ? "show" : ""
                          }`}
                          onClick={
                            showTable ? () => {} : toggleDropdownRightAdd
                          }
                        >
                          <div className="dropdown-display-pres clicked cl-dd">
                            <span className="selected-value-pres">
                              {eyePower.addRight}
                            </span>
                            <i
                              aria-hidden="true"
                              className={`fa-solid fa-angle-${
                                isOpenRightAdd ? "up" : "down"
                              } cl-dd`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {isOpenRightAdd && (
                      <div
                        className="dropdown-list-pres negative positive both"
                        style={{ zIndex: "1" }}
                      >
                        <div className="cl-power-list">
                          {/* <div className="top-pres-Zero">0</div> */}
                          <div
                            className="list-container"
                            style={{ display: "flex" }}
                          >
                            <div className="negative-list" style={{ flex: 1 }}>
                              <ul className="list pull-left zero full-width">
                                {addValues.map((value) => (
                                  <li
                                    key={value}
                                    onClick={(e) =>
                                      handleEyePower(e, value, "addRight")
                                    }
                                  >
                                    {value}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="table-prescription-data">
                    <div className="dd-container-pres">
                      <div className="custom-dropdown-pres cl-dd">
                        <div
                          className={`cl-dd dropdown-container-pres ${
                            isOpenLeftAdd ? "show" : ""
                          }`}
                          onClick={showTable ? () => {} : toggleDropdownLeftAdd}
                        >
                          <div className="dropdown-display-pres clicked cl-dd">
                            <span className="selected-value-pres">
                              {eyePower.addLeft}
                            </span>
                            <i
                              aria-hidden="true"
                              className={`fa-solid fa-angle-${
                                isOpenLeftAdd ? "up" : "down"
                              } cl-dd`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {isOpenLeftAdd && (
                      <div
                        className="dropdown-list-pres negative positive both"
                        style={{ zIndex: "1" }}
                      >
                        <div className="cl-power-list">
                          {/* <div className="top-pres-Zero">0</div> */}
                          <div
                            className="list-container"
                            style={{ display: "flex" }}
                          >
                            <div className="negative-list" style={{ flex: 1 }}>
                              <ul className="list pull-left zero full-width">
                                {addValues.map((value) => (
                                  <li
                                    key={value}
                                    onClick={(e) =>
                                      handleEyePower(e, value, "addLeft")
                                    }
                                  >
                                    {value}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="flex-cyl-power">
            {!showTable ? (
              <>
                <div>
                  <input
                    className="cyl-checkbox"
                    type="checkbox"
                    onChange={handleCheckBox}
                  />
                </div>
                <div className="cyl-power-pres">
                  I Have Cylindrical (CYL) Power
                </div>
              </>
            ) : (
              <div className="back-color-mdl" onClick={handleBackClick}>
                BACK
              </div>
            )}
          </div>
          <div className="submit-eye-power">
            <button
              className="btn-pay-now submit-btn-eye"
              onClick={handleSubmitClick}
            >
              SUBMIT EYE-POWER
            </button>
          </div>
        </div>
        {/* )} */}

        {/* {showTable && (
                    <div>
                        <div className="right-checked-circle"><i class="fa-regular fa-circle-check"></i></div>
                        <div className="review-submit">Please review your prescription before submitting</div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead className="header-prescription-eye">
                                <tr>
                                    <th className="header-left-right-eye">EYE</th>
                                    <th className="header-left-right-eye">RIGHT EYE</th>
                                    <th className="header-left-right-eye">LEFT EYE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(row => (
                                    <tr key={row.id}>
                                        <td className="table-left-right-data">{row.eye}</td>
                                        <td className="table-prescription-data">{row.rightEye}</td>
                                        <td className="table-prescription-data">{row.leftEye}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="continue-btn">
                            <button className="btn-pay-now continue-btn-select" onClick={onContinue}>CONTINUE</button>
                        </div>
                        <div className="back-color-mdl" onClick={handleBackClick}>
                            BACK
                        </div>
                    </div>
                )} */}
      </div>
      {loading && <Loader1 />}
    </div>
  );
};

export default ModelPrescriptionManually;
