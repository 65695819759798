import React, { useState, useEffect, useRef } from "react";

const ForgotPasswordPanel = (props) => {
  const {
    handleInputChangeSignIn,
    inputValuesForSignIn,
    VerifyOTP,
    inputValuesForOTP,
    secondsLeft,
    handleInputChangeOTP,
    isDisabled,
    handleClick,
    visibleResendOTP,
    SendOtpForForgotPassword,
    inputRefs,
  } = props;
  console.log(isDisabled);

  const { close } = props;

  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !e.target.value) {
      inputRefs[index - 1].current.focus();
    } else if (e.key === "Backspace" && index === 0 && !e.target.value) {
      e.preventDefault();
    }
  };

  return (
    <div className="form-container forgot-height">
      <i
        style={{ cursor: "pointer" }}
        onClick={close}
        className="fa-solid fa-xmark modal-cross"
      ></i>
      <div className="SignIn-Padding-Lc">
        <p style={{ marginBottom: "20px", border: "none" }} className="title">
          Forgot Password
        </p>
        <div className="form-modal">
          <div className="flex-forgot-otp">
            <input
              type="email"
              className="input-modal SendOTP-width"
              placeholder="Email/Mobile No"
              name="aEmail"
              onChange={handleInputChangeSignIn}
              value={inputValuesForSignIn.aEmail}
              disabled={isDisabled}
            />
            <button
              class="SendOTPButton"
              style={{
                cursor: "pointer",
                backgroundColor: isDisabled ? "#a5a5a5" : "#a78bfa",
              }}
              disabled={isDisabled}
              onClick={SendOtpForForgotPassword}
            >
              Send OTP
            </button>
          </div>
          <span class="mainHeading-OTP">Enter OTP</span>
          <div class="inputContainer">
            <input
              ref={inputRefs[0]}
              required="required"
              maxlength="1"
              type="text"
              name="otp"
              class="otp-input"
              id="otp-input1"
              onChange={(e) => handleInputChangeOTP(0, e.target.value)}
              onKeyDown={(e) => handleKeyDown(0, e)}
            />
            <input
              ref={inputRefs[1]}
              required="required"
              maxlength="1"
              type="text"
              name="otp"
              class="otp-input"
              id="otp-input2"
              onChange={(e) => handleInputChangeOTP(1, e.target.value)}
              onKeyDown={(e) => handleKeyDown(1, e)}
            />
            <input
              ref={inputRefs[2]}
              required="required"
              maxlength="1"
              type="text"
              name="otp"
              class="otp-input"
              id="otp-input3"
              onChange={(e) => handleInputChangeOTP(2, e.target.value)}
              onKeyDown={(e) => handleKeyDown(2, e)}
            />
            <input
              ref={inputRefs[3]}
              required="required"
              maxlength="1"
              type="text"
              name="otp"
              class="otp-input"
              id="otp-input4"
              onChange={(e) => handleInputChangeOTP(3, e.target.value)}
              onKeyDown={(e) => handleKeyDown(3, e)}
            />
          </div>
          {/* <input type="number" className="input-modal" placeholder="OTP" name='otp' onChange={handleInputChangeOTP} value={inputValuesForOTP.otp} /> */}

          {/* {showResendButton && (
                    <p className="resendNote">
                        Didn't receive the OTP? <button className="resendBtn" onClick={resendOTP}>Resend OTP</button>
                    </p>
                )}
                {!showResendButton && <div className="resendNote">Try again in {counter} seconds.</div>} */}

          {visibleResendOTP && (
            <p
              onClick={handleClick}
              style={{
                color: isDisabled ? "#947bdd" : "rgba(156, 163, 175, 1)",
              }}
              className="resendNote"
              disabled={isDisabled}
            >
              {isDisabled ? (
                `Resend OTP after ${secondsLeft} sec`
              ) : (
                <span>
                  Haven't received the OTP ?
                  <button className="resendBtn">Resend OTP</button>
                </span>
              )}
            </p>
          )}

          <div
            style={{ textDecoration: "none", textAlign: "center" }}
            className="form-btn"
            onClick={VerifyOTP}
          >
            Verify OTP
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPasswordPanel;
