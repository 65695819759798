import React, { useContext, useEffect, useState } from "react";
import Prescription from "../../Assets/Image_lc/prescription.png";
import call from "../../Assets/Image_lc/call back.jpg";
import ModelPrescriptionManually from "./ModelPrescriptionManually";
import ModelSavedPrescription from "./ModelSavedPrescription";
import ModelUploadPrescription from "./ModelUploadPrescription";
import ModelRequestACallBack from "./ModelRequestACallBack";
import Transaction from "../../../API/API_SERVISES/Transaction";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import MyContext from "../../MyContext";
import transitions from "@material-ui/core/styles/transitions";
import enterManually from "../../Assets/Images/enterManually.png";
import uploadPrescription from "../../Assets/Images/uploadPrescription.png";
import PayStack from "../../../API/API_SERVISES/PayStack";
import Loader1 from "../Loaders/Loader1";
import ReportDesignForA4 from "../../Component/ReportDesignForA4";

const OrderBox_Lc = (props) => {
  const [isClicked, setIsClicked] = useState({});
  const [isModalOpenPower, setIsModalOpenPower] = useState({});
  const [showBillPrint, setShowBillPrint] = useState(false);
  const [downloadBill, setDownloadBill] = useState(false);
  const [transId, setTransId] = useState();
  const [myOrderdata, setMyOrderData] = useState();
  const [isModalOpenManually, setIsModalOpenManually] = useState(false);
  const [lensData, setLensData] = useState();
  const [viewBill, setViewBill] = useState(false);

  const handleModalViewBill = (id) => {
    setTransId(id);
    setViewBill(!viewBill);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const closeViewBillModal = () => {
    setViewBill(false);
  };

  const handleViewBillModalClick = (e) => {
    // Prevent the event from propagating to parent elements
    e.stopPropagation();
  };
  const hideViewBillModal3 = () => {
    setViewBill(false);
  };

  const closeBillPrint = () => {
    setShowBillPrint(false);
  };

  const [isRedirect, setIsRedirect] = useState(true);

  useEffect(() => {
    debugger
    const power = () => {
      if (props.isAddPower && myOrderdata?.length > 0) {
        var orderData = myOrderdata.filter((x) => x.VouchNo == props.TransId);
        setTransId(props.TransId);
        console.log("orderData", orderData);
        var FrameItem = orderData.find((x) => x.MasterDescriptn != "");
        var LensItem = orderData.find((x) => x.LensTypeEf != "");
        if (FrameItem && LensItem) {
          toggleModalManually(
            FrameItem?.RefId,
            LensItem?.MastId2,
            FrameItem?.MasterName,
            FrameItem?.IImgId,
            LensItem?.LensType,
            LensItem?.LensTypeEf,
            FrameItem?.VouchNo,
            FrameItem?.TransId,
            FrameItem?.LinkedItm,
            FrameItem?.MastId2,
            isRedirect
          );
        }
      }
    };
    power();
  }, [myOrderdata, props.TransId]);

  const [transactionDetail, setTransactionDetail] = useState();

  const { imageBaseUrl, companyData, loading, setLoading, currentUser } =
    useContext(MyContext);

  const toggleClass = (vouchNo) => {
    setIsClicked((prevState) => ({
      ...prevState,
      [vouchNo]: !prevState[vouchNo], // Toggle only the specific modal state
    }));
    // setIsClicked(!isClicked);
  };
  const thicknessData = ["LENSES CORRIDOR", "LENSES THICKNESS"];

  const tableData = [
    {
      left: {
        sph: -1.5,
        cyl: -0.75,
        axis: 90,
        add: 1.0,
        pupil: 2.5,
      },
      right: {
        sph: -1.25,
        cyl: -0.5,
        axis: 85,
        add: 1.0,
        pupil: 2.5,
      },
    },
    // Additional rows here...
  ];

  const [isSubmitted, setIsSubmitted] = useState(false);

  // Function to be passed down to child to update the state
  const handleSubmitEyePower = () => {
    setIsSubmitted(true); // When the user clicks the submit button
  };
  /****************************************************** */

  const [refId, setRefId] = useState();
  const [itemData, setItemData] = useState({
    itemId: 0,
    itemName: "",
    imgId: 0,
    lensType: "",
    LensTypeEf: "",
    vouchNo: "",
    transId: 0,
    refId: "",
    linkedItem: "",
    mastId2: 0,
  });

  const toggleModalManually = (
    refId1,
    itemId,
    itemName,
    imgId,
    lensType,
    LensTypeEf,
    vouchNo,
    transId1,
    linkedItm,
    mastId2,
    isRedirect = false
  ) => {
    setRefId(refId1);
    setItemData((prevValues) => ({
      ...prevValues,
      itemId: itemId,
      itemName: itemName,
      imgId: imgId,
      lensType: lensType,
      LensTypeEf: LensTypeEf,
      vouchNo: vouchNo,
      transId: transId1,
      refId: refId1,
      linkedItem: linkedItm,
      mastId2: mastId2,
    }));
    if (transId != vouchNo || isRedirect) {
      setIsModalOpenManually(true);
    }
  };

  const closeModalManually = () => {
    setIsModalOpenManually(false);
    setIsRedirect(false);
  };

  /************************************************ */
  const fetchLensData = async () => {
    setLoading(true);
    try {
      const loginDataString = localStorage.getItem("accountLogin");
      if (loginDataString) {
        const loginData = JSON.parse(loginDataString);
        const data = await Transaction.getSaleLensDataByTransId(
          0,
          loginData.d2Id
        );
        const filterData = data.filter((item) => !item.imgId); // Simplified the condition
        setSaveLensData(filterData);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const [isModalOpenSaved, setIsModalOpenSaved] = useState(false);
  const [savedLensData, setSaveLensData] = useState();

  const toggleModalSaved = async (
    refId,
    itemId,
    itemName,
    imgId,
    lensType,
    LensTypeEf,
    vouchNo,
    transId,
    linkedItm,
    mastId2
  ) => {
    setRefId(refId);
    setItemData((prevValues) => ({
      ...prevValues,
      itemId: itemId,
      itemName: itemName,
      imgId: imgId,
      lensType: lensType,
      LensTypeEf: LensTypeEf,
      vouchNo: vouchNo,
      transId: transId,
      refId: refId,
      linkedItem: linkedItm,
      mastId2: mastId2,
    }));
    await fetchLensData();
    setIsModalOpenSaved(!isModalOpenSaved);
  };

  const closeModalSaved = () => {
    setIsModalOpenSaved(false);
  };

  /************************************************ */

  const [isModalOpenUpload, setIsModalOpenUpload] = useState(false);

  const toggleModalUpload = (
    refId,
    itemId,
    itemName,
    imgId,
    lensType,
    LensTypeEf,
    vouchNo,
    transId,
    linkedItm,
    mastId2
  ) => {
    setLoading(true);
    setRefId(refId);
    setItemData((prevValues) => ({
      ...prevValues,
      itemId: itemId,
      itemName: itemName,
      imgId: imgId,
      lensType: lensType,
      LensTypeEf: LensTypeEf,
      vouchNo: vouchNo,
      transId: transId,
      refId: refId,
      linkedItem: linkedItm,
      mastId2: mastId2,
    }));
    setIsModalOpenUpload(!isModalOpenUpload);
    setLoading(false);
  };

  const closeModalUpload = async () => {
    setIsModalOpenUpload(false);
  };

  /****************************************************** */

  const [isModalOpenCallBack, setIsModalOpenCallBack] = useState(false);

  const toggleModalCallBack = (
    refId,
    itemId,
    itemName,
    imgId,
    lensType,
    LensTypeEf,
    vouchNo,
    transId,
    linkedItm,
    mastId2
  ) => {
    setLoading(true);
    setRefId(refId);
    setItemData((prevValues) => ({
      ...prevValues,
      itemId: itemId,
      itemName: itemName,
      imgId: imgId,
      lensType: lensType,
      vouchNo: vouchNo,
      transId: transId,
      refId: refId,
      linkedItem: linkedItm,
      mastId2: mastId2,
    }));
    setIsModalOpenCallBack(!isModalOpenCallBack);
    setLoading(false);
  };

  const closeModalCallBack = () => {
    setIsModalOpenCallBack(false);
  };

  /*************************************************** */
  const toggleModalPower = (vouchNo, value) => {
    if (value == false) {
      setIsModalOpenPower({});
    } else {
      setIsModalOpenPower((prevState) => ({
        ...prevState,
        [vouchNo]: !prevState[vouchNo], // Toggle only the specific modal state
      }));
    }
    // setIsModalOpenPower(prevState => !prevState);
  };



  useEffect(() => {
    console.log("myOrderdata", myOrderdata);
  }, [myOrderdata]);
  const fetchMyOrderData = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const userLogin = localStorage.getItem("accountLogin");
        const userArray = userLogin ? JSON.parse(userLogin) : null;
        if (userArray) {
          const data = await Transaction.getAccountOrder(userArray.d2Id);
          console.log(data);
          if (data && data.length > 0) {
            debugger;
            const filteredData = data.filter((item) => item.RecoType == 4);
            setMyOrderData(filteredData);

            console.log("recoType4", filteredData);

            const refFilter =
              filteredData &&
              filteredData.filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) => t.TransId === item.TransId
                    //&& t.RefId === item.RefId
                  )
              );

            console.log("refFilter", refFilter);
            let lensDataa = [];
            for (const data of filteredData) {
              try {
                // const tansLensData = await Transaction.GetTransLensData(data.RefId);
                const tansLensData = await Transaction.getSaleLensDataByTransId(
                  data.TransId,
                  0
                );
                lensDataa.push({
                  refId: data.RefId,
                  mastId2: data.MastId2,
                  vouchNo: data.VouchNo,
                  transaction: tansLensData,
                  // transaction: tansLensData.result
                });
              } catch (error) {
                console.error(
                  `Failed to load data for RefId: ${data.RefId}`,
                  error
                );
                setLoading(false);
              }
            }
            console.log(lensDataa);
            setLensData(lensDataa);
            setLoading(false);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }, 1000);
  };
  const [runCount, setRunCount] = useState(0);

  useEffect(() => {
    //if (runCount < 3) {
    fetchMyOrderData();
    //setRunCount(runCount + 1);
    //}
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Get the day, month, year, hours, minutes, and AM/PM
    const day = String(date.getDate()).padStart(2, "0"); // Pad single digit day with leading zero
    const month = date.toLocaleString("default", { month: "short" }); // Get abbreviated month name (e.g., "Jan")
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Pad single digit minutes with leading zero
    let ampm = "AM";

    if (hours >= 12) {
      ampm = "PM";
      if (hours > 12) {
        hours -= 12; // Convert 24-hour to 12-hour format
      }
    } else if (hours === 0) {
      hours = 12; // Handle midnight
    }

    // Format as dd Jan yyyy hh:mm AM/PM
    // return `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
    return `${day} ${month} ${year}`;
  }

  const insertPayment = async (totalAmt) => {
    var dataRes = {
      status: true,
      message: "Authorization URL created",
      data: {
        authorization_url: "https://checkout.paystack.com/3ni8kdavz62431k",
        access_code: "3ni8kdavz62431k",
        reference: "re4lyvq3s3",
      },
    };

    var data = {
      email: "customer@email.com",
      amount: totalAmt, //"20000",
      currency: "KES",
      callback_url: "https://lens2cart.com/PaymentResponse",
    };
    var response = await PayStack.CreatePayment(data);
    if (response.status === 200) {
      const url = response.data.data.authorization_url;
      debugger;
      localStorage.setItem("PayStackPayment", response.data.data.reference);
      window.location.href = url;
    }
  };

  const lensNames = ["", "LENS", "LENS CORRIDOR", "LENS THICKNESS"];

  // Handle View Bill click

  // const handleViewBill = async (id) => {
  //   setLoading(true);
  //   setTransId(id);
  //   setShowBillPrint(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);
  // };

  const handleDownloadBill = (id) => {
    setLoading(true);
    setTransId(id);
    setDownloadBill(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  useEffect(() => {
    if (viewBill) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => (document.body.style.overflow = "auto");
  }, [viewBill]);

  return (
    <div className="myOrderBoxScroll">
      {myOrderdata &&
        myOrderdata
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.RefId === item.RefId)
          )
          // .slice().reverse()
          .map((data, index) => {
            return (
              <div
                className={`custom-div my-order-table order-mybox-height`}
                id={data.RefId}
                key={data.RefId}
              >
                <div className="order-box-top-top">
                  <div className="order-box-top">
                    <div className="ord-detail-admin">
                      <div className="flex-ord-date-time">
                        <div className="flex-ord-no">
                          <p className="ordertxt-1-order">Order No </p>
                          <p className="ordertxt-1-ordno">:&nbsp;</p>
                          <p className="ordertxt-1-ordno">{data.VouchNo}</p>
                        </div>
                        <div className="flex-ord-no">
                          <p className="ordertxt-1-order">Order Date </p>
                          <p className="ordertxt-1-ordno">:&nbsp;</p>
                          <p className="ordertxt-1-ordno">
                            {formatDate(data.VouchDate)}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="ord-detail-admin">
                      <div className="flex-ord-date-time">
                        <div className="flex-ord-no">
                          <p className="ordertxt-1-order">Total Price </p>
                          <p className="ordertxt-1-ordno">:&nbsp;</p>
                          <p className="ordertxt-1-ordno">
                            {companyData.currencySymbol}{" "}
                            {Number(data.BillAmt).toFixed(2)}
                          </p>
                        </div>
                        <div className="flex-ord-no">
                          <p className="ordertxt-1-order">Total Discount </p>
                          <p className="ordertxt-1-ordno">:&nbsp;</p>
                          <p className="ordertxt-1-ordno">
                            {companyData.currencySymbol}{" "}
                            {Number(data.BillDiscount).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {data.PaymentStatus == "False" && (
                    <div className="order-box-top pay-ord-border">
                      <div className="ord-detail-admin">
                        <div className="">
                          <div className="Complete-payment">
                            Complete Your Payment
                          </div>
                          <div className="processed-pymt">
                            Order will be processed after payment
                          </div>
                        </div>
                      </div>
                      <div className="order-detail-box-list-pay">
                        <button
                          className="btn-pay-now"
                          onClick={() => insertPayment(data.BillAmt)}
                        >
                          PAY NOW
                        </button>
                      </div>
                    </div>
                  )}
                  <div>
                    <div
                      style={{
                        borderRadius: "15px",
                        boxShadow: "none",
                        width: "100%",
                        padding: "0",
                        marginBottom: "0",
                      }}
                      className=""
                    >
                      {/* <label className="title"></label> */}
                      {data.PaymentStatus == "False" && (
                        <div className="flex-stop-pay">
                          <div className="elipse-stop-triang">
                            <i class="fa-solid fa-triangle-exclamation triangle-stop"></i>
                          </div>
                          <div className="power-submission-pay">
                            <div className="sub-payment">
                              Payment & Power Submission Pending
                            </div>
                            <div className="processed-pymt">
                              Complete your payment & submit power to process
                              order
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className="favourite-box-container"
                        style={{ height: "auto", paddingBottom: "10px" }}
                      >
                        <div className="fav-box-width">
                          {myOrderdata &&
                            Object.values(
                              myOrderdata
                                .sort(
                                  (a, b) =>
                                    Number(b.TransId) - Number(a.TransId)
                                )
                                .filter((item) => item.RefId === data.RefId)
                                .reduce((acc, item) => {
                                  let {
                                    LinkedItm,
                                    MasterDescriptn,
                                    PriceUnit,
                                  } = item;
                                  let linkedKey = String(LinkedItm);

                                  if (linkedKey == "0" && MasterDescriptn) {
                                    acc[MasterDescriptn] = {
                                      uniqueId: MasterDescriptn,
                                      products: [item],
                                      total: parseInt(PriceUnit),
                                      childCount: 0,
                                    };
                                  }

                                  if (linkedKey != "0") {
                                    let uniqueKey = Object.keys(acc).find(
                                      (key) => key == linkedKey
                                    );
                                    if (uniqueKey) {
                                      acc[uniqueKey].products.push(item);
                                      acc[uniqueKey].total +=
                                        parseInt(PriceUnit);
                                      acc[uniqueKey].childCount += 1;
                                    }
                                  }

                                  return acc;
                                }, {})
                            ).map((group, index) => {
                              return (
                                <>
                                  <div className="flex-ord-sold">
                                    <div className="review-box-image order-image-box">
                                      <img
                                        className="favourite-img fav-image-ord prescription-img-width"
                                        src={`${imageBaseUrl}${group.products[0].IImgId}`}
                                        alt=""
                                      />
                                    </div>
                                    <div className="review-box-detail item-details-width-ord">
                                      {group.products.map((product, i) => (
                                        <div>
                                          {product.LinkedItm == 0 && (
                                            <>
                                              <div
                                                className="review-box-options--theme3"
                                                style={{
                                                  borderBottom: "none",
                                                  padding: "0px",
                                                }}
                                              >
                                                <div className="name-top-item">
                                                  {product.MasterName}
                                                </div>
                                                <div className="prize-55">
                                                  {companyData.currencySymbol}{" "}
                                                  {product.Qty *
                                                    product.PriceUnit}
                                                </div>
                                              </div>
                                              <p
                                                className="review-price-item"
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "flex-start",
                                                }}
                                              >
                                                <label
                                                  style={{ color: "darkGrey" }}
                                                >
                                                  Qty : {product.Qty} x{" "}
                                                  {companyData.currencySymbol}{" "}
                                                  {product.PriceUnit} ={" "}
                                                  {companyData.currencySymbol}{" "}
                                                  {product.Qty *
                                                    product.PriceUnit}
                                                </label>
                                              </p>
                                            </>
                                          )}

                                          {product.LinkedItm != 0 && (
                                            <div
                                              className="review-box-options--theme3"
                                              style={{
                                                borderBottom: "none",
                                                padding: "5px 0px",
                                              }}
                                            >
                                              <div className="Lens-color-cart3page">
                                                {product.ParentName}-
                                                {product?.MasterName}
                                              </div>
                                              <div className="prize-55">
                                                {companyData.currencySymbol}{" "}
                                                {product.Qty *
                                                  product?.PriceUnit}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  {
                                    // group.products[0]?.MasterDescriptn
                                    isModalOpenPower[index] && (
                                      <div
                                        style={{
                                          marginBottom: "1px solid lightGrey",
                                        }}
                                      >
                                        <div className="flex-add-manually-prescription">
                                          <div
                                            className="Prescription-manually"
                                            onClick={() =>
                                              toggleModalManually(
                                                group.products[0]?.RefId,
                                                group.products[1]?.MastId2,
                                                group.products[0]?.MasterName,
                                                group.products[0]?.IImgId,
                                                group.products[1]?.LensType,
                                                group.products[1]?.LensTypeEf,
                                                group.products[0]?.VouchNo,
                                                group.products[0]?.TransId,
                                                group.products[0]?.LinkedItm,
                                                group.products[0]?.MastId2
                                              )
                                            }
                                          >
                                            <div className="Manually-pres-cart5">
                                              <img
                                                className="prescription-image"
                                                src={enterManually}
                                                alt=""
                                              />
                                            </div>
                                            <div className="manually-name-prescription">
                                              Enter Prescription Manually
                                            </div>
                                          </div>
                                          <div
                                            className="Prescription-manually"
                                            onClick={() =>
                                              toggleModalSaved(
                                                group.products[0]?.RefId,
                                                group.products[1]?.MastId2,
                                                group.products[0]?.MasterName,
                                                group.products[0]?.IImgId,
                                                group.products[1]?.LensType,
                                                group.products[1]?.LensTypeEf,
                                                group.products[0]?.VouchNo,
                                                group.products[0]?.TransId,
                                                group.products[0]?.LinkedItm,
                                                group.products[0]?.MastId2
                                              )
                                            }
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <div className="Manually-pres-cart5">
                                              <img
                                                className="prescription-image"
                                                src={Prescription}
                                                alt=""
                                              />
                                            </div>
                                            <div className="manually-name-prescription">
                                              Use Saved Prescription
                                            </div>
                                          </div>
                                          <div
                                            className="Prescription-manually"
                                            onClick={() =>
                                              toggleModalUpload(
                                                group.products[0]?.RefId,
                                                group.products[1]?.MastId2,
                                                group.products[0]?.MasterName,
                                                group.products[0]?.IImgId,
                                                group.products[1]?.LensType,
                                                group.products[1]?.LensTypeEf,
                                                group.products[0]?.VouchNo,
                                                group.products[0]?.TransId,
                                                group.products[0]?.LinkedItm,
                                                group.products[0]?.MastId2
                                              )
                                            }
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <div className="Manually-pres-cart5">
                                              <img
                                                className="prescription-image"
                                                src={uploadPrescription}
                                                alt=""
                                              />
                                            </div>
                                            <div className="manually-name-prescription">
                                              Upload Prescription
                                            </div>
                                          </div>
                                          <div
                                            className="Prescription-manually"
                                            onClick={() =>
                                              toggleModalCallBack(
                                                group.products[0]?.RefId,
                                                group.products[1]?.MastId2,
                                                group.products[0]?.MasterName,
                                                group.products[0]?.IImgId,
                                                group.products[1]?.LensType,
                                                group.products[1]?.LensTypeEf,
                                                group.products[0]?.VouchNo,
                                                group.products[0]?.TransId,
                                                group.products[0]?.LinkedItm,
                                                group.products[0]?.MastId2
                                              )
                                            }
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <div className="Manually-pres-cart5">
                                              <img
                                                className="prescription-image"
                                                src={call}
                                                alt=""
                                              />
                                            </div>
                                            <div className="manually-name-prescription">
                                              Request A Call Back
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }

                                  {group.childCount > 0 &&
                                    lensData &&
                                    lensData.length > 0 ? (
                                    (() => {
                                      const filteredLenses = lensData.filter(
                                        (lense) =>
                                          lense.refId ==
                                          group.products[0].RefId &&
                                          lense.mastId2 ==
                                          group.products[1]?.MastId2 &&
                                          lense.transaction?.some(
                                            (item1) =>
                                              item1.rItemId_d2 ==
                                              group.products[1].MastId2
                                          )
                                      );
                                      console.log("filterData", filteredLenses);
                                      console.log(
                                        "filterData1",
                                        group.products[0]
                                      );
                                      console.log(
                                        "filterData2",
                                        group.products[1]
                                      );
                                      var LensSplitTypes =
                                        group.products[1]?.LensTypeEf.split(
                                          ","
                                        );
                                      if (filteredLenses.length == 0) {
                                        return (
                                          <div>
                                            <div
                                              className="flex-add-power"
                                              style={{
                                                marginTop: "10px",
                                              }}
                                              onClick={() =>
                                                toggleModalPower(index)
                                              }
                                            >
                                              <div>
                                                Submit "POWER" to complete order
                                              </div>
                                              <div className="flex-chevron-icon">
                                                <div className="Add-Power-ord">
                                                  ADD POWER
                                                </div>
                                                <div>
                                                  {" "}
                                                  <i
                                                    className={`fa-solid ${
                                                      isModalOpenPower
                                                        ? "fa-chevron-up"
                                                        : "fa-chevron-down"
                                                      } icon-downUp-chev`}
                                                  ></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      } else if (
                                        filteredLenses &&
                                        filteredLenses[0]?.transaction &&
                                        filteredLenses[0]?.transaction[0]
                                          ?.imgId != "" &&
                                        filteredLenses[0]?.transaction[0]
                                          ?.imgId != "0"
                                      ) {
                                        return (
                                          <div style={{}}>
                                            <img
                                              className="favourite-img fav-image-ord prescription-img-width"
                                              style={{ height: "150px" }}
                                              src={`${imageBaseUrl}${filteredLenses[0]?.transaction[0]?.imgId}`}
                                              alt="My Prescription"
                                            />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <>
                                            {filteredLenses &&
                                              filteredLenses[0].transaction
                                                .filter(
                                                  (item1) =>
                                                    item1.rItemId_d2 ==
                                                    group.products[1]?.MastId2
                                                )
                                                .map(
                                                  (lens, idx) => (
                                                    console.log("lensee", lens),
                                                    console.log(
                                                      "lensee2",
                                                      filteredLenses[0]
                                                        .transaction
                                                    ),
                                                    (
                                                      <div key={idx}>
                                                        <div className="Prescription-show-table">
                                                          {lens?.partyName !=
                                                            null &&
                                                            lens?.partyName !=
                                                            "" && (
                                                              <div className="customer-name-order">
                                                                {
                                                                  lens?.partyName
                                                                }
                                                              </div>
                                                            )}
                                                          <div className="overflow-table-pres">
                                                            <table className="table-prescription-data-ord">
                                                              <thead>
                                                                <tr>
                                                                  {LensSplitTypes.includes(
                                                                    "1"
                                                                  ) ||
                                                                    LensSplitTypes.includes(
                                                                      "3"
                                                                    ) ||
                                                                    LensSplitTypes.includes(
                                                                      "5"
                                                                    ) ||
                                                                    LensSplitTypes.includes(
                                                                      "6"
                                                                    ) ? ( //Bifocal, Progressive, Tinted Bifocal, Tinted Progressive
                                                                    <>
                                                                      <th></th>
                                                                      <th className="table-power-font">
                                                                        Spherical(SPH)
                                                                      </th>
                                                                      <th className="table-power-font">
                                                                        Cylindrical(CYL)
                                                                      </th>
                                                                      <th className="table-power-font">
                                                                        Axis(0-180)
                                                                      </th>
                                                                      <th className="table-power-font">
                                                                        Additional
                                                                        Power(AP)
                                                                      </th>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <th></th>
                                                                      <th className="table-power-font">
                                                                        Spherical(SPH)
                                                                      </th>
                                                                      <th className="table-power-font">
                                                                        Cylindrical(CYL)
                                                                      </th>
                                                                      <th className="table-power-font">
                                                                        Axis(0-180)
                                                                      </th>
                                                                    </>
                                                                  )}
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                <tr>
                                                                  {LensSplitTypes.includes(
                                                                    "1"
                                                                  ) ||
                                                                    LensSplitTypes.includes(
                                                                      "3"
                                                                    ) ||
                                                                    LensSplitTypes.includes(
                                                                      "5"
                                                                    ) ||
                                                                    LensSplitTypes.includes(
                                                                      "6"
                                                                    ) ? ( // 0- Single, 4-Tinted Single
                                                                    <>
                                                                      <td>
                                                                        LEFT
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.lSphNv
                                                                        }
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.lCylNv
                                                                        }
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.lAxisNv
                                                                        }
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.lAdd
                                                                        }
                                                                      </td>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <td>
                                                                        LEFT
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.lSphDv
                                                                        }
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.lCylDv
                                                                        }
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.lAxisDv
                                                                        }
                                                                      </td>
                                                                    </>
                                                                  )}
                                                                </tr>
                                                                <tr>
                                                                  {LensSplitTypes.includes(
                                                                    "1"
                                                                  ) ||
                                                                    LensSplitTypes.includes(
                                                                      "3"
                                                                    ) ||
                                                                    LensSplitTypes.includes(
                                                                      "5"
                                                                    ) ||
                                                                    LensSplitTypes.includes(
                                                                      "6"
                                                                    ) ? ( //Bifocal
                                                                    <>
                                                                      <td>
                                                                        RIGHT
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.rSphNv
                                                                        }
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.rCylNv
                                                                        }
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.rAxisNv
                                                                        }
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.rAdd
                                                                        }
                                                                      </td>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <td>
                                                                        RIGHT
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.rSphDv
                                                                        }
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.rCylDv
                                                                        }
                                                                      </td>
                                                                      <td className="prescription-number">
                                                                        {
                                                                          lens?.rAxisDv
                                                                        }
                                                                      </td>
                                                                    </>
                                                                  )}
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  )
                                                )}
                                          </>
                                        );
                                      }
                                    })()
                                  ) : (
                                    <div></div>
                                  )}
                                </>
                              );
                            })}
                        </div>

                        {/* ************************ Enter prescription Manually *************** */}
                        {isModalOpenManually && (
                          <div className="modal-back">
                            <div
                             
                              className="OpticalField-modal increase_modal_cart5_manually "
                            >
                              <div
                                className="model-manually-pres"
                              
                              >
                                <div className="Modal-Title-Lc ">
                                  <label
                                    className="close_modal-theme3"
                                    onClick={closeModalManually}
                                  >
                                    <i class="fa-solid fa-xmark"></i>
                                  </label>
                                </div>
                                <div className="technical-scroll-detail model-manually">
                                  <ModelPrescriptionManually
                                    onContinue={handleSubmitEyePower}
                                    isSubmitted={isSubmitted}
                                    refId={refId}
                                    itemData={itemData}
                                    lensType={itemData?.LensTypeEf?.split(",")}
                                    closeModal={closeModalManually}
                                    setIsModalOpenPower={setIsModalOpenPower}
                                    fetchMyOrderData={fetchMyOrderData}
                                    toggleModalPower={toggleModalPower}
                                    setRunCount={setRunCount}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* ************************ Use Saved Prescription *************** */}
                        {isModalOpenSaved && (
                          <div className="modal-back">
                            <div
                              style={{ zIndex: "5" }}
                              className="OpticalField-modal increase_modal_cart5"
                            >
                              <div
                                className="model-manually-pres"
                                style={{ padding: "5px" }}
                              >
                                <div className="Modal-Title-Lc ">
                                  <label
                                    className="close_modal-theme3"
                                    onClick={closeModalSaved}
                                  >
                                    <i class="fa-solid fa-xmark"></i>
                                  </label>
                                </div>
                                <div
                                  className="technical-scroll-detail model-Saved-prescription"
                                  style={{ height: "85vh" }}
                                >
                                  {/* <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: item.longDescription }} /> */}
                                  <ModelSavedPrescription
                                    refId={refId}
                                    itemData={itemData}
                                    closeModal={closeModalSaved}
                                    isSelectShow={true}
                                    setIsModalOpenPower={setIsModalOpenPower}
                                    fetchMyOrderData={fetchMyOrderData}
                                    toggleModalPower={toggleModalPower}
                                    setRunCount={setRunCount}
                                    lensData={savedLensData}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* ************************ Upload Prescription *************** */}
                        {isModalOpenUpload && (
                          <div className="modal-back">
                            <div
                              style={{ zIndex: "5" }}
                              className="OpticalField-modal increase_modal_cart5_manually"
                            >
                              <div
                                className="model-manually-pres"
                                style={{ padding: "5px" }}
                              >
                                <div className="Modal-Title-Lc ">
                                  <label
                                    className="close_modal-theme3"
                                    onClick={closeModalUpload}
                                  >
                                    <i class="fa-solid fa-xmark"></i>
                                  </label>
                                </div>
                                <div className="technical-scroll-detail model-upload-padd">
                                  {/* <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: item.longDescription }} /> */}
                                  <ModelUploadPrescription
                                    refId={refId}
                                    itemData={itemData}
                                    setIsModalOpenPower={setIsModalOpenPower}
                                    closeModal={closeModalUpload}
                                    toggleModalPower={toggleModalPower}
                                    fetchMyOrderData={fetchMyOrderData}
                                    setRunCount={setRunCount}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* ************************ Request a Call back *************** */}
                        {isModalOpenCallBack && (
                          <div className="modal-back">
                            <div
                              style={{ zIndex: "5" }}
                              className="OpticalField-modal increase_modal_cart5_manually "
                            >
                              <div
                                className="model-manually-pres"
                                style={{ padding: "5px" }}
                              >
                                <div className="Modal-Title-Lc ">
                                  <label
                                    className="close_modal-theme3"
                                    onClick={closeModalCallBack}
                                  >
                                    <i class="fa-solid fa-xmark"></i>
                                  </label>
                                </div>
                                <div className="technical-scroll-detail model-manually">
                                  {/* <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: item.longDescription }} /> */}
                                  <ModelRequestACallBack itemData={itemData} />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="flex-order-delivery">
                          <div className="order-process">
                            <div className="Green-Line"></div>
                            <div className="order-start-icon">
                              <i class="fa-solid fa-cart-plus"></i>
                            </div>
                            <div className="process-by-order order-color">
                              Order Created
                            </div>
                          </div>
                          <div className="order-process">
                            <div className="Green-Line-dispatch"></div>
                            <div className="circle-icon-ord">
                              <i class="fa-regular fa-circle"></i>
                            </div>
                            <div className="process-by-order">
                              Prepare For Dispatch
                            </div>
                          </div>
                          <div className="order-process">
                            <div className="Green-Line-way"></div>
                            <div className="circle-icon-ord">
                              <i class="fa-regular fa-circle"></i>
                            </div>
                            <div className="process-by-order">On the Way</div>
                          </div>
                          <div className="order-process">
                            <div className="circle-icon-ord">
                              <i class="fa-regular fa-circle"></i>
                            </div>
                            <div className="process-by-order">Delivered</div>
                          </div>
                        </div>

                        {/* ************************* Overall Total ************************** */}
                        <>
                          <div className="flex-ord-details">
                            <div
                              className="order-details-open"
                              onClick={() => toggleClass(data.VouchNo)}
                            >
                              Order Details
                            </div>
                          </div>
                          {isClicked[data.VouchNo] && (
                            <div className="order-box-top">
                              <div className="address-detail-width">
                                <p className="ordertxt-1-delivery">
                                  Delivery Address
                                </p>
                                <p className="ordertxt-1-name">
                                  {data.EcomDelAddress}
                                </p>
                                {/* <p className="ordertxt-2-address">
                                                                Adarsh Nagar
                                                            </p>
                                                            <p className="ordertxt-2-address">Durg - 492001</p>
                                                            <p className="ordertxt-2-address">Chhattisgarh, India</p>
                                                            <p className="ordertxt-2-email">techcherry@gmail.com</p>
                                                            <p className="ordertxt-2-number">+91-9876543210</p> */}
                                <div className="flex-view-bill">
                                  {/* <div className="View-bill-content">
                                    <button
                                      className="view-bill-ord"
                                      onClick={() =>
                                        handleViewBill(data.TransId)
                                      }
                                    >
                                      View Bill
                                    </button>
                                  </div> */}
                                  <div className="View-bill-content">
                                    <button
                                      className="view-bill-ord"
                                      //onClick={toggleModalViewBill}
                                      onClick={() =>
                                        handleModalViewBill(data.TransId)
                                      }
                                    >
                                      View Bill
                                    </button>
                                  </div>
                                  <div className="download-bill-content">
                                    <button
                                      className="view-bill-ord"
                                      onClick={() =>
                                        handleDownloadBill(data.TransId)
                                      }
                                    >
                                      Download Bill
                                    </button>
                                  </div>
                                </div>

                                {viewBill && (
                                  <div
                                    onClick={hideViewBillModal3}
                                    className="modal-back"
                                  >
                                    <div
                                      onClick={handleViewBillModalClick}
                                      style={{ zIndex: "5" }}
                                      className="OpticalField-modal"
                                    >
                                      <div style={{ padding: "20px" }}>
                                        <div className="">
                                          <label
                                            className="close_modal-theme3"
                                            onClick={closeViewBillModal}
                                          >
                                            <i class="fa-solid fa-xmark"></i>
                                          </label>
                                        </div>
                                        <div>
                                          <div className="technical-scroll-detail">
                                            <ReportDesignForA4
                                              templateName="FinalBillPrint"
                                              currentUser={currentUser}
                                              ord1Id={transId}
                                              setViewBill={setViewBill}
                                              isViewBill={true}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {(showBillPrint || downloadBill) && (
                                  <div style={{ display: "none" }}>
                                    <div
                                      className="modal-back"
                                      onClick={closeBillPrint}
                                    >
                                      <div
                                        className="billPrintModal"
                                        style={{}}
                                      >
                                        <div
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "white",
                                          }}
                                        >
                                          {/* <ReportDesignForA4
                                            templateName="FinalBillPrint"
                                            currentUser={currentUser}
                                            ord1Id={transId}
                                            setShowBillPrint={setShowBillPrint}
                                            { downloadBill && setDownloadBill= {setDownloadBill}}
                                          setLoading={setLoading}
                                          /> */}
                                          <ReportDesignForA4
                                            templateName="FinalBillPrint"
                                            currentUser={currentUser}
                                            ord1Id={transId}
                                            // setShowBillPrint={setShowBillPrint}
                                            {...(showBillPrint && {
                                              setShowBillPrint,
                                            })}
                                            {...(downloadBill && {
                                              setDownloadBill,
                                            })}
                                            setLoading={setLoading}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="address-detail-width">
                                <p className="ordertxt-1-delivery">
                                  Price Breakup:
                                </p>
                                <div className="flex-subtotal">
                                  <p className="ordertxt-1-subtotal">
                                    Subtotal
                                  </p>
                                  <p className="ordertxt-1-subtotal">
                                    {companyData.currencySymbol}{" "}
                                    {Number(data.BillAmt).toFixed(2)}
                                  </p>
                                </div>
                                <div className="flex-autodiscount">
                                  <p className="ordertxt-1-subtotal">
                                    Auto Discount
                                  </p>
                                  <p className="ordertxt-1-subtotal">
                                    {companyData.currencySymbol} 0
                                  </p>
                                </div>
                                <div>
                                  <div className="flex-subtotal-netamt">
                                    <p className="ordertxt-1-Amount">
                                      Net Amount
                                    </p>
                                    <p className="ordertxt-1-Amount">
                                      {companyData.currencySymbol}{" "}
                                      {Number(data.BillAmt).toFixed(2)}
                                    </p>
                                  </div>
                                  <div className="flex-subtotal">
                                    <p className="ordertxt-1-Convenience">
                                      Convenience Fees
                                    </p>
                                    <p className="ordertxt-1-Convenience">
                                      Free
                                    </p>
                                  </div>
                                </div>
                                <div className="flex-subtotal-netamt">
                                  <p className="ordertxt-1-Amount">Total</p>
                                  <p className="ordertxt-1-Amount">
                                    {companyData.currencySymbol}{" "}
                                    {Number(data.BillAmt).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </>

                        {/* <div className="favourite-box">
                                                <div className="review-box-image">
                                                    <img className="favourite-img"
                                                        src="https://happychashma.com/shop/images/product/Green_Year_9017_Metal_Half_Rim_52_Rectangle_Black_502_1.jpg"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="review-box-detail">
                                                    <div className="review-box-detail-line-1">
                                                        <div className="review-box-name">
                                                            Peyush Bansal Shark Tank Monza Red Full Rim Hustler
                                                            Eyeglasses....
                                                        </div>
                                                        <div className="review-box-section">
                                                            <img className="fa-trash" src={dlt} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="review-box-detail-line-2">
                                                        <div className="lens-txt">
                                                            Lens :{" "}
                                                            <div className="review-product-name">
                                                                Black Full Frame Eyeglasses
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "60px",
                                                            }}
                                                        >
                                                            {" "}
                                                            <p className="review-price">
                                                                <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                                                            </p>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      <div>
        {/* <div
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        width: "100%",
                        margin: "5px 0px"
                    }}
                >
                    <button
                        onClick={() => previousPage()}
                        //disabled={!canPreviousPage}
                        className="table-list-button"
                    >
                        <i className="fa-solid fa-chevron-left"></i>
                    </button>
                    <span className="table-list-page-txt">
                        Page -
                        <strong style={{ margin: "0px 3px" }}>
                            {pageIndex}
                        </strong>
                    </span>
                    <button
                        onClick={() => nextPage()}
                        //disabled={!canNextPage}
                        className="table-list-button"
                    >
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </div> */}
      </div>
    </div>
  );
};

export default OrderBox_Lc;
