import { useState } from 'react';
import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);


const AddAddress = {

    insertAddress: async (data, myAccountID) => {

        function parseBoolean(v) {
            if (typeof v === "boolean") {
                return v;
            } else if (typeof v === "string") {
                v = v.toLowerCase().trim();
                return v === "true";
            } else {
                console.log("error", v);
                return false;
            }
        }
        try {
            var WebApiUrl = express_server_url;
            WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
            const apiUrl = `${WebApiUrl}/api/Account/CreateAccountAddress`;
            const response = await axios.post(apiUrl, {
                accountId: myAccountID,
                name: data.name,
                countryCallCode: data.countryCallCode,
                mobile: data.mobile,
                pincode: data.pincode,
                state: data.state,
                address: data.address,
                locality: data.locality,
                city: data.city,
                countryCode:data.countryCode ||  data.country,
                typeOfAddress: Number(data.typeOfAddress),
                isDefault: parseBoolean(data.isDefault),
                isActive: true
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getAddress: async (AccountId) => {
        try {
            var WebApiUrl = express_server_url;
            WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

            const apiUrl = `${WebApiUrl}/api/Account/GetAccountAllAddress?AccountId=${AccountId}`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteAddress: async (AddressId, AccountId) => {

        try {
            var WebApiUrl = express_server_url;
            WebApiUrl = express_server_url.replace("localhost", window.location.hostname);


            const apiUrl = `${WebApiUrl}/api/Account/DeleteAccountAddress?AddressId=${AddressId}&AccountId=${AccountId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getAddressById: async (AddressId, AccountId) => {

        try {
            var WebApiUrl = express_server_url;
            WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

            const apiUrl = `${WebApiUrl}/api/Account/GetAccountAddress?AddressId=${AddressId}&AccountId=${AccountId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************

    updateAddress: async (data, myAccountID) => {

        function parseBoolean(v) {
            if (typeof v === "boolean") {
                return v;
            } else if (typeof v === "string") {
                v = v.toLowerCase().trim();
                return v === "true";
            } else {
                console.log("error", v);
                return false;
            }
        }

        try {
            var WebApiUrl = express_server_url;
            WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
            const apiUrl = `${WebApiUrl}/api/Account/UpdateAccountAddress?AddressId=${data.id}&AccountId=${myAccountID}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                accountId: myAccountID,
                name: data.name,
                countryCallCode: data.countryCallCode,
                mobile: data.mobile,
                pincode: data.pincode,
                state: data.state,
                address: data.address,
                locality: data.locality,
                city: data.city,
                countryCode:data.countryCode ||  data.country,
                typeOfAddress: Number(data.typeOfAddress),
                isDefault: parseBoolean(data.isDefault),
                isActive: true
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return response.data.isSuccess
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get Account Types ***************************
    getAccountTypes: async () => {
        try {
            var WebApiUrl = express_server_url;
            WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
            const apiUrl = `${WebApiUrl}/api/Account/GetAccountTypes`;
            const response = await axios.get(apiUrl, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Create Account Master Setting ***************************    
    insertAccountMasterSetting: async (settingData, optionalData) => {
        let data = [];
        for (var i = 0; i < optionalData.length; i++) {
            data.push({
                optionalFieldName: optionalData[i].optionalFieldName,
                priority: optionalData[i].priority,
                isDeleted: false,
                isActive: true
            });
        }
        try {
            var WebApiUrl = express_server_url;
            WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
            const apiUrl = `${WebApiUrl}/api/Account/CreateAccountMasterSetting`;
            console.log(apiUrl);
            console.log(settingData);
            const response = await axios.post(apiUrl, {
                accountDetails: Boolean(settingData.accountDetails),
                aliasNPrintDetails: Boolean(settingData.aliasNPrintDetails),
                documentDetails: Boolean(settingData.documentDetails),
                transportDetails: Boolean(settingData.transportDetails),
                defaultSalesPerson: Boolean(settingData.defaultSalesPerson),
                optionalField: Boolean(settingData.optionalField),
                isActive: true,
                accountOptionalFields: data //previous data + latest updated data;
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return true;

        } catch (error) {
            console.error("Error:", error.message);
            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get Account Master Setting ***************************
    getAccountMasterSetting: async () => {
        try {
            var WebApiUrl = express_server_url;
            WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
            const apiUrl = `${WebApiUrl}/api/Account/GetAccountMasterSetting`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Get Account Optional Field ***************************
    getAccountOptionalField: async () => {
        try {
            var WebApiUrl = express_server_url;
            WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
            const apiUrl = `${WebApiUrl}/api/Account/GetAccountOptionalField`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
};

export default AddAddress;
