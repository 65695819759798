import React, { useContext } from "react";
import "../../../Css/Store.scss";
import logo from "../../../Assets/Images/logo.jpg"
import { Link } from 'react-router-dom';
import MyContext from "../../../MyContext";


function Header() {
  const { companyData, imageBaseUrl } = useContext(MyContext);
  return (
    <div class="header-container1">
      <div class="header-content">
        <Link to="/" class="logo-container">
          <img className="len2cart-logo-size"
            src={`${imageBaseUrl}${companyData?.companyImageId}`}
            alt="lens2cart-logo"
          />
        </Link>

        <div class="contact-info">
          {/* <div class="contact-item">
            <img
              src="https://www.lenskart.com/stores/svg/phone-call.svg"
              alt="Call"
            />
            <a href="#" class="contact-link">{companyData.mobileNo}</a>
          </div> */}
          <a class="social-icon" href="https://www.facebook.com/people/Lens2cart/61569104277658/">
            <img src="https://www.lenskart.com/stores/svg/facebook.svg" alt="Facebook" />
          </a>
          <a class="social-icon" href="https://www.instagram.com/lens2cart_kenya/">
            <img src="https://www.lenskart.com/stores/svg/instagram.svg" alt="Instagram" />
          </a>
          <a class="social-icon" href="https://www.youtube.com/embed/ExDCXACJzjw?si=NpY_AMh-jzji82LR">
            <img src="https://www.lenskart.com/stores/svg/youtube.svg" alt="Youtube" />
          </a>
          <div class="social-icon">
            <img src="https://www.lenskart.com/stores/svg/web.svg" alt="Website" />
          </div>
        </div>
      </div>
    </div>

  );
}

export default Header;
