import React, { useState } from "react";

const SignInPanel = (props) => {
  const { handleInputChangeSignIn, inputValuesForSignIn, signupshow, SendOtp } =
    props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = (val) => {
    setShowPassword(!showPassword);
  };

  const [showPasswordRe, setShowPasswordRe] = useState(false);

  const togglePasswordVisibilityRe = (val) => {
    setShowPasswordRe(!showPasswordRe);
  };

  const { close } = props;

  return (
    <div className="form-container">
      <div className="SignIn-Padding-Lc">
        <i
          style={{ cursor: "pointer" }}
          onClick={close}
          className="fa-solid fa-xmark modal-cross"
        ></i>
        <p style={{ marginBottom: "20px", border: "none" }} className="title">
          Create an Account
        </p>
        <div className="form-modal">
          <input
            type="email"
            className="input-modal"
            placeholder="Name"
            name="name"
            id="name-signin"
            onChange={handleInputChangeSignIn}
            value={inputValuesForSignIn.name}
          />
          <input
            type="email"
            className="input-modal"
            placeholder="Email"
            name="aEmail"
            id="email-signin"
            onChange={handleInputChangeSignIn}
            value={inputValuesForSignIn.aEmail}
          />
          <input
            type="email"
            className="input-modal"
            placeholder="Mobile No"
            name="aMobileNo"
            id="mobile-signin"
            onChange={handleInputChangeSignIn}
            value={inputValuesForSignIn.aMobileNo}
          />
          <input
            type={showPassword ? "text" : "password"}
            className="input-modal"
            placeholder="Password"
            name="password"
            id="password1"
            onChange={handleInputChangeSignIn}
            value={inputValuesForSignIn.password}
          />
          <input
            type={showPasswordRe ? "text" : "password"}
            className="input-modal"
            placeholder="Re-Enter Password"
            name="reEnterpassword"
            id="password1"
            onChange={handleInputChangeSignIn}
            value={inputValuesForSignIn.reEnterpassword}
          />
          <div
            style={{ textDecoration: "none", textAlign: "center" }}
            className="form-btn"
            onClick={SendOtp}
          >
            Create an Account
          </div>
          <div className="sent-email-add">OTP will be sent to the email address</div>
          <div
            className="input-image position-icon-pw signup-pwd-top"
            onClick={() => togglePasswordVisibility("password")}
          >
            {showPassword ? (
              <i className="fa-solid fa-eye-slash"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </div>
          <div
            className="input-image position-icon-pw Sign-reenter-top"
            onClick={() => togglePasswordVisibilityRe("password")}
          >
            {showPasswordRe ? (
              <i className="fa-solid fa-eye-slash"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </div>
          {/* <button class="verifyButton" type="submit">Verify</button> */}
          <p class="resendNote">
            Have an account?{" "}
            <button class="resendBtn" onClick={signupshow}>
              Log In
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};
export default SignInPanel;
