import axios from "../axiosConfig"; // Adjust the import path accordingly

// const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
// const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
  WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
  
const Country = {
	
  // ************************* Get All ***************************
  GetAllCountry: async () => {
    console.log("Country");
    try {
      const apiUrl = `${WebApiUrl}/api/Country/GetCountries`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "string",
              ascending: false
            }
          ],
          pageNumber: 0,
          pageSize: 50000
        },
        {
          withCredentials: true,
        }
      );
    //   console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetAllCountryWithFilter: async (data) => {
    debugger;
    const filtData = data.map((x) => ({
      fieldName: x.fieldName,
      operatorName: x.operatorName,
      compareValue: x.compareValue,
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/Country/GetCountries`;
      const response = await axios.post(
        apiUrl,
        {
          filter: filtData,
          order: [
            {
              propertyName: "string",
              ascending: false,
            },
          ],
          pageNumber: 0,
          pageSize: 4000,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


};
export default Country;
