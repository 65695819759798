import React, { useContext, useState } from "react";
import Transaction from "../../../API/API_SERVISES/Transaction";
import Loader1 from "../Loaders/Loader1";
import MyContext from "../../MyContext";

const ModelUploadPrescription = (props) => {

    const { imageBaseUrl } = useContext(MyContext);

    const [imageFile, setImageFile] = useState([]);

    const { itemData, closeModal, fetchMyOrderData, toggleModalPower, } = props;

    const removeImage = (index) => {
        setImageFile(prevImages => {
            const newImages = [...prevImages];
            URL.revokeObjectURL(newImages[index].preview);
            newImages.splice(index, 1);
            return newImages;
        });
    };

    const [loading, setLoading] = useState(false);

    const handleImageSelection = (e) => {
        const files = Array.from(e.target.files);
        const imageFilesWithPreviews = files.map(file => ({
            file,
            preview: URL.createObjectURL(file)
        }));
        setImageFile(prevImages => [...prevImages, ...imageFilesWithPreviews]);
    }

    const UploadImagePrescription = async () => {
        setLoading(true);
        const userLogin = localStorage.getItem("accountLogin");
        const userArray = userLogin ? JSON.parse(userLogin) : null;

        const formData = new FormData();
        console.log(imageFile[0].file);
        formData.append(`files`, imageFile[0].file);
        formData.append(`AccId`, Number(userArray.d2Id));
        console.log(formData);
        const uploadimage = await Transaction.UpdateLensPrescriptionImage(formData);
        if (uploadimage.isSuccess == true) {
            alert("Image uploaded successfully");
            var imageIdToSend = uploadimage.result.imageId[0];
            const filterData = {
                indx: 0,
                rItemId_d2: itemData.itemId || 0,
                rItemName: "",
                rSphDv: "",
                rCylDv: "",
                rAxisDv: "",
                rVnDv: "",
                rSphNv: "",
                rCylNv: "",
                rAxisNv: "",
                rVnNv: "",
                rAdd: "",
                rpB1: "",
                rpB2: "",
                lItemId_d2: itemData.itemId || 0,
                lItemName: "",
                lSphDv: "",
                lCylDv: "",
                lAxisDv: "",
                lVnDv: "",
                lSphNv: "",
                lCylNv: "",
                lAxisNv: "",
                lVnNv: "",
                lAdd: "",
                lpB1: "",
                lpB2: "",
                printLenseStatus: "",
                printLensEye: "",
                lenseType: itemData?.lensType || "",
                prescribedBy: "",
                partyId: userArray.d2Id,
                partyName: "",
                frameName: "",
                remark: "",
                nextVisit: "",
                nvDate: "",
                lensExpRem: "",
                lExDate: "",
                pdr: "",
                pdl: "",
                pdrl: "",
                deliverDays: "",
                deliverTime: "",
                contact: "",
                transpose: "",
                ar: "",
                im: "",
                imgId: imageIdToSend,
                linkedItm: itemData.linkedItem || 0,
                itemId_d2: itemData.itemId || 0
            }
            const transData = await Transaction.GetTransactionById(itemData.transId, 71);
            const updateTransData = await Transaction.SetTransLensData(filterData, transData);
            const createTrans = await Transaction.createTransaction(transData);

            // setIsModalOpenPower(prevState => ({
            //     ...prevState,
            //     [itemData.vouchNo]: !prevState[itemData.vouchNo] // Toggle only the specific modal state
            // }));

            toggleModalPower(itemData.mastId2, false);

            // const filterData = [
            //     {
            //         operationType: 0,
            //         path: "ImgId",
            //         op: "Add",
            //         from: "string",
            //         value: imageIdToSend
            //     }
            // ]
            // const imageUpadate = await Transaction.updateTempTransLensPartially(itemData.refId, itemData.itemId, itemData.linkedItem, filterData);
            fetchMyOrderData();
            setLoading(false);
            closeModal();
        } else {
            alert("error while uploading the image");
            setLoading(false);
        }
    };

    // const handleButtonClick = () => {
    //     const fileInput = document.getElementById('image-upload');
    //     fileInput.value = ""; // Clear the file input value
    //     fileInput.click();
    // };

    return (
        <>
            <div>
                <div className="flex-ord-sold mdl-manual-pre">
                    <div className="review-box-image order-image-box">
                        <img className="favourite-img fav-image-ord"
                            src={`${imageBaseUrl}${itemData.imgId}`}
                            alt=""
                        />
                    </div>
                    <div className="review-box-detail item-details-width-ord">
                        <div className="review-box-detail-line-1">
                            <div className="name-top-item">{itemData.itemName}</div>
                        </div>
                    </div>
                </div>
                <div className="upload-top-pres">UPLOAD YOUR PRESCRIPTIONS</div>
                <div className="">
                    <div className="image-uploader" style={{ height: "150px" }}>
                        {/* <div data-filetype=".jpg" class="filepicker-file-icon">
                        <div className="jpg-color-file">.JPG</div>
                            </div>
                            <div data-filetype=".png" class="filepicker-file-icon">
                                <div className="jpg-color-file">.PNG</div>
                            </div>
                            <div data-filetype=".gif" class="filepicker-file-icon">
                                <div className="jpg-color-file">.GIF</div>
                         </div> */}

                        <input type="file" hidden
                            accept=".jpg,.jpeg,.png,.gif"
                            // multiple
                            onChange={handleImageSelection}
                            class="file-input"
                            style={{ display: "" }}
                            id="image-upload"
                            placeholder="Drop files here to upload/ Click to Upload"
                        />
                        <div className="image-preview-container" style={{}}>
                            {/* <button type="button" onClick={() => handleButtonClick()}>Select Images</button> */}
                            {imageFile && imageFile.map((image, index) => (
                                <div key={index} className="image-preview">
                                    <img src={image.preview} alt={`Preview ${index}`} />
                                    <button onClick={() => removeImage(index)} className="remove-btn">Remove</button>
                                </div>
                            ))}
                        </div>
                        {/* <div className="drop-files-upload" style={{ left: "0" }}>Drop files here to upload/ Click to Upload</div> */}
                    </div>
                    <div style={{ marginTop: "70x", textAlign: "end" }}>
                        <button onClick={UploadImagePrescription}>Upload Image</button>
                    </div>
                </div>
                {loading && <Loader1 />}
            </div>
        </>

    );
};

export default ModelUploadPrescription;
