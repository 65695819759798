import React, { useContext, useEffect, useState, useMemo } from "react";

import Modal from "../../Component/Modal/Modal";
import AlertBox from "../../Component/AlertBox/AlertBox";
import AddCouponModal from "../../Component/Modal/AddCouponModal";
import Order from "../../../API/API_SERVISES/Order";
import MyContext from "../../MyContext";
import { Link } from "react-router-dom";
import ItemMasterAPI from "../../../API/API_SERVISES/ItemMaster";
import LoginApi from "../../../API/API_SERVISES/Login";
import { useNavigate } from "react-router-dom";
import CouponMasterApi from "../../../API/API_SERVISES/CouponMaster";
import BillSundry from "../../../API/API_SERVISES/BillSundry";
import Loader1 from "../../Component/Loaders/Loader1";
import CouponCard from "../../Component/Card/CouponCard";
import BillDetailCard from "../../Component/Card/BillDetailCard";
import LoyaltyMaster from "../../../API/API_SERVISES/LoyaltyMaster";
import Account from "../../../API/API_SERVISES/Account";
import CartDetailCard from "../../Component/Card/CartDetailCard";

const CartPage3 = (props) => {
  // const [modal, setModal] = useState(false);
  const {
    companyData,
    CouponApplied,
    setCouponApplied,
    AppliedCouponData,
    setAppliedCouponData,
    GetCouponData,
    calculateTotalPrice,
    signin,
    signupshow,
    setIsMembershipCardAdded,
    myaccountmodal1,
    setAllCouponsData,
    AllCouponsData,
    modal,
    totalQuantity,
    InputValues,
    imageBaseUrl,
    fetchDataWish,
    currentUser,
    wishList,
    fetchDataCart,
    myAccount,
    setMyAccount,
    ProducrDetailPageURL,
    isMembershipCardAdded
  } = useContext(MyContext);

  const [alert, setAlert] = useState(false);
  const [alertForCoupon, setAlertForCoupon] = useState(false);

  const [addcoupon, setAddCoupon] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const [coupon, setCoupon] = useState(AllCouponsData);
  const ApplyCoupon = async (CouponData) => {
    setLoading(true);
    await OpenAddCoupon();
    debugger;
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    var SundryData = await BillSundry.GetBillSundry();
    var discountId = SundryData.find((item) => item.name == "Discount")?.id;
    var discountAmtId = SundryData.find(
      (item) => item.name == "Discount Amt"
    )?.id;
    var data = {
      rowIndex: 0,
      id: CouponData.discountType == "1" ? discountId : discountAmtId,
      adjustmentInItem: true,
      Percent: CouponData.discountType == "1" ? CouponData.discountValue : 0,
      Amount: CouponData.discountType != "1" ? CouponData.discountValue : 0,
    };

    var newSundryData = CouponApplied.some((coupon) => coupon.id === data.id)
      ? CouponApplied.map((coupon) =>
        coupon.id === data.id
          ? {
            ...coupon,
            Amount: data.Amount ?? coupon.Amount,
            Percent: data.Percent ?? coupon.Percent,
          }
          : coupon
      )
      : [...CouponApplied, data];

    // Separate items based on conditions
    let amountZeroItems = [];
    let PercentZeroItems = [];
    let otherItems = [];

    newSundryData.forEach((item) => {
      if (item.Amount === 0) {
        amountZeroItems.push(item);
      } else if (item.Percent === 0) {
        PercentZeroItems.push(item);
      } else {
        otherItems.push(item);
      }
    });

    // Reconstruct the array: Amount === 0 first, then normal items, then Percent === 0
    newSundryData = [...PercentZeroItems, ...otherItems, ...amountZeroItems];
    var stringifydata = JSON.stringify(newSundryData);
    var encodedData = encodeURIComponent(stringifydata);
    var newCouponData = AppliedCouponData.some(
      (coupon) => coupon.id === CouponData.id
    )
      ? [...AppliedCouponData]
      : [...AppliedCouponData, CouponData];

    // Separate items based on discountType condition
    let discountTypeOne = [];
    let otherDiscounts = [];

    newCouponData.forEach((item) => {
      if (item.discountType === "1") {
        discountTypeOne.push(item);
      } else {
        otherDiscounts.push(item);
      }
    });

    // Reconstruct the array: discountType != "1" first, then discountType == "1"
    newCouponData = [...otherDiscounts, ...discountTypeOne];
    var stringifydataCoupon = JSON.stringify(newCouponData);
    var encodedDataCoupon = encodeURIComponent(stringifydataCoupon);
    console.log("COUPONDATA", data);
    console.log("COUPONDATA2", CouponData);

    const CartData = [
      {
        id: 0,
        useType: 0,
        accId: loginData.d2Id || 0,
        ex1: encodedData || "",
        ex2: encodedDataCoupon || "",
        ex3: "",
        ex4: "",
        ex5: "",
        iEx1: 0,
        iEx2: 0,
        iEx3: 0,
        nEx1: 0,
        nEx2: 0,
        nEx3: 0,
        nEx4: 0,
        nEx5: 0,
      },
    ];

    var coupon = await Order.SaveEcomCartExtrafields(CartData);
    debugger;
    if (coupon.isSuccess) {
      setLoading(false);
      setCouponApplied(data);
      setTimeout(() => {
        // if (loginData && loginData.d2Id) {
          GetCouponData(loginData?.d2Id || 0, InputValues, false);
        // }
        //  else {
        //   calculateTotalPrice();
        // }
      }, 100);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    // console.log(loginData.d1Id);
    // if (loginData && loginData.d1Id) {
      if (InputValues.length != 0) {
        console.log("function called", InputValues.length);
        setTimeout(() => {
          GetCouponData(loginData?.d2Id || 0, InputValues, true);
        }, 100);
      }
    // }
    //  else {
    //   calculateTotalPrice();
    // }
  }, [InputValues.length]);

  console.log(CouponApplied);

  const navigate = useNavigate();
  const [DeletingId, setDeletingId] = useState(0);
  const [DeletingLinkeditm, setDeletingLinkeditm] = useState();
  const [DeletingCoatingName, setDeletingCoatingName] = useState();


  const alertbox = (id, linkedItm , coatingName) => {
    setDeletingCoatingName(coatingName)
    setDeletingLinkeditm(linkedItm);
    setDeletingId(id);
    setAlert(!alert);
  };

  const alertbox1 = (
    e,
    id,
    AppliedCouponDatalength,
    AppliedCouponData,
    Item
  ) => {
    setCouponDeletingId(id);
    setNoOfCoupon(AppliedCouponDatalength);
    setCouponEvent(e);
    setCouponDataForDelete(Item);
    setAppliedCouponDataForDelete(AppliedCouponData);
    setAlertForCoupon(!alertForCoupon);
  };

  const [CouponDeletingId, setCouponDeletingId] = useState(0);
  const [NoOfCoupon, setNoOfCoupon] = useState(0);
  const [CouponEvent, setCouponEvent] = useState(0);
  const [AppliedCouponDataForDelete, setAppliedCouponDataForDelete] =
    useState(0);
  const [CouponDataForDelete, setCouponDataForDelete] = useState(0);

  const RemoveCoupon = async (
    event,
    id,
    NoOFCoupons,
    AppliedCouponData1,
    Coupon
  ) => {
    debugger;

    event.stopPropagation();
    var loginDataString = localStorage.getItem("accountLogin"); // This is a string
    var loginData = JSON.parse(loginDataString);
    // if (loginData && loginData.d2Id) {
      if (NoOFCoupons == 1) {
        var RemoveCouponData = await Order.RemoveAllEcomCartExtrafields(
          loginData?.d2Id || 0
        );
        if (RemoveCouponData.isSuccess == true) {
          alertbox1();
          setCouponApplied([]);
          GetCouponData(loginData?.d2Id || 0, InputValues, false);
        } else {
          alertbox1();
          window.alert("Error while Deleting coupon");
        }
        // var itemId;
        // if (MembershipItems.length == 0) {
        //   const filteredata = [
        //     {
        //       fieldName: "productCategory",
        //       operatorName: "equal",
        //       compareValue: 3,
        //     },
        //   ];
        //   var Dataid = await ItemMaster.GetItemMasterFilter(filteredata);
        //   if (Dataid && Dataid.length > 0) {
        //     setMembershipItems(Dataid);
        //     var membershipDetails =
        //       await LoyaltyMaster.GetLoyaltyMemberShipAccountCardDetail(
        //         id
        //       );
        //     if (membershipDetails) {
        //     }

        //     itemId = Dataid.find((item) => item.iUseMembershipCard == id).d2Id;
        //   }
        // } else {
        //   var membershipDetails =
        //     await LoyaltyMaster.GetLoyaltyMemberShipAccountCardDetail(
        //       id
        //     );
        //   if (membershipDetails) {
        //     itemId = MembershipItems.find(
        //       (item) => item.iUseMembershipCard == id
        //     ).d2Id;
        //   }
        //   var RemoveCouponData = await Order.RemoveAllEcomCartExtrafields(itemId);
        //   }
      } else {
        debugger;
        console.log(id);
        console.log(NoOFCoupons);
        var AfterDeleteDataCoupon = AppliedCouponData1.filter(
          (item) => item.id !== id
        );
        var SundryData = await BillSundry.GetBillSundry();
        var discountId = SundryData.find((item) => item.name == "Discount")?.id;
        var discountAmtId = SundryData.find(
          (item) => item.name == "Discount Amt"
        )?.id;
        var SundryDataToSend = [];
        if (Coupon.discountType == "1") {
          SundryDataToSend = CouponApplied.filter(
            (item) => item.id == discountAmtId
          );
        } else {
          SundryDataToSend = CouponApplied.filter(
            (item) => item.id == discountId
          );
        }

        console.log(SundryDataToSend);

        var stringifydata = JSON.stringify(SundryDataToSend);
        var encodedData = encodeURIComponent(stringifydata);

        var stringifydataCoupon = JSON.stringify(AfterDeleteDataCoupon);
        var encodedDataCoupon = encodeURIComponent(stringifydataCoupon);
        const CartData = [
          {
            id: 0,
            useType: 0,
            accId: loginData.d2Id || 0,
            ex1: encodedData || "",
            ex2: encodedDataCoupon || "",
            ex3: "",
            ex4: "",
            ex5: "",
            iEx1: 0,
            iEx2: 0,
            iEx3: 0,
            nEx1: 0,
            nEx2: 0,
            nEx3: 0,
            nEx4: 0,
            nEx5: 0,
          },
        ];
        var coupon = await Order.SaveEcomCartExtrafields(CartData);
        // debugger;
        if (coupon.isSuccess) {
          setLoading(false);
          setCouponApplied(SundryDataToSend);
          setTimeout(async () => {
            // if (loginData && loginData.d2Id) {
              await GetCouponData(loginData?.d2Id || 0, InputValues, false);
              setLoading(false);
              alertbox1();
            // } else {
            //   calculateTotalPrice();
            //   setLoading(false);
            // }
          }, 100);
        } else {
          setLoading(false);
        }
      // }
    }
  };

  const close = () => {
    setOpenLogin(false);
  };

  const [visibleTables, setVisibleTables] = useState([]);

  const toggleTableVisibility = (productId) => {
    setVisibleTables((prevState) => ({
      ...prevState,
      [productId]: !prevState[productId],
    }));
  };

  const OpenAddCoupon = () => {
      setAddCoupon(!addcoupon);
  };

  useEffect(() => {
    fetchDataCart();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("InputValues", InputValues);
  }, [InputValues]);

  const [inputValuesForLogin, setInputValuesForLogin] = useState({
    name_email: "",
    password: "",
  });

  const fetchAddress = () => {
    const userLogin = localStorage.getItem("accountLogin");
    const userArray = userLogin ? JSON.parse(userLogin) : null;

    if (userArray) {
      navigate("/ShippingAddress");
    } else {
      setMyAccount(true);
      document.body.style.overflow = myAccount ? "auto" : "hidden";
      signupshow();
    }
  };

  // **************Delete******************
  const removeMembership = async (
    loginData,
    NoOFCoupons,
    IsmembershipinCartDetail
  ) => {
    debugger;
    setLoading(true);
    var NoOFCoupons = AppliedCouponData.length;
    var AllCoupondata;
    if (AllCouponsData) {
      if (AllCouponsData.length == 0) {
        AllCoupondata = await CouponMasterApi.getCouponMaster();
        setAllCouponsData(AllCoupondata);
      } else {
        AllCoupondata = AllCouponsData;
      }
    } else {
      setLoading(false);
      return;
    }
    var membershipDetails =
      await LoyaltyMaster.GetLoyaltyMemberShipAccountCardDetail(
        IsmembershipinCartDetail.useMembershipCard
      );
    if (membershipDetails) {
      var CouponData1 = AllCoupondata.find(
        (item) => item.id == membershipDetails.autoApplyCouponsList[0]
      );

      var AfterDeleteDataCoupon = AppliedCouponData.filter(
        (item) => item.id !== CouponData1.id
      );
      var SundryData = await BillSundry.GetBillSundry();
      var discountId = SundryData.find((item) => item.name == "Discount")?.id;
      var discountAmtId = SundryData.find(
        (item) => item.name == "Discount Amt"
      )?.id;
      var SundryDataToSend = [];
      if (CouponData1.discountType == "1") {
        SundryDataToSend = CouponApplied.filter(
          (item) => item.id == discountAmtId
        );
      } else {
        SundryDataToSend = CouponApplied.filter(
          (item) => item.id == discountId
        );
      }

      console.log(SundryDataToSend);

      var stringifydata = JSON.stringify(SundryDataToSend);
      var encodedData = encodeURIComponent(stringifydata);

      var stringifydataCoupon = JSON.stringify(AfterDeleteDataCoupon);
      var encodedDataCoupon = encodeURIComponent(stringifydataCoupon);
      const CartData = [
        {
          id: 0,
          useType: 0,
          accId: loginData?.d2Id || 0,
          ex1: encodedData || "",
          ex2: encodedDataCoupon || "",
          ex3: "",
          ex4: "",
          ex5: "",
          iEx1: 0,
          iEx2: 0,
          iEx3: 0,
          nEx1: 0,
          nEx2: 0,
          nEx3: 0,
          nEx4: 0,
          nEx5: 0,
        },
      ];
      var coupon = await Order.SaveEcomCartExtrafields(CartData);
      // debugger;
      if (coupon.isSuccess) {
        setLoading(false);
        setCouponApplied(SundryDataToSend);
        setTimeout(async () => {
          // if (loginData && loginData.d2Id) {
            await GetCouponData(loginData?.d2Id || 0, InputValues, false);
            setLoading(false);
          // } else {
          //   calculateTotalPrice();
          //   setLoading(false);
          // }
        }, 100);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const DeleteStation = (id, linkedItm , coatingName) => {
    debugger;
    if (linkedItm == 0 && coatingName && coatingName == "") {
      DeleteDeleteEcomCartDetail(id);
    } else {
      debugger
      DeleteDeleteEcomCartDetail(id);
      var ids = InputValues.filter((item) => item.linkedItm == coatingName);
      for (var i = 0; i < ids.length; i++) {
        DeleteDeleteEcomCartDetail(ids[i].id);
      }
    }

    alertbox();
  };
  const DeleteDeleteEcomCartDetail = async (id) => {
    
    try {
      setLoading(true);
      const val = await Order.deleteDeleteEcomCartDetail(id);
      var fetched = await fetchDataCart();
      {
        if (fetched) {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
    setLoading(true);
    var IsmembershipinCartDetail = InputValues.find(
      (item) => item.productCategory == "3"
    );
    var NoOFCoupons = AppliedCouponData.length;
    if (IsmembershipinCartDetail) {
      if (IsmembershipinCartDetail.id == id) {
        setIsMembershipCardAdded(false);
        var loginDataString = localStorage.getItem("accountLogin"); // This is a string
        var loginData = JSON.parse(loginDataString);
        // if (loginData && loginData.d2Id) {
          if (isMembershipCardAdded) {
            if (NoOFCoupons == 1) {
              var RemoveCouponData = await Order.RemoveAllEcomCartExtrafields(
                loginData?.d2Id || 0
              );
              debugger;
              if (RemoveCouponData.isSuccess == true) {
                setLoading(false);
                setCouponApplied([]);
                GetCouponData(loginData?.d2Id || 0,  InputValues, false);
              } else {
                setLoading(false);
              }
            } else {
              await removeMembership(
                loginData,
                NoOFCoupons,
                IsmembershipinCartDetail
              );
            }
          }
          else{
            setLoading(false)
          }
        // }
      } else {
        debugger;
        if (IsmembershipinCartDetail && InputValues.length == 2) {
          setLoading(false);
          var loginDataString = localStorage.getItem("accountLogin"); // This is a string
          var loginData = JSON.parse(loginDataString);
          if (loginData) {
            await removeMembership(
              loginData,
              NoOFCoupons,
              IsmembershipinCartDetail
            );
          }
        } else {
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (InputValues.length == 1) {
      var loginDataString = localStorage.getItem("accountLogin");
      var loginData = JSON.parse(loginDataString);
      var NoOFCoupons = AppliedCouponData.length;
      var IsmembershipinCartDetail = InputValues.find(
        (item) => item.productCategory == "3"
      );
      if (loginData && NoOFCoupons && IsmembershipinCartDetail) {
        removeMembership(loginData, NoOFCoupons, IsmembershipinCartDetail);
      }
    }
  }, [InputValues]);

  const [activeLink, setActiveLink] = useState("/");
  const [loading, setLoading] = useState(false);

  const handleCartIncremrnt = async (
    d2AccountId,
    d2ItemId,
    id,
    quantity,
    linkedItm,
    IsLastItem
  ) => {
    // debugger
    setLoading(true);
    // debugger; console.log(InputValues)
    try {
      // Assuming handleChange is defined elsewhere in your code
      quantity = quantity + 1;
      const updatedValues = [
        {
          operationType: 0,
          path: "Quantity",
          op: "Add",
          from: "string",
          value: quantity.toString(), // Assuming you want to increment the quantity
        },
      ];
      // const isSuccess = await Order.UpdateEcomCartPartially(updatedValues, d2AccountId, d2ItemId, id);
      const isSuccess = await Order.UpdateEcomCartPartially(updatedValues, id);

      // if (isSuccess) {
      //   const isSuccess = await Order.UpdateEcomCartPartially(
      //     updatedValues,
      //     secondItemId
      //   );
      // }

      debugger;
      // ****************** Update Lens qty with change in frame qty **********************
      // const filterData = InputValues.filter(
      //   (item) => item.linkedItm != "" && item.linkedItm == linkedItm
      // ); //for lens qty update
      // if (filterData && filterData.length > 0) {
      //   const lensItem = filterData.find(
      //     (item) =>
      //       item.lensType != "" ||
      //       item.lensType != null ||
      //       item.lensType != "NA"
      //   );
      //   if (lensItem) {
      //     const updatedValues = [
      //       {
      //         operationType: 0,
      //         path: "Quantity",
      //         op: "Add",
      //         from: "string",
      //         value: quantity.toString(),
      //       },
      //     ];
      //     const isSuccess = await Order.UpdateEcomCartPartially(
      //       updatedValues,
      //       lensItem.id
      //     );
      //   }
      // }
      if (IsLastItem) {
        fetchDataCart();
        console.log(wishList);
      } else {
        throw new Error("Error while updating cart");
      }
    } catch (error) {
      console.error("Error while updating cart:", error);
      fetchDataCart();
    } finally {
      setLoading(false);
    }
    fetchDataCart();
  };

  const handleCartDecrement = async (
    d2AccountId,
    d2ItemId,
    id,
    quantity,
    linkedItm,
    IsLastItem
  ) => {
    setLoading(true);
    try {
      if (quantity <= 1) {
        await DeleteDeleteEcomCartDetail(id);
      } else {
        quantity = quantity - 1;
        const updatedValues = [
          {
            operationType: 0,
            path: "Quantity",
            op: "Add",
            from: "string",
            value: quantity.toString(),
          },
        ];
        const isSuccess = await Order.UpdateEcomCartPartially(
          updatedValues,
          id
        );

        // if (isSuccess) {
        //   const isSuccess = await Order.UpdateEcomCartPartially(
        //     updatedValues,
        //     secondItemId
        //   );
        // }
        // ****************** Update Lens qty with change in frame qty **********************
        // const filterData = InputValues.filter(
        //   (item) => item.linkedItm != "" && item.linkedItm == linkedItm
        // ); //for lens qty update
        // if (filterData && filterData.length > 0) {
        //   const lensItem = filterData.find(
        //     (item) =>
        //       item.lensType != "" ||
        //       item.lensType != null ||
        //       item.lensType != "NA"
        //   );
        //   if (lensItem) {
        //     const updatedValues = [
        //       {
        //         operationType: 0,
        //         path: "Quantity",
        //         op: "Add",
        //         from: "string",
        //         value: quantity.toString(),
        //       },
        //     ];
        //     const isSuccess = await Order.UpdateEcomCartPartially(
        //       updatedValues,
        //       lensItem.id
        //     );
        //   }
        // }
        if (IsLastItem) {
          fetchDataCart();
        } else {
          throw new Error("Error while updating cart");
        }
      }
    } catch (error) {
      console.error("Error while updating cart:", error);
      fetchDataCart();
    } finally {
      setLoading(false);
    }
    fetchDataCart();
  };

  const [totalPrice2, setTotalPrice2] = useState(0);
  const [taxtotalPrice, setTaxTotalPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 3; // Number of items to show per page
  const itemsPerPage2 = 1; // Number of items to show per page

  const [numItemsToShow, setNumItemsToShow] = useState(4);

  // Function to update the number of items based on the screen size
  const updateNumItemsToShow = () => {
    if (window.innerWidth < 768) {
      setNumItemsToShow(2);
    } else if (window.innerWidth < 950) {
      setNumItemsToShow(3);
    } else {
      setNumItemsToShow(4);
    }
  };

  // Add event listener to update the number of items on window resize
  useEffect(() => {
    updateNumItemsToShow();
    window.addEventListener("resize", updateNumItemsToShow);
    return () => window.removeEventListener("resize", updateNumItemsToShow);
  }, []);

  console.log(InputValues);
  console.log(wishList);

  useEffect(() => {
    fetchDataWish();
  }, [currentUser]);

  const handleNext = () => {
    if (currentIndex + itemsPerPage2 < wishList.length) {
      setCurrentIndex(currentIndex + itemsPerPage2);
    }
  };

  const handlePrev = () => {
    if (currentIndex - itemsPerPage2 >= 0) {
      setCurrentIndex(currentIndex - itemsPerPage2);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const id = window.location.href;
  //       const data = await ItemMasterAPI.GetItemMasterById(id);
  //       if (data) {
  //         setItem(data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching item master data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    const getCouponMaster = async () => {
      // setLoading(true);
      if (AllCouponsData || AllCouponsData.length == 0 || coupon.length == 0) {
        var data = await CouponMasterApi.getCouponMaster();
        if (data && data.length > 0) {
          setCoupon(data);
          setAllCouponsData(data);
        } else {
          console.error("error while finding coupon data");
        }
      }
    };
    getCouponMaster();
  }, []);

  const lensNames = ["", "LENS", "LENS CORRIDOR", "LENS THICKNESS"];

  return (
    <>
      {" "}
      {InputValues.length === 0 ? (
        <div className="black-codesdf">
          <div className="p-graph-head-title">
            Your shopping cart is empty!{" "}
          </div>
          <Link to="/" className="Blank-cart-page-btn">
            <button className="pricebox-type-btn-theme3">
              Continue Shopping
            </button>
          </Link>
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <div className="cartpage-high-theme3">
            <div className="cartpage-left-theme3">
              <div className="cartpage-heading-theme3">
                Cart({totalQuantity} Items)
              </div>
              <div className="cartpage-reviewbox">
                <div className="cart-container-scroll">
                  {/* {InputValues &&
                    Object.values(
                      InputValues.reduce((acc, item) => {
                        const { linkedItm, itemPrice } = item;
                        if (!acc[linkedItm]) {
                          acc[linkedItm] = {
                            linkedItm,
                            products: [],
                            total: 0,
                          };
                        }
                        acc[linkedItm].products.push(item);
                        acc[linkedItm].total += itemPrice;
                        return acc;
                      }, {})
                    ).map((productGroup, index) => {
                      console.log("productGroup", productGroup);
                      return (
                        <div
                          className="review-box review-box-2-theme3"
                          key={index}
                        >
                          <div className="review-box-image">
                            <img
                              src={`${imageBaseUrl}${productGroup.products[0].itemImgId}`}
                              alt=""
                            />
                          </div>
                          <div className="review-box-detail">
                            <p
                              onClick={() =>
                                alertbox(
                                  productGroup.products[0].id,
                                  productGroup.linkedItm
                                )
                              }
                              className="cartpage-2-product-remove"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </p>
                            {productGroup &&
                              productGroup.products.map((item, i) => (
                                <div
                                  className={"review-box-options--theme3"}
                                  style={{ paddingTop: "5px" }}
                                >
                                  <div
                                    className={
                                      i == 0
                                        ? "heading-panel-1-theme3"
                                        : "Lens-color-cart3page"
                                    }
                                  >
                                    {lensNames[i]?.trim() != ""
                                      ? `${lensNames[i]} - ${item.itemName}`
                                      : item.itemName}
                                  </div>
                                  <div className="prize-55">
                                    {companyData.currencySymbol}{" "}
                                    {item.itemPrice}
                                  </div>
                                </div>
                              ))}

                            {productGroup.products.some(
                              (p) => p.sph_L || p.sph_R
                            ) && (
                              <div>
                                <div
                                  className="Buying-For--User"
                                  onClick={() =>
                                    toggleTableVisibility(
                                      productGroup.products[0].id
                                    )
                                  }
                                >
                                  <span>
                                    Buying for{" "}
                                    <u className="Lens-user-Mleft">
                                      Lens2cart User
                                    </u>
                                    <i
                                      className={`fa-solid fa-angle-${
                                        visibleTables ? "up" : "down"
                                      }`}
                                    ></i>
                                  </span>
                                  <span>No extra charge for high power</span>
                                </div>
                                {visibleTables[productGroup.products[0].id] && (
                                  <div className="Table-Border-Prescription">
                                    <table className="table-Main-contact-Lc scroll-table-ctclens">
                                      <thead className="Table-Header-Contactlens">
                                        <tr>
                                          <th>EYE</th>
                                          {productGroup.products.some(
                                            (p) => p.box_L
                                          ) && <th>Boxes</th>}
                                          {productGroup.products.some(
                                            (p) => p.sph_L
                                          ) && <th>SPH</th>}
                                          {productGroup.products.some(
                                            (p) => p.cyl_L
                                          ) && <th>CYL</th>}
                                          {productGroup.products.some(
                                            (p) => p.axis_L
                                          ) && <th>AXIS</th>}
                                          {productGroup.products.some(
                                            (p) => p.add_L
                                          ) && <th>READING</th>}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>L</td>
                                          {productGroup.products.some(
                                            (p) => p.box_L
                                          ) && (
                                            <td>
                                              {productGroup.products[0].box_L}
                                            </td>
                                          )}
                                          {productGroup.products.some(
                                            (p) => p.sph_L
                                          ) && (
                                            <td>
                                              {productGroup.products[0].sph_L}
                                            </td>
                                          )}
                                          {productGroup.products.some(
                                            (p) => p.cyl_L
                                          ) && (
                                            <td>
                                              {productGroup.products[0].cyl_L}
                                            </td>
                                          )}
                                          {productGroup.products.some(
                                            (p) => p.axis_L
                                          ) && (
                                            <td>
                                              {productGroup.products[0].axis_L}
                                            </td>
                                          )}
                                          {productGroup.products.some(
                                            (p) => p.add_L
                                          ) && (
                                            <td>
                                              {productGroup.products[0].add_L}
                                            </td>
                                          )}
                                        </tr>
                                        <tr>
                                          <td>R</td>
                                          {productGroup.products.some(
                                            (p) => p.box_R
                                          ) && (
                                            <td>
                                              {productGroup.products[0].box_R}
                                            </td>
                                          )}
                                          {productGroup.products.some(
                                            (p) => p.sph_R
                                          ) && (
                                            <td>
                                              {productGroup.products[0].sph_R}
                                            </td>
                                          )}
                                          {productGroup.products.some(
                                            (p) => p.cyl_R
                                          ) && (
                                            <td>
                                              {productGroup.products[0].cyl_R}
                                            </td>
                                          )}
                                          {productGroup.products.some(
                                            (p) => p.axis_R
                                          ) && (
                                            <td>
                                              {productGroup.products[0].axis_R}
                                            </td>
                                          )}
                                          {productGroup.products.some(
                                            (p) => p.add_R
                                          ) && (
                                            <td>
                                              {productGroup.products[0].add_R}
                                            </td>
                                          )}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="review-box-options--theme3">
                              <div className="Lens-color-cart3page">
                                Final Price
                              </div>
                              <div className="prize-55">
                                {companyData.currencySymbol}{" "}
                                {(productGroup.total +
                                  (productGroup.linkedItem
                                    ? productGroup.linkedItem.itemPrice
                                    : 0)) *
                                  productGroup.products[0].quantity}
                              </div>
                            </div>

                            <p
                              className="review-box-detail-line-4"
                              style={{
                                marginBottom: "3px",
                                paddingLeft: "5px",
                              }}
                            >
                              <div className="quantity">
                                <a
                                  onClick={() => {
                                    productGroup.products.forEach((product) => {
                                      handleCartDecrement(
                                        product.d2AccountId,
                                        product.d2ItemId,
                                        product.id,
                                        product.quantity,
                                        productGroup.linkedItm,
                                        index ===
                                          productGroup.products.length - 1
                                      );
                                    });
                                  }}
                                  className="quantity__minus"
                                >
                                  <span>-</span>
                                </a>
                                <input
                                  name="quantity"
                                  type="text"
                                  className="quantity__input"
                                  value={productGroup.products[0].quantity}
                                  readOnly
                                />
                                <a
                                  onClick={() => {
                                    productGroup.products.forEach((product) => {
                                      handleCartIncremrnt(
                                        product.d2AccountId,
                                        product.d2ItemId,
                                        product.id,
                                        product.quantity,
                                        productGroup.linkedItm,
                                        index ===
                                          productGroup.products.length - 1
                                      );
                                    });
                                  }}
                                  className="quantity__plus"
                                >
                                  <span>+</span>
                                </a>
                              </div>
                            </p>
                          </div>
                        </div>
                      );
                    })} */}
                  <div className="cartpage-reviewbox">
                    <div className="cart-container-scroll">
                      <CartDetailCard
                        inputValues={InputValues}
                        imageBaseUrl={imageBaseUrl}
                        lensNames={lensNames}
                        companyData={companyData}
                        toggleTableVisibility={toggleTableVisibility}
                        visibleTables={visibleTables}
                        handleCartIncrement={handleCartIncremrnt}
                        handleCartDecrement={handleCartDecrement}
                        alertbox={alertbox}
                        IsButtonShow={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cartpage-right-theme3">
              <div className="cartpage-heading-theme3">Price Summary</div>

              <div className="">
                <BillDetailCard />
                {/* {
                  "id": 7298,
                  "couponName": "Amount Off on Item/(Category/Group)",
                  "couponNameId": 2,
                  "couponMethod": "Discount Coupon Code",
                  "couponMethodId": 1,
                  "couponTitleOrCode": "U4RAFY225V1Q",
                  "discountType": "1",
                  "discountValue": 25,
                  "discountApplyFor": "Specific Groups/Category",
                  "disCategory": "[{\"Id\":583,\"Name\":\"Sunglasses\"},{\"Id\":5031,\"Name\":\"Lenses\"},{\"Id\":5733,\"Name\":\"Kids Eyeglasses\"},{\"Id\":581,\"Name\":\"Eyeglasses\"}]",
                  "applyDiscountOncePerOrder": true,
                  "allowPOSProLocation": false,
                  "minPurchaseReqType": "no-minimum",
                  "minPurchaseReqValue": 0,
                  "customerEligibility": "All customers",
                  "eligibleCustomers": null,
                  "maxDiscountUses": "false",
                  "maxDiscountUsesValue": null,
                  "couponCombination": "",
                  "startDate": "05-02-2025 00:00:00",
                  "startTime": null,
                  "setEndDate": true,
                  "endDate": "04-02-2026 00:00:00",
                  "endTime": null,
                  "isCustmrBuysMinQtyItm": true,
                  "custmrBuysMinQtyItm": 0,
                  "isCustmrSpendsMinPurAmt": false,
                  "custmrSpendsMinPurAmt": 0,
                  "custmrBuysOrSpendsFromSpecificCatgry": "Specific Groups/Category",
                  "custmrBuysOrSpendsCatgry": null,
                  "custmrGetsQty": 0,
                  "custmrGetsFromSpecificCatgry": "",
                  "custmrGetsSpecificCatgry": null,
                  "custmrGetsDiscountedOn": "",
                  "custmrGetsDiscountedValue": 0,
                  "setMaxNoOfUsesPerOrder": 0,
                  "allowFreeShipForAllCountries": true,
                  "freeShipCountries": null,
                  "shippingRates": 0
              } */}
                <CouponCard
                  alertbox1={alertbox1}
                  OpenAddCoupon={OpenAddCoupon}
                />

                <div
                  onClick={fetchAddress}
                  className={`navbar-option ${
                    activeLink === "/MyAccount" ? "active" : ""
                  }`}>
                  <button className="pricebox-type-btn-theme3">
                    Proceed To CheckOut
                  </button>
                </div>
              </div>
            </div>
            {modal && <Modal handleClick={signin} />}
            {alert && (
              <AlertBox
                alertname="Remove this item from your cart ?"
                alertbox={() => DeleteStation(DeletingId, DeletingLinkeditm , DeletingCoatingName)}
                close={() => alertbox()}
              />
            )}
            {alertForCoupon && (
              <AlertBox
                alertname="Do you really want to delete this coupon ?"
                alertbox={() =>
                  RemoveCoupon(
                    CouponEvent,
                    CouponDeletingId,
                    NoOfCoupon,
                    AppliedCouponDataForDelete,
                    CouponDataForDelete
                  )
                }
                close={() => alertbox1()}
              />
            )}
            {addcoupon && (
              <AddCouponModal
                handleClick={OpenAddCoupon}
                ApplyCoupon={ApplyCoupon}
                coupons={coupon}
                setLoading={setLoading}
                InputValues={InputValues}
                setCouponApplied={setCouponApplied}
                calculateTotalPrice={calculateTotalPrice}
                GetCouponData={GetCouponData}
                AppliedCouponData={AppliedCouponData}
                setAppliedCouponData={setAppliedCouponData}
                CouponApplied={CouponApplied}
              />
            )}
          </div>
          <div className="cartpage-theme4">
            <div className="wish-side-ihdds">
              <div>
                <h2 className="head-body-arrow">
                  Add items from your WishList
                </h2>
              </div>
              <div>
                <i
                  className="fa-solid fa-arrow-left left-right-arrow"
                  onClick={handlePrev}
                ></i>
                <i
                  className="fa-solid fa-arrow-right left-right-arrow"
                  onClick={handleNext}
                ></i>
              </div>
            </div>
            {loading && <Loader1 />}
            <div
              style={{ cursor: "pointer" }}
              className="cartpage-pricebox-container-theme3"
            >
              {wishList
                ?.slice(currentIndex, currentIndex + numItemsToShow)
                .map((product, index) => (
                  <Link
                    to={{
                      pathname: `${ProducrDetailPageURL}/${product.d2ItemId}`,
                      state: { item: product },
                    }}
                    // to={`/ProductDetailTheme/${product.d2ItemId}`}
                    state={{ item: product }}
                    key={index}
                    className="pricebox-wishlist-container"
                  >
                    <div
                      className="wishlist_background"
                      style={{
                        background: `url(${imageBaseUrl}${product.itemImgId}) no-repeat center center`,
                        backgroundSize: "cover",
                      }}
                    ></div>

                    <div className="title-name-wl-3">
                      <p>{product.itemName}</p>
                    </div>
                    <div className="price-show-wl-3">
                      <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                      {product.itemPrice}
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartPage3;
