import React from "react";

const CartDetailCard = ({
  inputValues,
  imageBaseUrl,
  lensNames,
  companyData,
  toggleTableVisibility,
  visibleTables,
  handleCartIncrement,
  handleCartDecrement,
  alertbox,
  IsButtonShow,
  Cardwidth,
}) => {
  // const groupedProducts = inputValues
  //   ? Object.values(
  //       inputValues.reduce((acc, item, index) => {
  //         debugger;
  //         let { linkedItm, itemPrice, coatingName } = item;

  //         // Treat linkedItm === 0 as a unique product by using a unique key
  //         let key =
  //           linkedItm === 0 && coatingName ? `unique_${index}` : linkedItm;

  //         let existingKey = Object.keys(acc).find((k) =>
  //           acc[k].products.some((p) => Number(p.coatingName) === linkedItm)
  //         );

  //         if (existingKey) {
  //           key = existingKey;
  //         }

  //         if (!acc[key]) {
  //           acc[key] = { linkedItm, products: [], total: 0 };
  //         }

  //         acc[key].products.push(item);
  //         acc[key].total += itemPrice;

  //         return acc;
  //       }, {})
  //     )
  //   : [];

  const groupedProducts1 = inputValues.reduce((acc, item) => {
    let { linkedItm, coatingName, itemPrice } = item;

    // If linkedItm is "0" and coatingName is unique, create a new unique item
    if (linkedItm == "0" && coatingName) {
      acc[coatingName] = {
        uniqueId: coatingName,
        products: [item],
        total: itemPrice,
        linkedItm :linkedItm,
      }; // Initialize total
    }

    // If linkedItm is not "0", find the matching unique item and add to it
    if (linkedItm != "0") {
      let uniqueKey = Object.keys(acc).find((key) => key == linkedItm);
      if (uniqueKey) {
        acc[uniqueKey].products.push(item);
        acc[uniqueKey].total += itemPrice; // Add price to total
      }
    }

    return acc;
  }, {});

  const groupedProducts = Object.values(groupedProducts1);
  console.log(groupedProducts);

  return (
    // <div className="cartpage-reviewbox">
    //   <div className="cart-container-scroll">
    <>
      {groupedProducts.map((productGroup, index) => (
        <div
          className="review-box review-box-2-theme3"
          style={{ width: Cardwidth ? Cardwidth : "" }}
          key={index}
        >
          {/* Product Image */}
          <div className="review-box-image">
            <img
              src={`${imageBaseUrl}${productGroup.products[0].itemImgId}`}
              alt=""
            />
          </div>

          {/* Product Details */}
          <div className="review-box-detail">
            {IsButtonShow && (
              <p
                onClick={() =>
                  alertbox(productGroup.products[0].id, productGroup.linkedItm , productGroup.uniqueId)
                }
                className="cartpage-2-product-remove"
              >
                <i className="fa-regular fa-trash-can"></i>
              </p>
            )}

            {productGroup.products.map((item, i) => (
              <div
                key={i}
                className={"review-box-options--theme3"}
                style={{ paddingTop: "5px" }}
              >
                <div
                  className={
                    i === 0 ? "heading-panel-1-theme3" : "Lens-color-cart3page"
                  }
                >
                  {`${
                    item?.parentGrpName ? item.parentGrpName.toUpperCase() : ""
                  } - ${item?.itemName || ""}`}

                </div>
                <div className="prize-55 prize-50">
                  {companyData.currencySymbol} {item.itemPrice}
                </div>
              </div>
            ))}

            {/* Prescription Table */}
            {productGroup.products.some((p) => p.sph_L || p.sph_R) && (
              <div>
                <div
                  className="Buying-For--User"
                  onClick={() =>
                    toggleTableVisibility(productGroup.products[0].id)
                  }
                >
                  <span>
                    Buying for <u className="Lens-user-Mleft">Lens2cart User</u>
                    <i
                      className={`fa-solid fa-angle-${
                        visibleTables ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                  <span>No extra charge for high power</span>
                </div>

                {visibleTables[productGroup.products[0].id] && (
                  <div className="Table-Border-Prescription">
                    <table className="table-Main-contact-Lc scroll-table-ctclens">
                      <thead className="Table-Header-Contactlens">
                        <tr>
                          <th>EYE</th>
                          {["box_L", "sph_L", "cyl_L", "axis_L", "add_L"].map(
                            (key) =>
                              productGroup.products.some((p) => p[key]) && (
                                <th key={key}>{key.toUpperCase()}</th>
                              )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {["L", "R"].map((side) => (
                          <tr key={side}>
                            <td>{side}</td>
                            {["box", "sph", "cyl", "axis", "add"].map(
                              (key) =>
                                productGroup.products.some(
                                  (p) => p[`${key}_${side}`]
                                ) && (
                                  <td key={key}>
                                    {productGroup.products[0][`${key}_${side}`]}
                                  </td>
                                )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}

            {/* Final Price */}
            <div className="review-box-options--theme3">
              <div className="Lens-color-cart3page">Final Price</div>
              <div className="prize-55 prize-50">
                {companyData.currencySymbol}{" "}
                {(productGroup.total +
                  (productGroup.linkedItem
                    ? productGroup.linkedItem.itemPrice
                    : 0)) *
                  productGroup.products[0].quantity}
              </div>
            </div>

            {/* Quantity Selector */}
            {IsButtonShow && (
              <p
                className="review-box-detail-line-4"
                style={{ marginBottom: "3px", paddingLeft: "5px" }}
              >
                <div className="quantity">
                  <a
                    onClick={() => {
                      productGroup.products.forEach((product, i) => {
                        handleCartDecrement(
                          product.d2AccountId,
                          product.d2ItemId,
                          product.id,
                          product.quantity,
                          productGroup.linkedItm,
                          i === productGroup.products.length - 1
                        );
                      });
                    }}
                    className="quantity__minus"
                  >
                    <span>-</span>
                  </a>
                  <input
                    name="quantity"
                    type="text"
                    className="quantity__input"
                    value={productGroup.products[0].quantity}
                    readOnly
                  />
                  <a
                    onClick={() => {
                      productGroup.products.forEach((product, i) => {
                        handleCartIncrement(
                          product.d2AccountId,
                          product.d2ItemId,
                          product.id,
                          product.quantity,
                          productGroup.linkedItm,
                          i === productGroup.products.length - 1
                        );
                      });
                    }}
                    className="quantity__plus"
                  >
                    <span>+</span>
                  </a>
                </div>
              </p>
            )}
          </div>
        </div>
      ))}
      {/* </div>
    </div> */}
    </>
  );
};

export default CartDetailCard;
