import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertBox from '../../Component/AlertBox/AlertBox.jsx';
import { useContext } from 'react';
import MyContext from '../../MyContext.jsx';

const NabarAccountModel = () => {

  const { setIsMembershipDetails , setUserData , setInputValues, setWishList,} = useContext(MyContext)

  const navigate = useNavigate();

  const [alert, setAlert] = useState(false);
  const alertbox = () => {
    setAlert(!alert)
  }

  const logout = () => {
    localStorage.removeItem('accountLogin');
    localStorage.removeItem('MembershipDetails');
    setUserData(null);
    setIsMembershipDetails(0)
    setInputValues([])
    setWishList([])
    navigate("/");
    window.location.reload();

    // window.location.reload();
  };

  const handleOptionClick = (tabName) => {
    debugger
    navigate("/admin", { state: { tabName } });
  };


  return (
    <div className="Navbar-Account-nav">
      <div className="model-content-nav">
        <div className="menu-item-nav" onClick={() => handleOptionClick("My Profile")}>Account Info</div>
      <div className="menu-item-nav" onClick={() => handleOptionClick("My Order")}>My Orders</div>
        <div className="menu-item-nav" onClick={() => handleOptionClick("My Address")}>My Address</div>
        <div className="menu-item-nav" onClick={() => handleOptionClick("My Favourite")}>My Wishlist</div>
        <div className="menu-item-nav" onClick={() => handleOptionClick("My Prescriptions")}>My Prescription</div>
        <div onClick={alertbox} className="menu-item-nav">Logout</div>
        {/* <div className="logout-button">Logout</div> */}
      </div>
      {alert && (
        <AlertBox alertname="Are You Sure You Want to logout" alertbox={logout} close={alertbox} />
      )}
    </div>
  )
}

export default NabarAccountModel
