import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MyContext from "../../MyContext";

const ReletedProductsShow = (props) => {
  const { apiData, itemsPerPageRelated } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [apiDataFeature, setapiDataFeature] = useState([]);

  const { imageBaseUrl, ProducrDetailPageURL } = useContext(MyContext);

  const handleNext = () => {
    if (currentIndex + itemsPerPageRelated < apiData.length) {
      setCurrentIndex(currentIndex + itemsPerPageRelated);
    }
  };

  const handlePrev = () => {
    if (currentIndex - itemsPerPageRelated >= 0) {
      setCurrentIndex(currentIndex - itemsPerPageRelated);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 750px)");

    const handleResize = (e) => {
      if (e.matches) {
        setItemsPerPage(4);
      } else {
        setItemsPerPage(5);
      }
    };

    // Set the initial value
    handleResize(mediaQuery);

    // Add event listener
    mediaQuery.addEventListener("change", handleResize);

    // Clean up event listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  const featuredata = async (id) => {
    const data = apiData.result
      .filter((item) => item.itemId === id)
      .map((item) => item.addFeatures)
      .flat();

    console.log("Featuredata: ", data);
    setapiDataFeature(data);
    return data.length > 0;
  };

  return (
    <div className="cartpage-theme4">
      {apiData?.length >= 0 && (
        <div className="wish-side-ihdds2">
          <div>
            <h2 className="head-body-arrow">Related Product</h2>
          </div>
          <div>
            <i
              className="fa-solid fa-arrow-left left-right-arrow"
              onClick={handlePrev}
              disabled={currentIndex === 0}
            ></i>
            <i
              className="fa-solid fa-arrow-right left-right-arrow"
              onClick={handleNext}
              disabled={currentIndex + itemsPerPageRelated >= apiData.length}
            ></i>
          </div>
        </div>
      )}
      <div
        style={{ cursor: "pointer" }}
        className="cartpage-pricebox-container-theme3-pt"
      >
        {apiData &&
          apiData
            .slice(currentIndex, currentIndex + itemsPerPageRelated)
            .map((product, index) => (
              <Link
                to={`${ProducrDetailPageURL}/${product.selectedValue}`}
                state={{ item: product }}
                key={index}
                className="pricebox-wishlist-container"
              >
                {/* <div key={index} className="pricebox-wishlist-container"> */}
                <div
                  className="wishlist_background"
                  style={{
                    background: `url(${imageBaseUrl}${product.selectedImageId[0]}) no-repeat center center`,
                    backgroundSize: "cover",
                  }}
                ></div>
                <div className="title-name-wl-3">
                  <p>{product.selectedItemName}</p>
                </div>
                <div className="price-show-wl-3">
                  <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                  {product.salePrice}
                </div>
                {/* </div> */}
              </Link>
            ))}
      </div>
    </div>
  );
};

export default ReletedProductsShow;
