/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
// Ensure that the index.css is imported globally
import BookAppoientment from "./BookAppoientment/BookAppoientment";
import "../../Css/Store.scss";
import Userintersection from "./hook/Userintersection";



function Card({ store, setselectedmap }) {
  const [modal, setmodal] = useState(false)
  const [ref, isVisible] = Userintersection(handle, {
    root: null, // Use the viewport as the root
    rootMargin: "0px",
    threshold: 0.5, // Trigger when 50% of the component is visible
  });

  function handle() {
    console.log("callback", isVisible)
  }

  useEffect(() => {
    if (isVisible) {
      console.log(store)
      setselectedmap(store?.name)
    }
  }, [isVisible])

  const handleappoientment = () => {
    setmodal(true);
  }

  const handlemapview = () => {
    setselectedmap(store.name)
  }
  return (

    <div ref={ref} className="store-container">
      <div onClick={() => handlemapview()} className="store-content">
        <div className="store-image-container">
          <img src={store.image} alt="Property" className="store-image" />
          <div className={`opennow-label`}>Open Now</div>
        </div>

        <div className="store-details">
          <div className="store-header">
            <div className="store-title">
              <h4 className="store-name">
                <a href="#" className="store-name-link">
                  {store.name}
                </a>
              </h4>
              <div className="store-rating">
                <div className="store-rating-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="store-rating-star"
                  >
                    <path d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73-1.64 7.03L12 17.27z" />
                  </svg>
                </div>
                <div className="store-rating-text">
                  <div className="store-rating-value">{store.rating}</div>
                  <div className="store-reviews">({store.reviews})</div>
                </div>
              </div>
            </div>
            <div className="store-address">{store.address}</div>
            <div className="store-hours">Hours: {store.hours}</div>
            {/* <div className="store-contact">
              <img src="https://www.lenskart.com/stores/svg/phone.svg" alt="call" className="store-phone-icon" />
              <div className="store-phone-number">
                <a href={`tel:${store.phone}`}>{store.phone}</a>
              </div>
            </div> */}
          </div>
          <div className="store-buttons">
            {/* <button className="store-details-button"> */}
              <a href={`/Storehome/${store.name}`} className="store-details-link store-details-button">
                Store Details
              </a>
            {/* </button> */}
            <button onClick={handleappoientment} className="store-appointment-button">
              <a href="#_" className="store-appointment-link">
                Book an Appointment
              </a>
            </button>
          </div>
          <div className="store-offers">
            <div className="offer-item">Free Lens Cleaning Solution</div>
            <div className="offer-item">• Free Eye Test</div>
            <div className="offer-item">• Free Repair</div>
          </div>
        </div>
      </div>

      {modal ? <BookAppoientment modal={modal} setmodal={setmodal} /> : null}
    </div>

  );
}

export default Card;
