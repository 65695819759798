import React, { useState } from 'react'

const CustomerReview = () => {
    const [activeVideo, setActiveVideo] = useState(null);

    const videos = [
        "7nUyehZ0xvM?si=eQVyCftbwc1CXsJU",
        "ExDCXACJzjw?si=NpY_AMh-jzji82LR",
        "-5UiNw1IzYU?si=uVqjyUCQcmVuZ-1x",
    ];
    
    const handlePlay = (videoId) => {
        if (activeVideo && activeVideo !== videoId) {
          document.getElementById(activeVideo).contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            "*"
          );
        }
        setActiveVideo(videoId);
      };

    return (
        <div>
            <div style={{ background: "#fff092" }}>
                <div className='customer-title-1'>
                    <h3 className='customer-main-title'>VIDEOS</h3>
                    <div className='link-youtube-frame'>
                        {/* <div className="customer-link-detail">
                            <iframe className="frame-yt-video " src="https://www.youtube.com/embed/7nUyehZ0xvM?si=eQVyCftbwc1CXsJU" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div className="customer-link-detail">
                            <iframe className="frame-yt-video " src="https://www.youtube.com/embed/ExDCXACJzjw?si=NpY_AMh-jzji82LR" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div className="customer-link-detail">
                            <iframe className="frame-yt-video " src="https://www.youtube.com/embed/-5UiNw1IzYU?si=uVqjyUCQcmVuZ-1x" frameborder="0" allowfullscreen></iframe>
                        </div> */}

                        {videos.map((video, index) => (
                            <div className="customer-link-detail" key={index}>
                                <iframe
                                    id={`video-${index}`}
                                    className="frame-yt-video"
                                    src={`https://www.youtube.com/embed/${video}&enablejsapi=1`}
                                    frameBorder="0"
                                    allowFullScreen
                                    onLoad={() => {
                                        document
                                            .getElementById(`video-${index}`)
                                            .contentWindow.postMessage(
                                                '{"event":"command","func":"pauseVideo","args":""}',
                                                "*"
                                            );
                                    }}
                                    onClick={() => handlePlay(`video-${index}`)}
                                ></iframe>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerReview