import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import MyContext from '../../MyContext';
import Account from "../../../API/API_SERVISES/Account";
import useDebounce from "../useDebounce";
import { Link } from "react-router-dom";

const SearchPageMobile = (props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const { currentUser,
    imageBaseUrl, setClose, ProducrDetailPageURL } = useContext(MyContext);
  var isSearch = true;
  const [Data, setData] = useState([]);
  const [selected, setselected] = useState(null);
  const [searchValue, setSearchValue] = useState([]);
  const navigate = useNavigate();

  console.log(Data);

  const searchValueFunction = async (value) => {
    if (currentUser === true) {
      try {
        const filteredData1 = {
          MasterType: 4,
          GroupWiseSuggestion: false,
          GroupId: "",
          search_str: value,
          PageNumber: 1,
          PageSize: 15,
          IsEcomStore: false,
        };

        const filteredData = {
          MasterType: 3,
          GroupWiseSuggestion: false,
          GroupId: "",
          search_str: value,
          PageNumber: 1,
          PageSize: 5,
          IsEcomStore: false,
        };
        const searchItem1 = await Account.GetAccountListForAutoSuggestion(
          filteredData
        );
        const searchItem2 = await Account.GetAccountListForAutoSuggestion(
          filteredData1
        );

        // Append `isGroup: true` to all objects in searchItem1
        const modifiedSearchItem1 =
          searchItem1?.map((item) => ({ ...item, isGroup: true })) || [];

        // Append `isGroup: false` to all objects in searchItem2
        const modifiedSearchItem2 =
          searchItem2?.map((item) => ({ ...item, isGroup: false })) || [];

        setData([...modifiedSearchItem1, ...modifiedSearchItem2]);
      } catch (error) {
        console.error("Error in searchValueFunction:", error);
      }



    }
  };

  // useEffect(() => {
  //   searchValueFunction()
  // }, [currentUser])

  const Debounsedvalue = useDebounce(searchValue, 800)

  useEffect(() => {
    if (Debounsedvalue && Debounsedvalue != "") {
      searchValueFunction(Debounsedvalue)
    }
  }, [Debounsedvalue])


  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue); // Update the input state immediately
    // searchValueFunction(newValue); // Call the debounced search function
  };

  const closeSuggestion = () => {
    setTimeout(() => {
      setClose(false);
    }, 100); // 1000 milliseconds = 1 second
  }

  const openSuggestion = () => {
    setClose(true)
  }

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.target.blur(); // This will blur the input element
      props.close()
      navigate("/productsearch", { state: { searchValue: searchValue } });
    }
  };


  useEffect(() => {
    const handleKeyDown = (e) => {
      // Handle ArrowDown key
      if (e.key === 'ArrowDown') {
        if (selected === null) {
          setselected(0);
          setClose(true)
          Data.length !== 0 && setSearchValue(Data[0].name);
        } else {
          if (selected < Data.slice(0, 15).length - 1) {
            setClose(true)
            setSearchValue(Data[selected + 1].name);
            setselected(selected + 1);
          }
        }
      }

      // Handle ArrowUp key
      if (e.key === 'ArrowUp') {
        setClose(true)
        if (selected > 0) {
          setClose(true)
          setSearchValue(Data[selected - 1].name);
          setselected(selected - 1);
        }
      }

      // Handle Enter key
      if (e.key === 'Enter') {
        setClose(true)
        if (selected !== null && Data[selected]) {
          // Navigate to the selected item's page
          if (Data[selected].isGroup == true) {
            props.setSearchValue("");
            navigate(`/productsearch?productId=${Data[selected].d1RefId}`);
          } else {
            props.setSearchValue(Data[selected].name);
            navigate(`${ProducrDetailPageURL}/${Data[selected].id}`, {
              state: { isSearch },
            });
          }
          setClose(false); // Close the suggestion box
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selected, Data, navigate]);


  function setsearchvalue(item) {
    setClose(false);
    props.close()
    // props.setSearchValue(item.name);
  }

  return (
    <div className="mobile-filter-page">
      <i onClick={props.close} className="search-back-button fa-solid fa-arrow-left-long"></i>
      <div className='searchpanel-input-box' >
        <input className='search-input-searchpanel' type="text" placeholder='What are you looking for ?' ref={inputRef}
          value={searchValue}
          onBlur={closeSuggestion}
          onChange={(e) => {
            handleInputChange(e)
          }}
          onFocus={openSuggestion}
          onKeyDown={handleKeyDown} />
      </div>
      <div className='search-options-panal'>
        {/* <p className='search-options-panal-txt'>Search</p> */}
        <div className='search-options-panal-recent-box'>
          {Data?.slice(0, 15).map((item, index) => {
            return (
              <Link 
              to={`${
                item.isGroup
                  ? `/productsearch?productId=${item.d1RefId}`
                  : `${ProducrDetailPageURL}/${item.id}`
              }`}
              state={{ isSearch }}
              key={item.d1Id} className='search-options-panal-recent-1' 
              style={{ backgroundColor: `${selected === index ? "#ccc" : "white"}`,
              fontWeight: `${item.isGroup ? "600" : "300"}` }}
              onClick={() => setsearchvalue(item)}
              >
                  <i className="fa-regular fa-clock"></i>
                <div style={{width:"82%"}}>
                  {item.name}
                </div>
                {item.imageId ? (
                  <img style={{ width: "30px", height: "30px", margin: "0px 10px 0px 5px" }} src={`${imageBaseUrl}${item.imageId}`} alt="" />
                ) : (
                  <div style={{ width: "30px", height: "30px", margin: "0px 10px 0px 5px" }}> </div>
                )}</Link>
            )
          })}

        </div>
        {/* <p className='search-options-panal-txt'>Tranding on our shop</p>
        <div className='search-options-panal-recent-box'>
          <div className='search-options-panal-recent-1'>  Eyeglasses</div>
          <div className='search-options-panal-recent-1'> Sunglasses</div>
          <div className='search-options-panal-recent-1'>  lens</div>
        </div> */}
      </div>

    </div>
  )
}

export default SearchPageMobile
