// SideBar1.js
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";

import logo from "../../Assets/Icons/logo.png";

import icon from "../../Assets/Icons/home.png";
import icon1 from "../../Assets/Icons/glasses.png";
import icon2 from "../../Assets/Icons/sunglasses.png";
import icon3 from "../../Assets/Icons/contact-lens.png";
import icon4 from "../../Assets/Icons/glasses (1).png";
import icon5 from "../../Assets/Icons/light-eyeglasses-.png";
import icon6 from "../../Assets/Icons/clipboard.png";
import icon7 from "../../Assets/Icons/support.png";
import icon8 from "../../Assets/Icons/shopping-cart (1).png";
import icon9 from "../../Assets/Icons/facebook-app-symbol.png";
import icon10 from "../../Assets/Icons/instagram.png";
import icon11 from "../../Assets/Icons/youtube.png";
import MyContext from "../../MyContext";
import Appointment from "../../Pages/AppointmentPage/Appointment";
import Storelocator from "../StoreLocator/Storelocator/Storelocator";

const SidebarMobile_Lc = ({ showSidebar, handleClick }) => {

    const { myaccountmodal, userArray, modal, setModal, setLoginPanel, totalQuantity, productlist, setProductList, currentUser,
        wishLisHandle, setwishLisHandle, logoCompanyUrl, companyData, setCompanyData, imageBaseUrl, itemGroupData, homeEyeCheckUpData } = useContext(MyContext);

    const [CategoryData, setCategoryData] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [parentId, setParentId] = useState();
    const [LcNavbar, setLcNavbar] = useState(true);


    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // const toggleDropdown = () => {
    //     setIsDropdownOpen(!isDropdownOpen);
    // };

    const sidebarRef = useRef(null);
    const [activeCategoryId, setActiveCategoryId] = useState(null);

    const toggleDropdown = (categoryId) => {
        setActiveCategoryId((prevCategoryId) =>
            prevCategoryId === categoryId ? null : categoryId
        );
        if (activeCategoryId !== categoryId) {
            setActiveSubCategoryId(null);
        }

    };

    const [activeSubCategoryId, setActiveSubCategoryId] = useState(null);

    const toggleDropdownSubCat = (subCategoryId) => {
        setActiveSubCategoryId((prevSubCategoryId) =>
            prevSubCategoryId === subCategoryId ? null : subCategoryId
        );
    };


    // Handle clicks outside of the sidebar
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setActiveCategoryId(null);
                setActiveSubCategoryId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const initialClass = "sidebar-1";
    const additionalClass = showSidebar ? "show" : "";
    const initialClass1 = "";
    const additionalClass1 = showSidebar ? "sidebarback" : "";

    // Concatenate the classes with a space
    const combinedClasses = `${initialClass} ${additionalClass}`.trim();
    const combinedClasses1 = `${initialClass1} ${additionalClass1}`.trim();

    useEffect(() => {
        document.body.style.overflow = showSidebar ? "hidden" : "auto";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [showSidebar]);

    const fetchData = () => {
        // debugger
        if (currentUser === true) {

            try {
                // const categoryName = await ItemGroupAPI.GetItemGroup();
                const categoryName = itemGroupData;
                // console.log(categoryName);
                const categoryData = categoryName.filter(item => item.imageShowOnError == 1).slice().sort((a, b) => a.displayOrder - b.displayOrder)
                setCategoryData(categoryData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        }
        setLoading(false);
    };
    useEffect(() => {
        // setLoading(true);
        fetchData();
    }, [currentUser, itemGroupData]);


    const openNavbarLc = async (d1_Id) => {
        setParentId(d1_Id)
        setLcNavbar(true);
    };

    const closeNavbarLc = async () => {
        setLcNavbar(false);
        // alert("close")
    };

    const validEyeTestNames = ["Home Eye-Test", "Eye Test", "Eye Checkup", "Eye", "Home Eye Test", "HOME EYE TEST"];

    const validStoreLocator = ["Store Locator", "Location", "Store", "Locator", "STORE LOCATOR"]


    return (
        <div ref={sidebarRef} onClick={handleClick} className={combinedClasses1}>
            <div className={combinedClasses}>
                <Link onClick={handleClick} to="/" className="sidebar-1-logo">
                    {/* <img className="logo-sidebar-1" src={logo} alt="" /> */}
                    <img className="logo-sidebar-1" src={`${imageBaseUrl}${companyData.companyImageId}`} alt="" />
                </Link>

                <div onClick={handleClick} className="sidebar-1-button-box">
                    <Link onClick={handleClick} to="/" className="sidebar-1-btn">
                        {" "}
                        <img className="sidebar-1-icon" src={icon} alt="" />
                        Home
                    </Link>
                    <div className={`sidebar ${showSidebar ? 'show' : 'hide'}`}>
                        {CategoryData.filter(
                            (category1) => category1.primaryGroup === true && category1.showInMenu === true && category1.imageShowOnError == 1
                        )
                            .slice().sort((a, b) => a.displayOrder - b.displayOrder)
                            .map((category) => {
                                // Determine if the category has subcategories
                                const hasSubcategories = CategoryData.some(
                                    (item) => item.primaryGroup === false && item.showInMenu === true && item.parentGrp === category.d1_Id
                                );

                                return (
                                    <div key={category.id}>

                                        <div className="sidebar-1-btn"
                                            onMouseLeave={closeNavbarLc}
                                            onMouseEnter={() => openNavbarLc(category.d1_Id)}
                                        >
                                            <img className="sidebar-1-icon" src={`${imageBaseUrl}${category.images[0]}`} alt="" />
                                            {["contact lens", "contact lenses"].includes(category.menuName.toLowerCase()) ? (
                                                <Link to={`/ContactLenseCart?productId=${category.d2_Id}`} className="category-side-mobile">
                                                    {category.menuName}
                                                </Link>
                                            ) : (["home eye-test", "eye test", "eye checkup", "eye", "home eye test"].includes(category.menuName.toLowerCase()) ? (
                                                <Link to="/ComingSoon" className="category-side-mobile">
                                                    {/* bookappointment */}
                                                    {category.menuName}
                                                </Link>
                                            ) : (["store locator", "location", "store", "locator", "store locator"].includes(category.menuName.toLowerCase()) ? (
                                                <Link to="/Storelocator" className="category-side-mobile">
                                                    {category.menuName}
                                                </Link>
                                            ) : (
                                                <Link to={`/productsearch?productId=${category.d2_Id}`} className="category-side-mobile">
                                                    {category.menuName}
                                                </Link>
                                            )))}
                                            {hasSubcategories && (
                                                // <div className="Arrow-up-down-sidebar" onClick={() => toggleDropdown(category.d1_Id)}>
                                                <div className="Arrow-up-down-sidebar" onClick={(e) => {
                                                    e.stopPropagation(); toggleDropdown(category.d1_Id);
                                                }}
                                                >
                                                    <i className={`fa-solid fa-caret-${activeCategoryId === category.d1_Id ? 'up' : 'down'}`}></i>
                                                </div>
                                            )}
                                        </div>

                                        {activeCategoryId === category.d1_Id && (
                                            <div className="dropdown-sidebar-mobile">
                                                {/* {parentId === 5008 ? (
                                                    homeEyeCheckUpData.eyeCheckUpInHome ? (
                                                        <div style={{ display: "flex" }}>
                                                            <Appointment closeNavbarLc={closeNavbarLc} />
                                                        </div>
                                                    ) : (
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <h2>Sorry Home Eye Test Is Currently Unavailable</h2>
                                                        </div>
                                                    )
                                                ) */}
                                                {CategoryData && CategoryData.find(item => item.d1_Id === parentId)?.name &&
                                                    validEyeTestNames.includes(CategoryData.find(item => item.d1_Id === parentId).name)
                                                    ? (homeEyeCheckUpData.eyeCheckUpInHome === true ?
                                                        (
                                                            <div style={{ display: "flex" }}>
                                                                <Link to="/bookappointment">
                                                                    <Appointment closeNavbarLc={closeNavbarLc} />
                                                                </Link>
                                                            </div>
                                                        ) : (
                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <h2>Sorry Home Eye Test Is Currently Unavailable</h2>
                                                            </div>
                                                        )
                                                    )
                                                    : validStoreLocator.includes(CategoryData.find(item => item.d1_Id === parentId)?.name)
                                                        ? (
                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                {/* <h2>Store Locator Is Available</h2> */}
                                                                <Storelocator />
                                                                {/* You can also insert your Store Locator component here */}
                                                            </div>
                                                        )
                                                        : (
                                                            CategoryData
                                                                .filter(item => item.primaryGroup === false && item.showInMenu === true && item.parentGrp === parentId)
                                                                .map((item, index) => (
                                                                    <div>
                                                                        <div className="sidebar-1-btn" key={index}>
                                                                            <h3 className="Flex-subcat-mobile-Lc">
                                                                                <Link to={`/productsearch?productId=${item.d2_Id}`} className="Submenu-color-home">{item.menuName}</Link>
                                                                            </h3>
                                                                            {CategoryData.some(
                                                                                (subItem) =>
                                                                                    subItem.parentGrp === item.d1_Id &&
                                                                                    subItem.showInMenu === true
                                                                            ) && (
                                                                                    // <div className="Arrow-up-down-sidebar" onClick={() => toggleDropdownSubCat(item.d1_Id)}>
                                                                                    <div className="Arrow-up-down-sidebar" onClick={(e) => { e.stopPropagation(); toggleDropdownSubCat(item.d1_Id); }}>
                                                                                        <i className={`fa-solid fa-caret-${activeSubCategoryId === item.d1_Id ? 'up' : 'down'}`}></i>
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                        {activeSubCategoryId === item.d1_Id && (
                                                                            <div className="dropdown-sidebar-mobile">
                                                                                {CategoryData
                                                                                    .filter(subItem => subItem.parentGrp === item.d1_Id && subItem.showInMenu === true)
                                                                                    .map((subItem, subIndex) => (
                                                                                        <div className="sidebar-1-btn">
                                                                                            <Link to={`/productsearch?productId=${subItem.d2_Id}`} className="" >
                                                                                                <div className="navbar-onhover-left-Panel-child">
                                                                                                    {subItem.images && subItem.images[0] ? (
                                                                                                        <div className="Font-image-nav-Lc">
                                                                                                            <img className=""
                                                                                                                src={`${imageBaseUrl}${subItem.images[0]}`}
                                                                                                                alt="" />
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div style={{ display: "none" }}>
                                                                                                            <img className="" src="" alt="" />
                                                                                                        </div>
                                                                                                    )}
                                                                                                    <div className="{`theme-3-onhover-heading-2}`} Subitem-Color-Lc">{subItem.menuName}</div>
                                                                                                </div>
                                                                                            </Link>
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ))
                                                        )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </div>

                    <Link onClick={handleClick} to="/OrderBox_Lc" className="sidebar-1-btn">
                        {" "}
                        <img className="sidebar-1-icon" src={icon6} alt="" /> My Order
                    </Link>
                    <Link onClick={handleClick} to="/contactpage" className="sidebar-1-btn">
                        {" "}
                        <img className="sidebar-1-icon" src={icon7} alt="" /> Contact Us
                    </Link>

                    <Link onClick={handleClick} to="/Cart3" className="sidebar-1-btn">
                        {" "}
                        <img className="sidebar-1-icon" src={icon8} alt="" />
                        CART
                    </Link>
                    {/* <Link href="https://www.facebook.com/people/Lens2cart/61569104277658/"  className="sidebar-1-btn">
                        {" "}
                        <img className="sidebar-1-icon" src={icon9} alt="" />
                        FACEBOOK
                    </Link> */}

                    <a className="sidebar-1-btn" href="https://www.facebook.com/people/Lens2cart/61569104277658/">
                        <img className="sidebar-1-icon" src={icon9} alt="Facebook Icon" />
                        FACEBOOK
                    </a>
                    <a href="https://www.instagram.com/lens2cart_kenya/" className="sidebar-1-btn">
                        {" "}
                        <img className="sidebar-1-icon" src={icon10} alt="" />
                        INSTAGRAM
                    </a>
                    <a href="https://www.youtube.com/embed/ExDCXACJzjw?si=NpY_AMh-jzji82LR" className="sidebar-1-btn">
                        {" "}
                        <img className="sidebar-1-icon" src={icon11} alt="" />
                        YOUTUBE
                    </a>
                </div>
                {/* {LcNavbar && (
                    <SidebarMobileOnClick_Lc
                        CategoryData={CategoryData}
                        openNavbarLc={openNavbarLc}
                        closeNavbarLc={closeNavbarLc}
                        parentId={parentId}
                    />
                )} */}
            </div>
        </div>
    );
};

export default SidebarMobile_Lc;
