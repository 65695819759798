import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Navigation, Pagination, Autoplay } from 'swiper/modules'; // Import Autoplay module
import 'swiper/css/navigation';
import 'swiper/css/pagination'; // Import pagination styles
import person1 from "../../Assets/Image_lc/ad-card/Paul Kamau.webp"
import person2 from "../../Assets/Image_lc/ad-card/Mary Wanjiru.webp"
import person3 from "../../Assets/Image_lc/ad-card/Brian Mwangi.webp"

const Testimonial = () => {
    return (
        <section className="Testimonialcontainer">
            <Swiper
                slidesPerView={1}
                grabCursor={true}
                loop={true}
                pagination={true} // Enable pagination
                navigation={true} // Enable navigation
                modules={[Pagination, Navigation, Autoplay]} // Register modules
                autoplay={{ // Configure autoplay
                    delay: 3000, // 3 seconds delay
                    disableOnInteraction: false, // Autoplay continues after user interactions
                }}
                className="testimonial mySwiper"
            >
                <SwiperSlide className="slide">
                    <img src={person1} alt="" className="image" />
                    <p>
                    Yo, my people, let me put you on some real drip I copped my specs from Lens2Cart, and wueh! 
                    These guys don’t play when it comes to fashion and class.I got the Fashionista Classic, and let me tell you, 
                    I’m looking like a whole CEO!
                    The frames? Solid, stylish, and lightweight – no stress on my face. The fit? Perfectly snug like it was made just for me. 
                    And the best part? The price is super friendly, but the quality is straight-up premium! No plastic vibes here – just pure elegance.
                    </p>
                
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Paul Kamau</span>
                        <span className="job">Kenya</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                    <img src={person2} alt="" className="image" />
                    <p>
                    Their service was top-tier, no long stories. Ordered my specs, and before I knew it, they landed.
                    The frames? Mad stylish, super light, and comfy for all-day wear. The quality? A1,
                    way better than I expected for the price. Been getting compliments left, right,
                    and center—everyone wants to know where I copped them. Lens2Cart is the real plug if you want trendy,
                    durable, and affordable eyewear.
                    </p>
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Mary Wanjiru</span>
                        <span className="job">Kenya</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                    <img src={person3} alt="" className="image" />
                    <p className='test-monial'>
                    Aii boss, if you ain't rocking Fashionista Sunglasses from Lens2Cart, are you even outside? 
                    These shades are a whole vibe – sleek, stylish, and screaming "big man ting" on another level
                    Delivery? Super fast, no long waits. Ordered, and boom! My sunglasses were at my doorstep, ready for the streets. 
                    Whether you’re rolling in a Benz or just chilling at Java, these shades will have you looking like a whole celebrity. 

                    </p>
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Brian Mwangi</span>
                        <span className="job">Kenya</span>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    );
}

export default Testimonial;
