import React, { useContext, useEffect, useRef, useState } from "react";
import paymentDone from "../Assets/Images/order.png";
import paymentFail from "../Assets/Images/accept.png";
import PayStack from "../../API/API_SERVISES/PayStack";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Transaction from "../../API/API_SERVISES/Transaction";
import Order from "../../API/API_SERVISES/Order";
import Payment from "../../API/API_SERVISES/Payment";
import CurrentDateAndTime from "../../API/API_SERVISES/CurrentDateAndTime";
import AddAddress from "../../API/API_SERVISES/AddAddress";
import BillSeriesMaster from "../../API/API_SERVISES/BillSeriesMaster";
import BillType from "../../API/API_SERVISES/BillType";
import BillPaymentMode from "../../API/API_SERVISES/BillPaymentMode";
import ReportDesignForA4 from "./ReportDesignForA4";
import MyContext from "../MyContext";
import Loader1 from "./Loaders/Loader1";
import Account from "../../API/API_SERVISES/Account";

const PaymentResponse = () => {
  const [orderdetails, setOrderDetails] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const paymentDetails = location?.state?.RazorpayPaymentDetails || 0;
  const searchParams = new URLSearchParams(location.search);
  const paymentTypeId = searchParams.get("gateWayId");

  const {
    companyData,
    setTotalQuantity,
    setInputValues,
    currentUser,
    setIsMembershipDetails,
  } = useContext(MyContext);
  useEffect(() => {
    // "orderDetails": {
    //     "id": "order_Q6EpDchBY497ye",
    //     "entity": "order",
    //     "amount": 10000,
    //     "amount_paid": 10000,
    //     "amount_due": 0,
    //     "currency": "INR",
    //     "receipt": "receipt#1",
    //     "offer_id": null,
    //     "status": "paid",
    //     "attempts": 1,
    //     "notes": [],
    //     "created_at": 1741860902
    // }
    // var data = {
    //     "status": true,
    //     "message": "Verification successful",
    //     "data": {
    //         "id": 4099260516,
    //         "domain": "test",
    //         "status": "success",
    //         "reference": "re4lyvq3s3",
    //         "receipt_number": null,
    //         "amount": 40333,
    //         "message": null,
    //         "gateway_response": "Successful",
    //         "paid_at": "2024-08-22T09:15:02.000Z",
    //         "created_at": "2024-08-22T09:14:24.000Z",
    //         "channel": "card",
    //         "currency": "NGN",
    //         "ip_address": "197.210.54.33",
    //         "metadata": "",
    //         "log": {
    //             "start_time": 1724318098,
    //             "time_spent": 4,
    //             "attempts": 1,
    //             "errors": 0,
    //             "success": true,
    //             "mobile": false,
    //             "input": [],
    //             "history": [
    //                 {
    //                     "type": "action",
    //                     "message": "Attempted to pay with card",
    //                     "time": 3
    //                 },
    //                 {
    //                     "type": "success",
    //                     "message": "Successfully paid with card",
    //                     "time": 4
    //                 }
    //             ]
    //         },
    //         "fees": 10283,
    //         "fees_split": null,
    //         "authorization": {
    //             "authorization_code": "AUTH_uh8bcl3zbn",
    //             "bin": "408408",
    //             "last4": "4081",
    //             "exp_month": "12",
    //             "exp_year": "2030",
    //             "channel": "card",
    //             "card_type": "visa ",
    //             "bank": "TEST BANK",
    //             "country_code": "NG",
    //             "brand": "visa",
    //             "reusable": true,
    //             "signature": "SIG_yEXu7dLBeqG0kU7g95Ke",
    //             "account_name": null
    //         },
    //         "customer": {
    //             "id": 181873746,
    //             "first_name": null,
    //             "last_name": null,
    //             "email": "demo@test.com",
    //             "customer_code": "CUS_1rkzaqsv4rrhqo6",
    //             "phone": null,
    //             "metadata": null,
    //             "risk_action": "default",
    //             "international_format_phone": null
    //         },
    //         "plan": null,
    //         "split": {},
    //         "order_id": null,
    //         "paidAt": "2024-08-22T09:15:02.000Z",
    //         "createdAt": "2024-08-22T09:14:24.000Z",
    //         "requested_amount": 30050,
    //         "pos_transaction_data": null,
    //         "source": null,
    //         "fees_breakdown": null,
    //         "connect": null,
    //         "transaction_date": "2024-08-22T09:14:24.000Z",
    //         "plan_object": {},
    //         "subaccount": {}
    //     }
    // }
  }, []);

  const hasRun = useRef(false); // Tracks if the effect has already executed

  useEffect(() => {
    if (hasRun.current) return; // Prevent re-execution

    hasRun.current = true; // Mark as executed

    if (
      paymentDetails !== null &&
      paymentDetails !== undefined &&
      paymentDetails != 0
    ) {
      FetchOrderDetailsRazorpay();
      setOrderDetails(paymentDetails);
    } else {
      FetchOrderDetailsPaystack();
    }
  }, []); // Empty dependency array ensures it runs once

  const [orderNo, setOrderNo] = useState(0);
  const [showBillPrint, setShowBillPrint] = useState(false);
  const [downloadBill, setDownloadBill] = useState(false);
  const [viewBill, setViewBill] = useState(false);
  const [isLens, setIsLens] = useState(false);

  const closeViewBillModal = () => {
    setViewBill(false);
  };


  const handleViewBillModalClick = (e) => {
    // Prevent the event from propagating to parent elements
    e.stopPropagation();
  };
  const hideViewBillModal3 = () => {
    setViewBill(false);
  };

  const closeBillPrint = () => {
    setLoading(false);
    setShowBillPrint(false);
  };

  const [transactionDetail, setTransactionDetail] = useState();
  console.log(transactionDetail);

  const FetchOrderDetailsPaystack = async () => {
    debugger
    setLoading(true);
    try {
      var PaymentdataInString = localStorage.getItem("PayStackPayment");
      var data = JSON.stringify(PaymentdataInString);
      var dataToSend = {
        reference: PaymentdataInString || data.reference || "cfky6fa733",
      };
      var orderdata = await PayStack.VerifyPayment(dataToSend, paymentTypeId);
      console.log("orderData", orderdata);
      if (orderdata.data.status === true) {
        setOrderDetails(orderdata.data);
        if (orderdata?.data?.data?.status == "success") {
          const userLogin = localStorage.getItem("accountLogin");
          const userArray = userLogin ? JSON.parse(userLogin) : null;

          const cartData = await Order.getCartList(userArray.d2Id);
          if (cartData.length == 0) {
            setLoading(false);
            return;
          }
          const accAdd = await AddAddress.getAddress(userArray.d1Id);
          const address = accAdd.find((item) => item.isDefault == true);

          const billSeries = await BillSeriesMaster.getBillSeriesMaster();
          const defaultSeries = billSeries.find(
            (item) => item.setDefaultSeriesForEcom == true
          );
          const filteredData = [
            {
              fieldName: "IsDefault",
              operatorName: "Equal",
              compareValue: true,
            },
          ];
          const billTypeData = await BillType.GetFilteredBillType(filteredData);
          const billData = {
            billTypeId: billTypeData ? billTypeData[0].id || 0 : 0,
            billSeriesId: defaultSeries ? defaultSeries?.id || 0 : 0,
          };

          const paymentData = await BillPaymentMode.getvoucherPaymentModeId(
            defaultSeries?.id || 0
          );
          const bankData =
            paymentData?.filter((item) =>
              item.accountName?.includes("MPESA")
            ) || [];
          const bankId = bankData && bankData[0]?.accountId;

          var loginDataString = localStorage.getItem("accountLogin"); // This is a string
          var loginData = JSON.parse(loginDataString);
          var CouponApplied = [];
          var PaidAmountThroughGateway = orderdata.data.data.amount / 100;
          if (loginData.d2Id) {
            try {
              const response = await Order.GetEcomCartExtrafields(
                loginData.d2Id
              );
              var decodedData = decodeURIComponent(response.result[0].ex1);
              CouponApplied = JSON.parse(decodedData);
              setLoading(false);
              // var decodedDataCoupon = decodeURIComponent(response.result[0].ex2)
              // CouponApplied = JSON.parse(decodedDataCoupon)
              // console.log( "COUPONDATA", data);
              // console.log("COUPONDATA2", CouponApplied);
            } catch (error) {
              CouponApplied = [];
              console.log("Error while getting EcomCartExtrafields", error);
              setLoading(false);
            }
          } else {
            CouponApplied = [];
            setLoading(false);
          }
          var CouponDataForTransaction = [];

          var MembershipDetailsString =
            localStorage.getItem("MembershipDetails"); // This is a string
          var MembershipDetails = JSON.parse(MembershipDetailsString);

          if (MembershipDetails && MembershipDetails !== 0) {
            // CouponDataForTransaction = MembershipDetails.coupons.map(element => {
            //     return {
            //         membershipCardId: MembershipDetails.id,
            //         membershipCardNo: MembershipDetails.memberShipCardId,
            //         couponCodeId: element.id,
            //         couponCode: element.couponCode
            //     };
            // });

            CouponApplied = [];
          }

          const response = await Transaction.GetTransRefNoForOrder(
            userArray,
            cartData,
            bankId || 203 || 241,
            address,
            billData,
            CouponApplied,
            PaidAmountThroughGateway,
            CouponDataForTransaction
          ); //241- bank id or Online
          await Order.DeleteEcomCartByAccountId(userArray.d2Id);

          var loginDataString = localStorage.getItem("accountLogin");
          var loginData = JSON.parse(loginDataString);
          var accountData1 = await Account.getAccountById(loginData.d1Id);
          debugger;
          if (accountData1) {
            var accountData = accountData1.item1[0];
            localStorage.setItem(
              "MembershipDetails",
              accountData.membershipCard
                ? JSON.stringify(accountData.membershipCard)
                : "0"
            );
            if (accountData.membershipCard) {
              setIsMembershipDetails(accountData.membershipCard);
            } else {
              setIsMembershipDetails(0);
            }
          }

          setTotalQuantity(0);
          setInputValues([]);

          setTimeout(async () => {
            setLoading(true);
            const orderResponseData = await Transaction.getAccountOrder(
              userArray.d2Id
            );
            const selectedData =
              orderResponseData &&
              orderResponseData.filter(
                (item) => item.RefId == response.referenceNumber
              );
            setTransactionDetail(selectedData);
            console.log("transactionDetail", selectedData);
            debugger
            const LensItem = selectedData.filter(x => x.RecoType == 4 && x.LensTypeEf != "")
            if (LensItem.length > 0 && LensItem.LensType != "" && LensItem.LensTypeEf != "") {
              setIsLens(true)
            } else {
              setIsLens(false)
            }

            if (selectedData) {
              setOrderNo(selectedData[0]?.VouchNo || 0);
            }
            const totalAmt = cartData.reduce((total, item) => {
              return total + item.quantity * item.itemPrice; // Add the calculated amount for each item
            }, 0);

            // **************************** Save payment details *************

            const dateTime = await CurrentDateAndTime.getCurrentDateTime();

            // var data = {
            //     "status": true,
            //     "message": "Verification successful",
            //     "data": {
            //         "id": 4099260516,
            //         "domain": "test",
            //         "status": "success",
            //         "reference": "re4lyvq3s3",
            //         "receipt_number": null,
            //         "amount": 40333,
            //         "message": null,
            //         "gateway_response": "Successful",
            //         "paid_at": "2024-08-22T09:15:02.000Z",
            //         "created_at": "2024-08-22T09:14:24.000Z",
            //         "channel": "card",
            //         "currency": "NGN",
            //         "ip_address": "197.210.54.33",
            //         "metadata": "",
            //         "log": {
            //             "start_time": 1724318098,
            //             "time_spent": 4,
            //             "attempts": 1,
            //             "errors": 0,
            //             "success": true,
            //             "mobile": false,
            //             "input": [],
            //             "history": [
            //                 {
            //                     "type": "action",
            //                     "message": "Attempted to pay with card",
            //                     "time": 3
            //                 },
            //                 {
            //                     "type": "success",
            //                     "message": "Successfully paid with card",
            //                     "time": 4
            //                 }
            //             ]
            //         },
            //         "fees": 10283,
            //         "fees_split": null,
            //         "authorization": {
            //             "authorization_code": "AUTH_uh8bcl3zbn",
            //             "bin": "408408",
            //             "last4": "4081",
            //             "exp_month": "12",
            //             "exp_year": "2030",
            //             "channel": "card",
            //             "card_type": "visa ",
            //             "bank": "TEST BANK",
            //             "country_code": "NG",
            //             "brand": "visa",
            //             "reusable": true,
            //             "signature": "SIG_yEXu7dLBeqG0kU7g95Ke",
            //             "account_name": null
            //         },
            //         "customer": {
            //             "id": 181873746,
            //             "first_name": null,
            //             "last_name": null,
            //             "email": "demo@test.com",
            //             "customer_code": "CUS_1rkzaqsv4rrhqo6",
            //             "phone": null,
            //             "metadata": null,
            //             "risk_action": "default",
            //             "international_format_phone": null
            //         },
            //         "plan": null,
            //         "split": {},
            //         "order_id": null,
            //         "paidAt": "2024-08-22T09:15:02.000Z",
            //         "createdAt": "2024-08-22T09:14:24.000Z",
            //         "requested_amount": 30050,
            //         "pos_transaction_data": null,
            //         "source": null,
            //         "fees_breakdown": null,
            //         "connect": null,
            //         "transaction_date": "2024-08-22T09:14:24.000Z",
            //         "plan_object": {},
            //         "subaccount": {}
            //     }
            // }

            const lastMessage =
              orderdata.data?.log?.history.at(-1)?.message || "";

            let paymentData = {
              ord1Id: 0,
              orderNo: orderNo,
              orderRefNo: response.referenceNumber || "",
              date: dateTime || "",
              amount: PaidAmountThroughGateway || "",
              apiId: 0,
              apiName: "",
              pgOrderId: "",
              pgOrderStatus: orderdata.data.data.status || "",
              pgTrackingId: "",
              pgBankRefNo: "",
              pgFailureMessage: "",
              pgPaymentMode: orderdata.data.data.channel || "",
              pgCardName: "",
              pgStatusCode: "",
              pgStatusMessage: lastMessage || "",
              transactionDetail: "",
              remarks: "",
            };
            const insertedData = await Payment.insertPayment(paymentData);
            if (insertedData) {
              setLoading(false);
              setShowBillPrint(true);
            } else {
              setLoading(false);
              setShowBillPrint(true);
            }
          }, 2000);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const FetchOrderDetailsRazorpay = async () => {
    debugger;
    setLoading(true);
    try {
      // setOrderDetails(orderdata.data);
      const userLogin = localStorage.getItem("accountLogin");
      const userArray = userLogin ? JSON.parse(userLogin) : null;
      const cartData = await Order.getCartList(userArray.d2Id);
      if (cartData.length == 0) {
        setLoading(false);
        return;
      }
      const accAdd = await AddAddress.getAddress(userArray.d1Id);
      const address = accAdd.find((item) => item.isDefault == true);
      const billSeries = await BillSeriesMaster.getBillSeriesMaster();
      const defaultSeries = billSeries.find(
        (item) => item.setDefaultSeriesForEcom == true
      );
      const filteredData = [
        {
          fieldName: "IsDefault",
          operatorName: "Equal",
          compareValue: true,
        },
      ];
      const billTypeData = await BillType.GetFilteredBillType(filteredData);
      const billData = {
        billTypeId: billTypeData ? billTypeData[0].id || 0 : 0,
        billSeriesId: defaultSeries ? defaultSeries?.id || 0 : 0,
      };
      const paymentData = await BillPaymentMode.getvoucherPaymentModeId(
        defaultSeries?.id || 0
      );
      const bankData =
        paymentData?.filter((item) => item.accountName?.includes("MPESA")) ||
        [];
      const bankId = bankData && bankData[0]?.accountId;
      var loginDataString = localStorage.getItem("accountLogin"); // This is a string
      var loginData = JSON.parse(loginDataString);
      var CouponApplied = [];
      var PaidAmountThroughGateway = paymentDetails.amount_paid / 100;
      if (loginData.d2Id) {
        try {
          const response = await Order.GetEcomCartExtrafields(loginData.d2Id);
          var decodedData = decodeURIComponent(response.result[0].ex1);
          CouponApplied = JSON.parse(decodedData);
          console.log("COUPONDATA2", CouponApplied);
        } catch (error) {
          CouponApplied = [];
          console.log("Error while getting EcomCartExtrafields", error);
        }
      } else {
        CouponApplied = [];
      }

      var CouponDataForTransaction = [];

      var MembershipDetailsString = localStorage.getItem("MembershipDetails"); // This is a string
      var MembershipDetails = JSON.parse(MembershipDetailsString);

      if (MembershipDetails && MembershipDetails !== 0) {
        // CouponDataForTransaction = MembershipDetails.coupons.map(element => {
        //     return {
        //         membershipCardId: MembershipDetails.id,
        //         membershipCardNo: MembershipDetails.memberShipCardId,
        //         couponCodeId: element.id,
        //         couponCode: element.couponCode
        //     };
        // });

        CouponApplied = [];
      }


      const response = await Transaction.GetTransRefNoForOrder(
        userArray,
        cartData,
        bankId || 203 || 241,
        address,
        billData,
        CouponApplied,
        PaidAmountThroughGateway
      ); //241- bank id or Online
      await Order.DeleteEcomCartByAccountId(userArray.d2Id);
      var loginDataString = localStorage.getItem("accountLogin");
      var loginData = JSON.parse(loginDataString);
      var accountData1 = await Account.getAccountById(loginData.d1Id);
      debugger;
      if (accountData1) {
        var accountData = accountData1.item1[0];
        localStorage.setItem(
          "MembershipDetails",
          accountData.membershipCard
            ? JSON.stringify(accountData.membershipCard)
            : "0"
        );
        if (accountData.membershipCard) {
          setIsMembershipDetails(accountData.membershipCard);
        } else {
          setIsMembershipDetails(0);
        }
      }

      setTotalQuantity(0);
      setInputValues([]);

      setTimeout(async () => {
        const orderResponseData = await Transaction.getAccountOrder(
          userArray.d2Id
        );
        debugger;
        const selectedData =
          orderResponseData &&
          orderResponseData.filter(
            (item) => item.RefId == response.referenceNumber
          );
        setTransactionDetail(selectedData);
        console.log("transactionDetail", selectedData);
        if (selectedData) {
          setOrderNo(selectedData[0]?.VouchNo || 0);
        }
        const totalAmt = cartData.reduce((total, item) => {
          return total + item.quantity * item.itemPrice; // Add the calculated amount for each item
        }, 0);

        // **************************** Save payment details *************

        const dateTime = await CurrentDateAndTime.getCurrentDateTime();

        let paymentData = {
          ord1Id: 0,
          orderNo: orderNo,
          orderRefNo: response.referenceNumber,
          date: dateTime,
          amount: PaidAmountThroughGateway,
          apiId: 0,
          apiName: "",
          pgOrderId: "",
          pgOrderStatus: orderdetails.status,
          pgTrackingId: "",
          pgBankRefNo: "",
          pgFailureMessage: "",
          pgPaymentMode: "",
          pgCardName: "",
          pgStatusCode: "",
          pgStatusMessage: orderdetails.entity,
          transactionDetail: "",
          remarks: "",
        };
        const insertedData = await Payment.insertPayment(paymentData);
        if (insertedData) {
          setLoading(false);
          setShowBillPrint(true);
        } else {
          setLoading(false);
          setShowBillPrint(true);
        }
      }, 2000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const backToMyOrderPage = () => {
    const tabName = "My Order";
    navigate("/admin", {
      state: {
        tabName,
      },
    });
  };

  const AddPowerMyOrderPage = () => {
    const tabName = "My Order";
    navigate("/admin", {
      state: {
        TransId: orderNo,
        isAddPower: true,
        tabName,
      },
    });
  };

  // const handlePrint = () => {
  //   const printContent = document.getElementById("printDiv").innerHTML;
  //   const originalContent = document.body.innerHTML;

  //   document.body.innerHTML = printContent;
  //   window.print();
  //   document.body.innerHTML = originalContent;
  //   window.location.reload(); 
  // };

  // const handleViewBill = async () => {
  //   setLoading(true)
  //   setShowBillPrint(true);
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 5000);
  // };


  const handleModalViewBill = () => {
    setViewBill(!viewBill);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const handleDownloadBill = () => {
    setLoading(true);
    //setTransId(id);
    setDownloadBill(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  useEffect(() => {
    if (viewBill) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => (document.body.style.overflow = "auto");
  }, [viewBill]);


  return (
    <div className="Receipt-height-top">
      {paymentDetails != 0 ? (
        <>
          {orderdetails && Object.keys(orderdetails).length > 0 && (
            <div
              className="PaymentResponse"
              id="printDiv"
              style={{ marginTop: "100px" }}
            >
              {orderdetails?.status == "paid" ? (
                <img src={paymentDone} alt="" />
              ) : (
                <img src={paymentFail} alt="" />
              )}
              <div className="paymentHeading">
                <h3>Payment Status : {orderdetails?.status?.toUpperCase()}</h3>
                <p>Amount</p>
                <h5>
                  {transactionDetail && transactionDetail[0]?.BillAmt}{" "}
                  {companyData.currencySymbol || "KES"}
                </h5>
              </div>
              <div className="paymentDetailsBox">
                <div className="paymentDetailsBox-inner">
                  <div>
                    <label className="PaymentBoxInnerheading">
                      Amount Paid :
                    </label>
                    <label className="PaymentBoxInnerRes">
                      {transactionDetail && transactionDetail[0]?.BillAmt}{" "}
                      {companyData.currencySymbol || "KES"}
                    </label>
                  </div>
                  {/* <div>
                      <label className='PaymentBoxInnerheading'>Charge Money :</label>
                      <label className='PaymentBoxInnerRes'>{orderdetails?.data.fees} KES</label>
                  </div> */}
                  <div>
                    <label className="PaymentBoxInnerheading">
                      Paymeny entity :
                    </label>
                    <label className="PaymentBoxInnerRes">
                      {orderdetails?.entity}
                    </label>
                  </div>
                  <div>
                    <label className="PaymentBoxInnerheading">Currency :</label>
                    <label className="PaymentBoxInnerRes">
                      {orderdetails?.currency || companyData.currencySymbol}
                    </label>
                  </div>
                  <div>
                    <label className="PaymentBoxInnerheading">Order Id :</label>
                    <label className="PaymentBoxInnerRes">
                      {orderdetails?.id}
                    </label>
                  </div>
                  <div>
                    {/* <label className="PaymentBoxInnerheading">Mobile :</label>
                    <label className="PaymentBoxInnerRes">
                      {orderdetails?.data?.authorization?.mobile_money_number}
                    </label> */}
                  </div>
                </div>
                <div className="PaymentBoxButtonBox">
                  {/* <button
                    style={{
                      display:
                        orderdetails?.status == "paid" ? "block" : "none",
                    }}
                    className="paymentDetailsBox-button print-btn-clr"
                 
                  >
                    Print{" "}
                    <i
                      style={{ margin: "2px 0px 0px 6px" }}
                      class="fa-solid fa-print"
                    ></i>
                  </button> */}
                  {/* <Link to="/" style={{ backgroundColor: orderdetails?.data.status == "success" ? "" : "#de5605" }} className='paymentDetailsBox-button'>Back to Homepage</Link> */}
                  {/* <div style={{ backgroundColor: orderdetails?.data.status == "success" ? "" : "#de5605", display: "block" }} className='paymentDetailsBox-button' onClick={backToMyOrderPage}>Back</div> */}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {orderdetails && Object.keys(orderdetails).length > 0 && (
            <div
              className="PaymentResponse"
              id="printDiv"
              style={{ marginTop: "100px" }}
            >
              {orderdetails?.data.status == "success" ? (
                <img src={paymentDone} alt="" />
              ) : (
                <img src={paymentFail} alt="" />
              )}
              <div className="paymentHeading">
                <h3>
                  Payment Status : {orderdetails?.data?.status?.toUpperCase()}
                </h3>
                <p>Amount</p>
                <h5>
                  {transactionDetail && transactionDetail[0]?.BillAmt}{" "}
                  {companyData.currencySymbol || "KES"}
                </h5>
              </div>
              <div className="paymentDetailsBox">
                <div className="paymentDetailsBox-inner">
                  <div>
                    <label className="PaymentBoxInnerheading">
                      Amount Paid :
                    </label>
                    <label className="PaymentBoxInnerRes">
                      {transactionDetail && transactionDetail[0]?.BillAmt}{" "}
                      {companyData.currencySymbol || "KES"}
                    </label>
                  </div>
                  {/* <div>
                        <label className='PaymentBoxInnerheading'>Charge Money :</label>
                        <label className='PaymentBoxInnerRes'>{orderdetails?.data.fees} KES</label>
                    </div> */}
                  <div>
                    <label className="PaymentBoxInnerheading">
                      Paymeny Method :
                    </label>
                    <label className="PaymentBoxInnerRes">
                      {orderdetails?.data.channel}
                    </label>
                  </div>
                  <div>
                    <label className="PaymentBoxInnerheading">Currency :</label>
                    <label className="PaymentBoxInnerRes">
                      {orderdetails?.data.currency ||
                        companyData.currencySymbol}
                    </label>
                  </div>
                  <div>
                    <label className="PaymentBoxInnerheading">Email :</label>
                    <label className="PaymentBoxInnerRes">
                      {orderdetails?.data.customer.email}
                    </label>
                  </div>
                  <div>
                    <label className="PaymentBoxInnerheading">Mobile :</label>
                    <label className="PaymentBoxInnerRes">
                      {orderdetails?.data?.authorization?.mobile_money_number}
                    </label>
                  </div>
                </div>
                {/* <div className="PaymentBoxButtonBox">
                  <button
                    style={{
                      display:
                        orderdetails?.data.status == "success"
                          ? "block"
                          : "none",
                    }}
                    className="paymentDetailsBox-button print-btn-clr"
                    onClick={handlePrint}
                  >
                    Print{" "}
                    <i
                      style={{ margin: "2px 0px 0px 6px" }}
                      class="fa-solid fa-print"
                    ></i>
                  </button>
                </div> */}

                <div className="view-flex-power">
                  <div className="PaymentBoxButtonBox payment-bill-view">
                    <button
                      className="paymentDetailsBox-button print-btn-clr"
                      onClick={handleModalViewBill}
                    >
                      View Bill
                    </button>
                  </div>
                  {isLens ? (<div className="PaymentBoxButtonBox payment-bill-view">
                    <button
                      className="paymentDetailsBox-button print-btn-clr"
                      style={{
                        backgroundColor:
                          orderdetails &&
                            orderdetails?.data?.status == "success"
                            ? ""
                            : "#de5605",
                      }}
                      onClick={AddPowerMyOrderPage}
                    >
                      Add Power
                    </button>
                  </div>) : (<div className="PaymentBoxButtonBox payment-bill-view">
                    <button
                      className="paymentDetailsBox-button print-btn-clr"
                      onClick={handleDownloadBill}
                    >
                      Download Bill
                    </button>
                  </div>)}
                </div>
              </div>
            </div>
          )}
        </>
      )}



      <h2
        style={{
          textAlign: "center",
          color: "gray",
          marginBottom: "50px",
          marginTop: "50px",
        }}
      >
        We’ve received your Order. Your Order number is {orderNo}.
      </h2>
      <div
        style={{
          backgroundColor:
            orderdetails && orderdetails?.data?.status == "success"
              ? ""
              : "#de5605",
        }}
        className="paymentDetailsBox-button"
        onClick={backToMyOrderPage}
      >
        Back to My Order
      </div>

      {viewBill && (
        <div onClick={hideViewBillModal3} className="modal-back">
          <div
            onClick={handleViewBillModalClick}
            style={{ zIndex: "5" }}
            className="OpticalField-modal"
          >
            <div style={{ padding: "20px" }}>
              <div className="">
                <label
                  className="close_modal-theme3"
                  onClick={closeViewBillModal}
                >
                  <i class="fa-solid fa-xmark"></i>
                </label>
              </div>
              <div>
                <div className="technical-scroll-detail">
                  <ReportDesignForA4
                    templateName="FinalBillPrint"
                    currentUser={currentUser}
                    ord1Id={transactionDetail && transactionDetail[0]?.TransId}
                    setViewBill={setViewBill}
                    isViewBill={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}



      {(showBillPrint || downloadBill) && (
        <div style={{ display: "none" }}>
          <div className="modal-back" onClick={closeBillPrint}>
            <div className="billPrintModal" style={{}}>
              <div style={{ padding: "5px", backgroundColor: "white" }}>
                <ReportDesignForA4
                  templateName="FinalBillPrint"
                  currentUser={currentUser}
                  ord1Id={transactionDetail && transactionDetail[0]?.TransId}
                  //setShowBillPrint={setShowBillPrint}
                  {...(showBillPrint && {
                    setShowBillPrint,
                  })}
                  {...(downloadBill && {
                    setDownloadBill,
                  })}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && <Loader1 />}
    </div>
  );
};

export default PaymentResponse;
