import React, { useState, useEffect, useContext } from "react";
import Theme3NavbarOnhover from "./NavbarHoverPart/Theme3NavbarOnhover";
import { Link, useLocation } from "react-router-dom";
import MyContext from "../../MyContext";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import Loader1 from "../Loaders/Loader1";
import NavBarMobile_Lc from "../NavBarMobile/NavBarMobile_Lc";
import { useNavigate } from "react-router-dom";
import SearchItemBox from "../SearchItemBox/SearchItemBox";
import useDebounce from "../useDebounce";
import Gold from "../../Assets/Image_lc/membership new.jpg";
import Kidsglasses from "../KidsGlasses/Kidsglasses";
import StoreloginModalBox from "../Modal/StoreloginModalBox";
import Account from "../../../API/API_SERVISES/Account";
import NabarAccountModel from "./NabarAccountModel";
import ShopBanner from "../../../API/API_SERVISES/Shopbanner";

const Navbar_lc = (props) => {
  const { wishList } = props;
  // console.log("444444444444444444444444444444", wishList);

  const [Loading, setLoading] = useState(false);
  const [CategoryData, setCategoryData] = useState([]);
  const [parentId, setParentId] = useState();
  const [LcNavbar, setLcNavbar] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiBannerData, setApiBannerData] = useState([]);
  // const [companyData, setCompanyData] = useState("")
  const {
    myaccountmodal,
    userArray,
    modal,
    setModal,
    setLoginPanel,
    totalQuantity,
    productlist,
    setProductList,
    currentUser,
    wishLisHandle,
    setwishLisHandle,
    logoCompanyUrl,
    companyData,
    setCompanyData,
    imageBaseUrl,
    itemGroupData,
    close,
    setClose,
    error,
    handleLogin,
    searchRef,
    logging,
    shopBanner,
    isMembershipDetails,
    userData,
    setUserData

  } = useContext(MyContext);
  console.log(companyData);

  const [searchValue, setSearchValue] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { totalPrice2 } = location.state || {};

  const [Data, setData] = useState([]);

  const openNavbarLc = async (d1_Id) => {
    // alert(d1_Id)
    setParentId(d1_Id);
    setLcNavbar(true);
  };

  const [StoreloginModal, setStoreloginModal] = useState(false);
  const storeLogin = () => {
    setStoreloginModal(!StoreloginModal);
  };

  const Debounsedvalue = useDebounce(searchValue, 500);

  useEffect(() => {
    if (Debounsedvalue && Debounsedvalue != "") {
      searchValueFunction(Debounsedvalue);
    }
  }, [Debounsedvalue]);

  const searchValueFunction = async (value) => {
    console.log("Searching for:", value); // Debugging log
    if (currentUser) {
      try {
        const filteredData1 = {
          MasterType: 4,
          GroupWiseSuggestion: false,
          GroupId: "",
          search_str: value,
          PageNumber: 1,
          PageSize: 15,
          IsEcomStore: false,
        };

        const filteredData = {
          MasterType: 3,
          GroupWiseSuggestion: false,
          GroupId: "",
          search_str: value,
          PageNumber: 1,
          PageSize: 5,
          IsEcomStore: false,
        };
        const searchItem1 = await Account.GetAccountListForAutoSuggestion(
          filteredData
        );
        const searchItem2 = await Account.GetAccountListForAutoSuggestion(
          filteredData1
        );

        // Append `isGroup: true` to all objects in searchItem1
        const modifiedSearchItem1 =
          searchItem1?.map((item) => ({ ...item, isGroup: true })) || [];

        // Append `isGroup: false` to all objects in searchItem2
        const modifiedSearchItem2 =
          searchItem2?.map((item) => ({ ...item, isGroup: false })) || [];

        setData([...modifiedSearchItem1, ...modifiedSearchItem2]);
      } catch (error) {
        console.error("Error in searchValueFunction:", error);
      }
    }
  };

  const wishListHandleShow = () => {
    setwishLisHandle(!wishLisHandle);
  };

  const closeNavbarLc = async () => {
    setLcNavbar(false);
    // alert("close")
  };

  const signin = () => {
    setLoginPanel(!modal);
  };
  const [UserDetails, setUserDetails] = useState([]);
  console.log(UserDetails);

  useEffect(() => {
    const data = localStorage.getItem("accountLogin");
    const parsedData = JSON.parse(data);
    setUserData(parsedData);
  }, []);

  useEffect(() => {
    const data = localStorage.getItem("StoreLogin");
    const parsedData = JSON.parse(data);
    setUserDetails(parsedData);
  }, []);

  const [activeLink, setActiveLink] = useState("/");
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const fetchData = () => {
    // debugger
    if (currentUser === true) {
      try {
        // const categoryName = await ItemGroupAPI.GetItemGroup();
        const categoryName = itemGroupData;
        // console.log(categoryName);
        const categoryData = categoryName
          .filter((item) => item.imageShowOnError == 1)
          .slice()
          .sort((a, b) => a.displayOrder - b.displayOrder);
        setCategoryData(categoryData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [currentUser, itemGroupData]);

  const [buttonColors, setButtonColors] = useState({
    defaultColor: "",
    ourMenuColor: "",
    myCartColor: "",
  });

  // const fetchData2 = async () => {
  //   // debugger
  //   if (currentUser === true) {

  //     try {
  //       const Data = await CompanyApi.GetCompanyById(id);
  //       // console.log(categoryName);
  //       setUserCompany(Data);
  //       console.log(Data);
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  //   setLoading(false);
  // };
  // useEffect(() => {
  //   // setLoading(true);
  //   fetchData2();
  // }, [currentUser]);

  // const debounce = (func, delay) => {
  //   let timeoutId;
  //   return (...args) => {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //     timeoutId = setTimeout(() => {
  //       func(...args);
  //     }, delay);
  //   };
  // };

  useEffect(() => {
    fetchData();
  }, [currentUser, itemGroupData]);

  // Search function that gets called after the debounce delay

  // Create a debounced version of the search function
  // const debouncedSearchValueFunction = debounce(searchValueFunction, 2000); // 2 seconds debounce

  // Handle input change
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue); // Update the input state immediately
    // searchValueFunction(newValue); // Call the debounced search function
  };

  const closeSuggestion = () => {
    setTimeout(() => {
      setClose(false);
    }, 100); // 1000 milliseconds = 1 second
  };

  const openSuggestion = () => {
    setClose(true);
  };


  const ImageId =
  apiBannerData &&
  apiBannerData
    .filter((item) => item.imageBannerName == "Membership")
    .map((image) => image.bannerImageId);
  console.log(ImageId);

  const fetchAllBannerdata = async () => {
    if (currentUser === true) {
      try {
        const filterData = [
          {
            fieldName: "imageBannerName",
            operatorName: "Equal",
            compareValue: "Membership",
          },
        ];
        const response = await ShopBanner.GetShopBannersFilter(filterData);
        if (response) {
          console.log("Response", response);
          setApiBannerData(response);
        }
      } catch (error) {
        alert(error);
        throw new error("Unable To fetch Data");
      }
    }
  };
  useEffect(() => {
    fetchAllBannerdata();
  }, [currentUser, shopBanner]);

  // const handleKeyDown = async (event) => {
  //   if (event.key === "Enter") {
  //     event.target.blur(); // This will blur the input element
  //     navigate("/productsearch", { state: { searchValue: searchValue } });
  //   }
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (currentUser === true) {
  //       try {

  //         const data = await CompanyApi.GetCompany();
  //         const data1 = await CompanyApi.GetCompanyById(data[0].id)
  //         console.log(data1);
  //         setCompanyData(data1);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [currentUser]);

  // -----------------Topnavdata------------------------
  const topnavdata = [
    {
      name: "Store Locator",
      route: "/Storelocator",
    },
    {
      name: `${companyData.shortName} Blogs`,
      route: "/Blogs",
    },
    {
      name: "Partner With Us",
      route: "/partnerwithus",
    },
  ];

  const validEyeTestNames = ["Home Eye-Test", "Eye Test", "Eye Checkup", "Eye", "Home Eye Test", "HOME EYE TEST"];

  const validStoreLocator = ["Store Locator", "Location", "Store", "Locator", "STORE LOCATOR"]

  const showButton = () => {
    if (currentPath === "/Checkout") {
      return (
        <div>
          <div className="navbar_theme_3">
            <div
              className={`navabar_middle_theme_33 ${buttonColors.defaultColor}`}
            >
              <div className="navabar_middle_theme_3-logo-box">
                <Link to="/" className="Logo_theme_3_box">
                  <img
                    className="logo_theme_3-secondary"
                    src={`${imageBaseUrl}${companyData.companyImageId}`}
                    alt=""
                  />
                  {/* <img className="logo_theme_3-secondary" src={Logo} alt="" /> */}
                </Link>
                <div className="mobileNo_Theme_lc"></div>
              </div>
              <div className="Theme_3_navbar_Buttons"></div>
            </div>
            <div
              className={`navbar_bottom_theme_3 ${buttonColors.defaultColor}`}
            >
              {/* <div>
                <img className="gold-membership" src={Gold} alt="" />
                </div> */}
            </div>
          </div>
        </div>
      );
      // } if (currentPath === '/ShippingAddress') {
      //   return (
      //     <div >
      //       <div className="navbar_theme_3">
      //         <div className={`navabar_middle_theme_33 ${buttonColors.defaultColor}`}>
      //           <div className="navabar_middle_theme_3-logo-box">
      //             <Link to="/" className="Logo_theme_3_box">
      //               <img className="logo_theme_3-secondary" src={`${imageBaseUrl}${companyData.companyImageId}`} alt="" />
      //               {/* <img className="logo_theme_3-secondary" src={Logo} alt="" /> */}
      //             </Link>
      //             <div className="mobileNo_Theme_lc">
      //             </div>
      //           </div>
      //           <div className="Theme_3_navbar_Buttons">
      //           </div>
      //         </div>
      //         <div className={`navbar_bottom_theme_3 ${buttonColors.defaultColor}`}>
      //         </div>
      //       </div>
      //     </div>
      //   );
    } else if (
      // currentPath === "/Checkout" ||
      currentPath === "/Storelocator" ||
      currentPath === "/Storehome/Kenya"
    ) {
      return <div></div>;
    } else {
      return (
        <div>
          <div className="navbar_theme_3">
            <div className={`topnavbar`}>
              <div className={`topnavbar-left`}>
                {topnavdata.map((item, index) => (
                  <Link
                    key={index}
                    to={item.route}
                    className="Head-top-contect head-top-left-name"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className={`topnavbar-right`}>
                <div
                  style={{ border: "none" }}
                  className="Head-top-contect"
                  onClick={storeLogin}
                >
                  {UserDetails
                    ? `${UserDetails.compCode || ""} - ${UserDetails.userName || ""
                      }`.trim() || "Store Login"
                    : "Store Login"}
                </div>{" "}
                |
                <Link
                  style={{ border: "none", marginLeft: "8px" }}
                  to={"/contactpage"}
                  className="Head-top-contect"
                >
                  Contact Us
                </Link>
              </div>
            </div>

            <div
              className={`navabar_middle_theme_3 ${buttonColors.defaultColor}`}
            >
              <div className="navabar_middle_theme_3-logo-box">
                <Link to="/" className="Logo_theme_3_box">
                  <img
                    className="logo_theme_3"
                    src={`${imageBaseUrl}${companyData?.companyImageId}`}
                    alt=""
                  />
                  {/* <img className="logo_theme_3-secondary" src={Logo} alt="" /> */}
                </Link>
                <div className="mobileNo_Theme_lc">
                  {/* <i className="fa-solid fa-phone-volume"></i>
                  <p>{companyData && companyData?.mobileNo}</p> */}
                </div>
              </div>

              <div className="div-for-search-input">
                <input
                  className="search_box_theme_3"
                  placeholder="What are you looking for ?"
                  type="text"
                  ref={searchRef}
                  value={searchValue}
                  // onBlur={closeSuggestion}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  onFocus={openSuggestion}
                // onKeyDown={handleKeyDown}
                />
                {searchValue.length != 0 &&
                  window.location.pathname != "productsearch?productId=582" && (
                    <SearchItemBox
                      searchValue={searchValue}
                      Data={Data}
                      close={close}
                      setClose={setClose}
                      setSearchValue={setSearchValue}
                    />
                  )}
              </div>

              <div className="Theme_3_navbar_Buttons">
                {/* <div>Track Order</div> */}
                <div
                  onClick={myaccountmodal}
                  className={`navbar-option ${activeLink === "/ShippingAddress" ? "active" : ""
                    }`}
                >
                  <div   className={`signinup-hover ${
                      isMembershipDetails !== 0 ? "shine-ForMember" : ""
                    }`} onClick={signin}
                  onMouseEnter={() => setIsModalOpen(true)} 
                  onMouseLeave={() => setIsModalOpen(false)}
                  >
                    {userData ? userData.name : "Sign in & Sign Up"}
                    {isModalOpen && userData && <NabarAccountModel />}
                  </div>
                </div>
                <div onClick={wishListHandleShow} style={{ cursor: "pointer" }}>
                  <i
                    style={{ position: "relative" }}
                    className="fa-regular fa-heart"
                  >
                    <span className="Qty-ttl">{wishList?.length || 0}</span>
                  </i>{" "}
                  Wishlist
                </div>
                <Link to="/Cart3" className="navbar-cart-link-theme3">
                  <i
                    style={{ position: "relative" }}
                    className="fa-solid fa-bag-shopping"
                  >
                    <span className="Qty-ttl">{totalQuantity} </span>
                  </i>{" "}
                  Cart {/* {totalQuantity} */}
                </Link>
                
              </div>
            </div>

            <div
              className={`navbar_bottom_theme_3 ${buttonColors.defaultColor}`}
            >
              {/* <div className="navbar_Bottom_left_theme_3">
                {CategoryData.filter(
                  (category1) =>
                    category1.primaryGroup === true &&
                    category1.showInMenu === true &&
                    category1.imageShowOnError == 1
                )
                  .slice()
                  .sort((a, b) => a.displayOrder - b.displayOrder)
                  .map((category) => (
                    <div
                      key={category.id}
                      onMouseLeave={closeNavbarLc}
                      onMouseEnter={() => openNavbarLc(category.d1_Id)}
                      onClick={(e) => {
                        const menuNameLower = category.menuName.toLowerCase();
              
                        if (
                          validEyeTestNames.some((name) => name.toLowerCase() === menuNameLower) ||
                          validStoreLocator.some((name) => name.toLowerCase() === menuNameLower)
                        ) {
                          e.preventDefault();
                          e.stopPropagation();
                          return; 
                        }
                      }}
                    >
                      {["contact lens", "contact lenses"].includes(
                        category.menuName.toLowerCase()
                      ) ? (
                        <Link
                          to={`/ContactLenseCart?productId=${category.d2_Id}`}
                          className="Header-Category-Color"
                        >
                          {category.menuName}
                        </Link>
                      ) : (
             
                        <Link
                          to={`/productsearch?productId=${category.d2_Id}`}
                          className="Header-Category-Color"
                        >
                          {category.menuName}
                        </Link>
                      )}
                    </div>
                  ))}
              </div> */}

              <div className="navbar_Bottom_left_theme_3">
                {CategoryData.filter(
                  (category) =>
                    category.primaryGroup === true &&
                    category.showInMenu === true &&
                    category.imageShowOnError == 1
                )
                  .slice()
                  .sort((a, b) => a.displayOrder - b.displayOrder)
                  .map((category) => {
                    const menuNameLower = category.menuName.toLowerCase();
                    const isRestricted = validEyeTestNames.some(name => name.toLowerCase() === menuNameLower) ||
                      validStoreLocator.some(name => name.toLowerCase() === menuNameLower);

                    return (
                      <div key={category.id} onMouseLeave={closeNavbarLc} onMouseEnter={() => openNavbarLc(category.d1_Id)}>
                        {isRestricted ? (
                          <span className="Header-Category-Color">{category.menuName}</span> // No navigation
                        ) : (
                          <Link
                            to={["contact lens", "contact lenses"].includes(menuNameLower)
                              ? `/ContactLenseCart?productId=${category.d2_Id}`
                              : `/productsearch?productId=${category.d2_Id}`}
                            className="Header-Category-Color"
                          >
                            {category.menuName}
                          </Link>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="navbar_Bottom_right_theme_3 goldmax-cpn-member">
                <Link to="/MembershipGold">
                  {/* <img className="gold-membership" src={Gold} alt="" /> */}
                  {ImageId && ImageId.length > 0 && (
                        <img 
                            style={{
                                height: ImageId.length === 0 ? "0" : ""
                            }}
                            className="gold-membership"
                            src={ImageId.length > 0 ? `${imageBaseUrl}${ImageId[0]}` : ""}
                            alt=""
                        />
                    )
                    }
                </Link>
              </div>
            </div>

            {LcNavbar && (
              <Theme3NavbarOnhover
                CategoryData={CategoryData}
                openNavbarLc={openNavbarLc}
                closeNavbarLc={closeNavbarLc}
                parentId={parentId}
              />
            )}
          </div>
          {Loading && <Loader1 />}
          {/* <NavBarMobile1 /> */}
          <NavBarMobile_Lc wishList={wishList} />
          {StoreloginModal && (
            <div className="modal-back">
              <StoreloginModalBox close={storeLogin} />
            </div>
          )}
        </div>
      );
    }
  };

  return <div>{showButton()}</div>;
};

export default Navbar_lc;
